import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-primary-goal',
  templateUrl: './pre-primary-goal.component.html',
  styleUrls: ['./pre-primary-goal.component.css']
})
export class PrePrimaryGoalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
