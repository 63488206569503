<div class="container">
  <app-lightgreen-box name="CirculationThink">Think Alt Causes</app-lightgreen-box>

  <app-lg-info>

        <h2>Obstructive Shock</h2>
          <ul>
              <li>Tension Pneumothorax </li>
              <li>Cardiac Tamponade</li>
          </ul>

          <h2>Distributive Shock</h2>
          <ul>
              <li>Sepsis</li>
              <li>Anaphylaxis </li>
              <li>Spinal Cord Damage</li>
          </ul>

          <h2>Cardiogenic Shock</h2>
          <ul>
              <li>Hypocalcaemia</li>
              <li>Hypoxia (Cardiac Stunning)</li>
              <li>Cardiac Contusion</li>
              <li>Medical Cause</li>
          </ul>

          <hr>

          <iframe src="https://player.vimeo.com/video/864741303?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="C - Think Alt Causes"></iframe>

  </app-lg-info>

</div>
