import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circ-recon-plasma',
  templateUrl: './circ-recon-plasma.component.html',
  styleUrls: ['./circ-recon-plasma.component.css']
})
export class CircReconPlasmaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
