import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comms-casualty',
  templateUrl: './comms-casualty.component.html',
  styleUrls: ['./comms-casualty.component.css']
})
export class CommsCasualtyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
