<div class="container">
    <app-lightgreen-box name="CirculationReconPlasma">Plasma</app-lightgreen-box>
  
    <app-lg-info>
        
        <h1>Indications</h1>
    
        <ul>
            <li>Haemorrhagic Shock</li>
            <li>Burns >20% </li>
        </ul>


    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit: Dried Plasma / Distilled water / Connector</li>
                <li>Cas: IV or IO Access</li>
            </ul>
        
    <h1>Procedure</h1>

        <h2>LyoPlas</h2>

        <h2>1 Check</h2>
        
            <ul>
                <li>Expiry date</li>
                <li>Integrity of containers</li>
            </ul>

        <h2>2 Connect</h2>
            
            <ul>
                <li>Transfer set to water bag</li>
                <li>Close the clamp on the transfer set</li>
            </ul>

        <h2>3 Pierce</h2>
            
            <ul>
                <li>Expose & disinfect rubber stopper </li>
                <li>Spike the stopper with the transfer set </li>
                
            </ul>

        <h2>4 Transfer</h2>
            <ul>
                <Li>Release clip and allow water to flow to the bottle of dried plasma </Li>
                <li>Open the airlock valve on the transfer set</li>
            </ul>

        <h2>5 Swirl</h2>
            <ul>
                <li>As the water flows gently swirl the bottle to assist reconstitution </li>
            </ul>

        <h2>6 Choose</h2>
            <ul>
                <li>Transfuse from the bottle OR</li>
                <li>Reverse the flow and pour plasma back into bag</li>
                <li>Transfer label to plastic bag </li>
            </ul>

    <h1>Post Procedure</h1>    
    
        <h2>Check</h2>
        
            <ul>
                <li>All dried plasma has dissolved before transfusion</li>
                <li>Unit information sticker is transferred to cas docs </li>
                <li>No airlock if using the bottle - Insert needle to break airlock</li>
            </ul>
        
        <h2>Secure</h2>
            <ul>
                <li>All lines prior to movement</li>
            </ul>

    
    <h1 style="color: orange">Risk</h1>
        <ul>

            <li style="color: orange;">TRALI</li>
                <ul>
                    <li>Unlikely with this product but observe for lung injury</li>
                </ul>
            
            <li style="color: orange;">TACO</li>

                <ul>
                    <li>Aim for 100mmHg Systolic </li>
                    <li>Slow the transfusion once above 100 mmHg</li>

                </ul>

                <li style="color: orange;">Infection</li>

                <ul>
                    <li>Practise aseptic technique</li>

                </ul>

                <li style="color: orange;">Anaphylaxis</li>

                <ul>
                    <li>Stop transfusion</li>
                    <li>Give 500 micrograms of adrenalin - Deep IM injection</li>

                </ul>
        </ul>

    <hr>

    <iframe src="https://player.vimeo.com/video/855682790?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="C - Plasma"></iframe>

    </app-lg-info>

  </div>
  