import { BrowserModule } from '@angular/platform-browser';
import { AngularFireModule } from '@angular/fire';
// import { AngularFireDatabase } from '@angular/fire/database'
import { AngularFirestore } from '@angular/fire/firestore'
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BlueBoxComponent } from './Buttons/blue-box/blue-box.component';
import { HomeComponent } from './home/home.component';
import { GreyBoxComponent } from './Buttons/grey-box/grey-box.component';
import { WhiteBoxComponent } from './Buttons/white-box/white-box.component';
import { LightblueBoxComponent } from './Buttons/lightblue-box/lightblue-box.component';
import { LightgreenBoxComponent } from './Buttons/lightgreen-box/lightgreen-box.component';
import { RouterModule, Routes } from '@angular/router';
import { InfoPageComponent } from './info-page/info-page.component';
import { FormsModule } from '@angular/forms';
import { TickComponent } from './Buttons/tick/tick.component';
import { TacticsRouteComponent } from './Pre-Primary/SAFETY/safety-tactics/tactics-route.component';
import { NotesComponent } from './Buttons/notes/notes.component';
import { LimbTQComponent } from './EXSANGUINATION/limb-tq/limb-tq.component';
import { PackingComponent } from './EXSANGUINATION/packing/packing.component';
import { ITClampComponent } from './EXSANGUINATION/i-tclamp/i-tclamp.component';
import { LbInfoComponent } from './Buttons/lb-info/lb-info.component';
import { LgInfoComponent } from './Buttons/lg-info/lg-info.component';
import { CathemAssessComponent } from './EXSANGUINATION/cathem-assess/cathem-assess.component';
import { CatHemReassessComponent } from './EXSANGUINATION/cat-hem-reassess/cat-hem-reassess.component';
import { JunctionalTQComponent } from './EXSANGUINATION/junctional-tq/junctional-tq.component';
import { AAJTComponent } from './EXSANGUINATION/aajt/aajt.component';
import { PosturalDrainageComponent } from './AIRWAY/postural-drainage/postural-drainage.component';
import { InspectClearComponent } from './AIRWAY/inspect-clear/inspect-clear.component';
import { OpenComponent } from './AIRWAY/open/open.component';
import { MaintainComponent } from './AIRWAY/maintain/maintain.component';
import { ProtectComponent } from './AIRWAY/protect/protect.component';
import { AirwayAssessComponent } from './AIRWAY/airway-assess/airway-assess.component';
import { AirwayReAssessComponent } from './AIRWAY/airway-re-assess/airway-re-assess.component';
import { SurgicalCricComponent } from './AIRWAY/surgical-cric/surgical-cric.component';
import { EGAComponent } from './AIRWAY/ega/ega.component';
import { IntubationComponent } from './AIRWAY/intubation/intubation.component';
import { ModalModule } from './_modal';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NavbarComponent } from './navbar/navbar.component';
import { AppRouterModule } from './app-routing.module';
import { BreathingAssessComponent } from '../app/BREATHING/breathing-assess/breathing-assess.component';
import { BreathingReAssessComponent } from '../app/BREATHING/breathing-re-assess/breathing-re-assess.component';
import { ChestSealComponent } from './BREATHING/chest-seal/chest-seal.component';
import { DecompressComponent } from './BREATHING/decompress/decompress.component';
import { CircAssesComponent } from './CIRCULATION/circ-asses/circ-asses.component';
import { CircReAssessComponent } from './CIRCULATION/circ-re-assess/circ-re-assess.component';
import { DisAssessComponent } from './DISABILITY/dis-assess/dis-assess.component';
import { DisReAssesComponent } from './DISABILITY/dis-re-asses/dis-re-asses.component';
import { DisRBAComponent } from './DISABILITY/dis-rba/dis-rba.component';
import { DisIndicationsComponent } from './DISABILITY/dis-indications/dis-indications.component';
import { DisHyperventilationComponent } from './DISABILITY/dis-hyperventilation/dis-hyperventilation.component';
import { DisHypoxiaComponent } from './DISABILITY/dis-hypoxia/dis-hypoxia.component';
import { DisHypotensionComponent } from './DISABILITY/dis-hypotension/dis-hypotension.component';
import { DisHighICPComponent } from './DISABILITY/dis-high-icp/dis-high-icp.component';
import { DisHypoglycaemiaComponent } from './DISABILITY/dis-hypoglycaemia/dis-hypoglycaemia.component';
import { CatHemTreatmentComponent } from './EXSANGUINATION/cat-hem-treatment/cat-hem-treatment.component';
import { FurSecondarySurveyComponent } from './FURTHER TREATMENT/fur-secondary-survey/fur-secondary-survey.component';
import { WhiteBoxInfoComponent } from './Buttons/white-box-info/white-box-info.component';
import { GoalRiskComponent } from './Buttons/goal-risk/goal-risk.component';
import { GoalInfoComponent } from './Buttons/goal-info/goal-info.component';
import { XGoalComponent } from './EXSANGUINATION/xgoal/xgoal.component';
import { ExpAssessComponent } from './EXPOSURE/exp-assess/exp-assess.component';
import { SafetySelfComponent } from './Pre-Primary/SAFETY/safety-self/safety-self.component';
import { SafetySceneComponent } from './Pre-Primary/SAFETY/safety-scene/safety-scene.component';
import { SafetyCasualtyComponent } from './Pre-Primary/SAFETY/safety-casualty/safety-casualty.component';
import { CommsTeamComponent } from './Pre-Primary/COMMS/comms-team/comms-team.component';
import { CommsEvacuationComponent } from './Pre-Primary/COMMS/comms-evacuation/comms-evacuation.component';
import { CommsCasualtyComponent } from './Pre-Primary/COMMS/comms-casualty/comms-casualty.component';
import { AirwayTreatmentComponent } from './AIRWAY/airway-treatment/airway-treatment.component';
import { CirStartComponent } from './CIRCULATION/cir-start/cir-start.component';
import { CircStopComponent } from './CIRCULATION/circ-stop/circ-stop.component';
import { CircMaintainComponent } from './CIRCULATION/circ-maintain/circ-maintain.component';
import { CircAdministerComponent } from './CIRCULATION/circ-administer/circ-administer.component';
import { CircRetainComponent } from './CIRCULATION/circ-retain/circ-retain.component';
import { CircTitrateComponent } from './CIRCULATION/circ-titrate/circ-titrate.component';
import { CircThinkComponent } from './CIRCULATION/circ-think/circ-think.component';
import { CircTreatmentComponent } from './CIRCULATION/circ-treatment/circ-treatment.component';
import { ExpReassessComponent } from './EXPOSURE/exp-reassess/exp-reassess.component';
import { ExpTreatmentComponent } from './EXPOSURE/exp-treatment/exp-treatment.component';
import { ExpHypothermiaComponent } from './EXPOSURE/exp-hypothermia/exp-hypothermia.component';
import { ExpHyperthermiaComponent } from './EXPOSURE/exp-hyperthermia/exp-hyperthermia.component';
import { ExpBurnsComponent } from './EXPOSURE/exp-burns/exp-burns.component';
import { ExpToxinsComponent } from './EXPOSURE/exp-toxins/exp-toxins.component';
import { FurReassessComponent } from './FURTHER TREATMENT/fur-reassess/fur-reassess.component';
import { FurPackageComponent } from './FURTHER TREATMENT/fur-package/fur-package.component';
import { FurHistoryComponent } from './FURTHER TREATMENT/fur-history/fur-history.component';
import { FurAnalgesiaComponent } from './FURTHER TREATMENT/fur-analgesia/fur-analgesia.component';
import { FurAntibioticsComponent } from './FURTHER TREATMENT/fur-antibiotics/fur-antibiotics.component';
import { FurHandoverComponent } from './FURTHER TREATMENT/fur-handover/fur-handover.component';
import { AssSceneComponent } from './Pre-Primary/ASSESS/ass-scene/ass-scene.component';
import { TriageSearchComponent } from './Pre-Primary/TRIAGE/triage-search/triage-search.component';
import { TriageCasualtyComponent } from './Pre-Primary/TRIAGE/triage-casualty/triage-casualty.component';
import { TriageEvacComponent } from './Pre-Primary/TRIAGE/triage-evac/triage-evac.component';
import { BreathingTreatmentComponent } from './BREATHING/breathing-treatment/breathing-treatment.component';
import { BreathingChestDrainComponent } from './BREATHING/breathing-chest-drain/breathing-chest-drain.component';
import { BreathingVentilateComponent } from './BREATHING/breathing-ventilate/breathing-ventilate.component';
import { AirwayGoalComponent } from './AIRWAY/airway-goal/airway-goal.component';
import { BreathingGoalComponent } from './BREATHING/breathing-goal/breathing-goal.component';
import { CircGoalComponent } from './CIRCULATION/circ-goal/circ-goal.component';
import { DisGoalComponent } from './DISABILITY/dis-goal/dis-goal.component';
import { ExpGoalComponent } from './EXPOSURE/exp-goal/exp-goal.component';
import { PrePrimaryGoalComponent } from './Pre-Primary/pre-primary-goal/pre-primary-goal.component';
import { PrimaryGoalComponent } from './Primary-Survey/primary-goal/primary-goal.component';
import { DatabaseService } from './database.service';
import { PdfComponent } from './pdf/pdf.component';
import { ExpDivingComponent } from './EXPOSURE/exp-diving/exp-diving.component';
import { ExpAltitudeComponent } from './EXPOSURE/exp-altitude/exp-altitude.component';
import { ExProxComponent } from './EXSANGUINATION/ex-prox/ex-prox.component';
import { ScenarioComponent } from './scenario/scenario.component';
import { EXFoleyComponent } from './EXSANGUINATION/ex-foley/ex-foley.component';
import { FirebaseService } from './firebase.service';
import { SettingsComponent } from './settings/settings.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { CircTractFracturesComponent } from './CIRCULATION/circ-tract-fractures/circ-tract-fractures.component';
import { CircPelvicBinderComponent } from './CIRCULATION/circ-pelvic-binder/circ-pelvic-binder.component';
import { CircIvAccessComponent } from './CIRCULATION/circ-iv-access/circ-iv-access.component';
import { CircIoAccessComponent } from './CIRCULATION/circ-io-access/circ-io-access.component';
import { CircReconPlasmaComponent } from './CIRCULATION/circ-recon-plasma/circ-recon-plasma.component';
import { CircWholeBloodTransfusionComponent } from './CIRCULATION/circ-whole-blood-transfusion/circ-whole-blood-transfusion.component';
import { CircEmergencyDonorComponent } from './CIRCULATION/circ-emergency-donor/circ-emergency-donor.component';
import { CircWholeBloodDonationComponent } from './CIRCULATION/circ-whole-blood-donation/circ-whole-blood-donation.component';
import { DisCbtCasComponent } from './DISABILITY/dis-cbt-cas/dis-cbt-cas.component';
import { DisTBIComponent } from './DISABILITY/dis-tbi/dis-tbi.component';
import { DisIBAComponent } from './DISABILITY/dis-iba/dis-iba.component';
import { DisLowerLOCComponent } from './DISABILITY/dis-lower-loc/dis-lower-loc.component';
import { DisSpinalInjuryComponent } from './DISABILITY/dis-spinal-injury/dis-spinal-injury.component';
import { DisSeizuresComponent } from './DISABILITY/dis-seizures/dis-seizures.component';
import { DisMildTbiComponent } from './DISABILITY/dis-mild-tbi/dis-mild-tbi.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { ExpNerveAgentComponent } from './EXPOSURE/exp-nerve-agent/exp-nerve-agent.component';
import { ExpCyanideComponent } from './EXPOSURE/exp-cyanide/exp-cyanide.component';
import { ExpOpiatesComponent } from './EXPOSURE/exp-opiates/exp-opiates.component';




@NgModule({
  declarations: [
    AppComponent,
    BlueBoxComponent,
    HomeComponent,
    GreyBoxComponent,
    WhiteBoxComponent,
    LightblueBoxComponent,
    LightgreenBoxComponent,
    InfoPageComponent,
    TickComponent,
    TacticsRouteComponent,
    NotesComponent,
    LimbTQComponent,
    PackingComponent,
    ITClampComponent,
    LbInfoComponent,
    LgInfoComponent,
    CathemAssessComponent,
    CatHemReassessComponent,
    JunctionalTQComponent,
    AAJTComponent,
    PosturalDrainageComponent,
    InspectClearComponent,
    OpenComponent,
    MaintainComponent,
    ProtectComponent,
    AirwayAssessComponent,
    AirwayReAssessComponent,
    SurgicalCricComponent,
    EGAComponent,
    IntubationComponent,
    NavbarComponent,
    BreathingAssessComponent,
    BreathingReAssessComponent,
    ChestSealComponent,
    DecompressComponent,
    CircAssesComponent,
    CircReAssessComponent,
    DisAssessComponent,
    DisReAssesComponent,
    DisRBAComponent,
    DisIndicationsComponent,
    DisHyperventilationComponent,
    DisHypoxiaComponent,
    DisHypotensionComponent,
    DisHighICPComponent,
    DisHypoglycaemiaComponent,
    CatHemTreatmentComponent,
    FurSecondarySurveyComponent,
    WhiteBoxInfoComponent,
    GoalRiskComponent,
    GoalInfoComponent,
    XGoalComponent,
    ExpAssessComponent,
    SafetySelfComponent,
    SafetySceneComponent,
    SafetyCasualtyComponent,
    CommsTeamComponent,
    CommsEvacuationComponent,
    CommsCasualtyComponent,
    AirwayTreatmentComponent,
    CirStartComponent,
    CircStopComponent,
    CircMaintainComponent,
    CircAdministerComponent,
    CircRetainComponent,
    CircTitrateComponent,
    CircThinkComponent,
    CircTreatmentComponent,
    ExpReassessComponent,
    ExpTreatmentComponent,
    ExpHypothermiaComponent,
    ExpHyperthermiaComponent,
    ExpBurnsComponent,
    ExpToxinsComponent,
    FurReassessComponent,
    FurPackageComponent,
    FurHistoryComponent,
    FurAnalgesiaComponent,
    FurAntibioticsComponent,
    FurHandoverComponent,
    AssSceneComponent,
    TriageSearchComponent,
    TriageCasualtyComponent,
    TriageEvacComponent,
    BreathingTreatmentComponent,
    BreathingChestDrainComponent,
    BreathingVentilateComponent,
    AirwayGoalComponent,
    BreathingGoalComponent,
    CircGoalComponent,
    DisGoalComponent,
    ExpGoalComponent,
    PrePrimaryGoalComponent,
    PrimaryGoalComponent,
    PdfComponent,
    ExpDivingComponent,
    ExpAltitudeComponent,
    ExProxComponent,
    ScenarioComponent,
    EXFoleyComponent,
    SettingsComponent,
    DisclaimerComponent,
    SignUpComponent,
    CircTractFracturesComponent,
    CircPelvicBinderComponent,
    CircIvAccessComponent,
    CircIoAccessComponent,
    CircReconPlasmaComponent,
    CircWholeBloodTransfusionComponent,
    CircEmergencyDonorComponent,
    CircWholeBloodDonationComponent,
    DisCbtCasComponent,
    DisTBIComponent,
    DisIBAComponent,
    DisLowerLOCComponent,
    DisSpinalInjuryComponent,
    DisSeizuresComponent,
    DisMildTbiComponent,
    PasswordResetComponent,
    ExpNerveAgentComponent,
    ExpCyanideComponent,
    ExpOpiatesComponent,


  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFireDatabase,
    FormsModule,
    ModalModule,
    AppRouterModule,
    ServiceWorkerModule.register( 'ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately'
    })
  ],
  providers: [DatabaseService, FirebaseService, AngularFirestore],
  bootstrap: [AppComponent]
})
export class AppModule { }
