import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../firebase.service';
import 'firebase/functions';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  userEmail = '';

  constructor(
    public afAuth: AngularFireAuth,
    public firebaseService: FirebaseService,
  ) { }

  ngOnInit(): void {
  }

  CheckEmail(){
    alert(this.userEmail)
  }

  PasswordReset(){
    this.afAuth.sendPasswordResetEmail(this.userEmail);
    alert('A password reset link will be sent to your registered email shortly')
  }
}
