import { Component, Input, OnInit } from '@angular/core';
import { DatabaseService } from '../database.service';
import * as firebase from 'firebase/app';
import { FirebaseService } from '../firebase.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.css']
})
export class InfoPageComponent implements OnInit {
  @Input() name: string;
  data: {};
  presented = false;

  constructor(private databaseService: DatabaseService,
    public firebaseService: FirebaseService,
    private router: Router) { }

  ngOnInit(): void {
    this.data = this.databaseService.database;

    this.checkPayments();
  }

  ifPresented(id) {
    return this.data[id.name].presented
  }

  checkPayments() {
    if (!this.firebaseService.isLoggedIn) {
      this.router.navigateByUrl('/?not-authorized=true');
    } else {
      try {
        firebase.default
          .firestore()
          .collection('production_customers')
          .doc(this.firebaseService.userID)
          .collection('payments')
          .where('livemode',"==", true)
          .get()
          .then(async (querySnapshot) => {
            if (querySnapshot.size <= 0) {
              this.router.navigateByUrl('/?no-subscription=true');
            }
          })
      } catch (error) {
        console.log("error validating subscribtion", error);
        alert('an error occurred while attempting to validate your subscription. Our team is busy investigating what happened, please try again later.')

        this.router.navigateByUrl('/');
      }
    }

  }

  // checkSubs() {
  //   if (!this.firebaseService.isLoggedIn) {
  //     this.router.navigateByUrl('/?not-authorized=true');
  //   } else {
  //     try {
  //       firebase.default
  //         .firestore()
  //         .collection('production_customers')
  //         .doc(this.firebaseService.userID)
  //         .collection('subscriptions')
  //         .where('current_period_end', '>', new Date())
  //         .get()
  //         .then(async (querySnapshot) => {
  //           if (querySnapshot.size <= 0) {
  //             this.router.navigateByUrl('/?no-subscription=true');
  //           }
  //         })
  //     } catch (error) {
  //       console.log("error validating subscribtion", error);
  //       alert('an error occurred while attempting to validate your subscription. Our team is busy investigating what happened, please try again later.')

  //       this.router.navigateByUrl('/');
  //     }
  //   }

  // }

}
