<div class="container">
  <app-lightgreen-box name="FurPackage">PACKAGE</app-lightgreen-box>

  <app-lg-info>


    <h2>Stretcher </h2>
          <ul>
            <li>Get Casualty on a Stretcher ASAP</li>
            <li>Default is the Lateral Airway Position </li>
            <li>Lateral Inline-Airway Position if spinal injury suspected</li>
            <li>Covered</li>
            <li>Strapped on</li>
          </ul>

          <h2>PPE</h2>
          <ul>
            <li>Ballistic: Replace body armour & helmet</li>
            <li>Ear protection </li>
            <li>Eye protection (Glasses or Eye patch) </li>
          </ul>

          <iframe src="https://player.vimeo.com/video/915096041?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="F - Packaging"></iframe>

  </app-lg-info>

</div>
