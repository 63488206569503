<div class="container">
    <div class="header">
        <img src="../../assets/logo.png" alt="">
        <h1>ATEM ACC Drill</h1>
    </div>
    
        <div class="input-fields">
            <form action="">
            <label for="cas-name">Casualty Details:</label>
            <input type="text" id="cas-name" #casname>
            <label for="incident">Incident:</label>
            <input type="text" id="incident" #incident>
            <label for="time">Time of Injury:</label>
            <input type="text" id="incident" #time>
            <!-- <label for="time">Time:</label>
            <input type="text" id="time" #time> -->
            <label for="medic-name">Medic Name:</label>
            <input type="text" id="medic-name" #medicname>
            </form>
        </div>
    <button (click)="updateScenario(casname,incident,medicname,time)" routerLink="/infoPage">Start</button>
    <button (click)="refreshPage()">Clear</button>
</div>