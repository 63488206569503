import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breathing-treatment',
  templateUrl: './breathing-treatment.component.html',
  styleUrls: ['./breathing-treatment.component.css']
})
export class BreathingTreatmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
