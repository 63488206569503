<div class="container">
    <app-lightgreen-box name="XsanguinationProx">Foley Catheter</app-lightgreen-box>

    <app-lg-info>

    <h1>Indications</h1>
    
        <ul>
            <li>X Bleeding from junctional wounds to the neck & subclavian vessels</li>
        </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit:</li>
                    <ul>
                        <li>18 or 20 Gauge Foley catheter - Check balloon before insertion</li>
                        <li>Syringe with WFI</li>
                        <li>Suture kit</li>
                    </ul>
                <li>Cas: Position casualty for access to wound / Apply direct pressure</li>
            </ul>

    <h1>Procedure</h1>

        <h2>1 Insert</h2>
        
            <ul>
                <li>Insert along wound tract past balloon area of Foley</li>
            </ul>

        <h2>2 Inflate</h2>
            
            <ul>
                <li>Inflate with 5ml of fluid or until resistance  </li>
            </ul>

        <h2>3 Tie off</h2>
            <ul>
                <li>Tie off or clamp Foley preventing bleeding via urine lumen</li>
            </ul>


        <h1>Post Procedure</h1>

        <h2>Check</h2>
            
            <ul>
                <li>Check for leaking from wound / Recheck regularly especially after movement </li>
            </ul>

        <h2>Secure</h2>
        <ul>
            <li>Consider: Suturing in place / neck junction pressure bandage</li>
        </ul>

            <hr>

        <h1 style="color: orange;">Risk</h1>
            <ul>
                <li style="color: orange;">Leaking</li>
                    <ul><li>Suture wound around catheter</li></ul>
                <li style="color: orange;">Accidental removal</li>
                    <ul><li>Secure loose end of catheter</li></ul>
                <li style="color: orange;">Accidental insertion into the Airway</li>
                    <ul><li>Assess airway on inflation - deflate and withdraw</li></ul>
            </ul>

            <iframe src="https://player.vimeo.com/video/608484883?h=172eabe020" width="320" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>


    </app-lg-info>

</div>


