import { Component, OnInit, Input } from '@angular/core';
import { DatabaseService } from 'src/app/database.service';

@Component({
  selector: 'app-lightgreen-box',
  templateUrl: './lightgreen-box.component.html',
  styleUrls: ['./lightgreen-box.component.css']
})
export class LightgreenBoxComponent implements OnInit {
  @Input() presented:boolean;
  @Input() info:string;
  @Input() name:string;
  data:{};

  constructor( private databaseService: DatabaseService) {}

  ngOnInit(): void {
    this.data= this.databaseService.database
    this.presented = this.data[this.name].presented 
  }

  onClicked(){
    this.presented = !this.presented;
    this.databaseService.database[this.name].presented = this.presented
  };
}