
<div class="container">
    <app-lightgreen-box name="DisabilityCbtCas">Combative Cas</app-lightgreen-box>
   
    <app-lg-info>

        <h1>Sedation of Combative Casualty </h1>

        <h2>Indications </h2>
        <ul>
            <li>Combative Casualty following head injury and suspected TBI</li>
        </ul>

        <h2>Procedure </h2>
        <ul>
            <li>Disarm and remove sensitive items like radios</li>
                <li>Consider using Ketamine or Midazolam for sedation</li>
        </ul>

        <h2 style="color: orange;">Risk</h2>
        <ul style="color: orange;">
            <li>Midazolam may affect the casualty's ability to maintain their airway</li>
            <li>Drugs may impact the cardiovascular system in haemorrhagic shock </li>
            <li>Drugs affect the ability to conduct a neurological examination</li>
        </ul>


        <hr>

        <iframe src="https://player.vimeo.com/video/871783926?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="D - Combat Cas"></iframe>

    </app-lg-info>

</div>