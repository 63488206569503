<app-white-box class="header" name="SafetySelf">SELF</app-white-box>
<app-white-box-info>

  <!-- Copy and paste as necessary  -->

    <h1 >PPE</h1>

      <h2>Tactical PPE</h2>

        <ul>
            <li>Weapons</li>
            <li>Body Armour</li>
            <li>Helmet</li>

        </ul>

        <h2>Medical PPE</h2>

        <ul>
          <li>Gloves</li>
        
          <li>Glasses</li>
        
          <li>Mask</li>
        </ul>

        <h2>Environmental PPE</h2>
        
        <ul>
        <li>CBRN & HazMat</li>
        <li>Confined Space</li>
        <li>High Angle</li>
        <li>Water</li>
        <li>Road Traffic</li>
        </ul>
        
       

</app-white-box-info>
