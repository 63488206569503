<div class="container">
  <app-lightgreen-box name="ExposureHypothermia">HYPOTHERMIA</app-lightgreen-box>

  <app-lg-info>
      <H2>Classification</H2>
      <ul>
        <li>Mild: 34-36°C </li>
        <li>Moderate: 32-34°C</li>  
        <li>Severe: < 32°C </li>  
      </ul>

    <h2>Symptoms</h2>
    <ul>
        <li>Shivering / Fatigue / Loss of coordination  </li>
        <li>Tachypnoea becoming difficult to detect </li>
        <li>Tachycardia becoming Bradycardia & difficult to detect</li>
    </ul>

    <h2>IA Drill</h2>
    <ul>
       
        <li>Shelter ASAP from environmental conditions </li>
        <li>Remove wet clothing</li>
        <li>Off the ground</li>
        <li>Insulate</li>
        <li>Warm</li>
        <li>IA on no signs of life:</li>
        <ul>
            <li>Handle gently due to risk of VF</li>
            <li>Start CPR only where it can continue unchecked</li>
        </ul>

    </ul>
    <h2>Treatment</h2>
    <ul>
        <li>Rewarm with active heat: </li>
             <ul>
                 <li>Chemical heater</li>
                 <li>Hot water bottle</li>
                 <li>Buddy heat</li>
             </ul>
         <Li>Monitor core temp</Li>
    </ul>

    <h2>Consider </h2>
    <ul>
        <li>Hypoxia Induced Hypothermia </li>
        <li>Freezing and non-freezing cold injury to extremities</li>
    </ul>

    <h2 style="color: orange;">Risk</h2>
    <ul>
        <li style="color: orange;">May be mistaken for dead</li>
        <ul>
            <li>Not dead until warm & dead</li>
        </ul>
        <li style="color: orange;">Aggressive movement may cause cardiac arrest</li>
        <ul>
            <li>Move as gently as possible </li>
        </ul>
        <li style="color: orange;">Overheating & Hyperthermia </li>
        <ul>
            <li>Reassess regularly</li>
        </ul>
        <li style="color: orange;">Burns from active heating element</li>
        <ul>
            <li>Do not place heating element against skin</li>
        </ul>
        <li style="color: orange;">Unrecognised Hypoglycaemia</li>
        <ul>
            <li>Check blood sugar </li>
        </ul>
    </ul>
  
    <iframe src="https://player.vimeo.com/video/894070116?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="E - Hypothermia"></iframe>

  </app-lg-info>

</div>
