<div class="container">
    <app-lightgreen-box name="XsanguinationAAJT">AAJT-S</app-lightgreen-box>

    <app-lg-info>
        <h1>Indications</h1>
            <ul>
                <li>Uncontrollable X Bleeding from axilla / groin / pelvis / lower limb </li>
            </ul>
    <h1>Contraindications </h1>
        <ul>
            <li>Known Abdominal Aortic Aneurysm / Pregnancy</li>
        </ul>
        <hr>

        <h1>Pre-Procedure</h1>
            <h2>Prep</h2>
                <ul>
                    <li>Kit: Retrieve AAJT-S and inspect </li>
                    <li>Cas: Place in the supine position</li>
                </ul>

        <h1>Procedure</h1>
            
            <h2>1 Secure</h2>
                
                <ul>
                    <li>Secure device around waist / hips / shoulder - Connect Ladder Strap until RED MEETS RED </li>
                </ul>

            <h2>2 Position</h2>

                <ul>
                    <li>Position over target area</li>
                </ul>


            <h2>3 Tighten</h2>
                <ul>
                    <li>Tighten belt – Remove all slack</li>
                </ul>
    
            <h2>4 Ratchet</h2>
                <ul>
                    <li>Use ratcheting buckle to complete tightening  </li>
                </ul>
            
            <h2>5 Inflate</h2>
                <ul>
                    <li>Inflate bladder until green indicator shows</li>
                </ul>

            <h2>6 Time</h2>

                <ul>
                    <li>Mark the time  of application on device and document</li>
                    <li>Max 1 hr abdominal use 4 hr for junctional</li>
                </ul>

            <h1>Post Procedure</h1>
                <h2>Check</h2>
                    <ul>
                        <li>Check for control of bleeding / Recheck regularly especially after movement </li>
                    </ul>

                <h2>Secure</h2>
                    <ul>
                        <li>Tuck away straps / Caution dragging or moving using the device </li>
                    </ul>
        <hr>

        <h1 style="color: orange;">Risk</h1>
            
            <ul>
                <li style="color: orange;">Re-bleeding with movement</li>
                    <ul><li>Recheck regularly especially after movement</li></ul>

                <li style="color: orange;">Ischaemia / Toxic metabolites on release</li>  
                    <ul><li>Limit time</li></ul>

                <li style="color: orange;">Occlusion of abdominal vein only</li>
                    <ul><li>Position centrally over abdomen and inflate to correct pressure </li></ul>

                <li style="color: orange;">AAJT affected by altitude</li>
                    <ul><li>Reassess and adjust pressure </li></ul>
            
            </ul>

            <iframe src="https://player.vimeo.com/video/599560900?h=087edecffe" width="320" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>



    </app-lg-info>
</div>