import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exp-opiates',
  templateUrl: './exp-opiates.component.html',
  styleUrls: ['./exp-opiates.component.css']
})
export class ExpOpiatesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
