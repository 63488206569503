<div class="container">
    <app-lightgreen-box name="CirculationPelvicBinder">Pelvic Binder</app-lightgreen-box>
  
    <app-lg-info>
        
        <h1>Indications</h1>
    
        <ul>
            <li>Pelvic Fracture</li>
            <li>S&S</li>
            <ul>
                <li>MOI:</li>
                <ul>
                    <li>Direct trauma</li>
                    <li>Lower limb amputation from blast injury</li>
                    <li>Indirect (fall from height)</li>
                    <li>Poly-trauma</li>
                </ul>
                <li>Pain</li>
                <li>Instability</li>
                <li>Shock</li>
            </ul>
        </ul>

    <h2>Relative Contraindications</h2>
        <ul>
            <li>Open Pelvic Fracture</li>

        </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit: </li>
                    <ul>
                        <li>PPE, Gloves & Eye pro</li>
                        <li>Pelvic binder of correct size</li>
                    </ul>
                <li>Cas:   </li>
                    <ul>
                        <li>Position supine for pelvic binder application</li>
                    </ul>
            </ul>
        
    <h1>Procedure</h1>

        <h2>SAM Pelvic Sling</h2>

        <h2>1 Position</h2>
        
            <ul>
                <li> Place under legs or lower back and slide into position</li>
                <li> The centre of the sling should be in line with the greater trocanters </li>
            </ul>


        <h2>2 Buckle</h2>
            
            <ul>
                <li>Attach the buckle </li>
                
            </ul>

        <h2>3 Tighten</h2>
            <ul>
                <Li> Stabilise to avoid movement </Li>
                <li> Tighten until a click is heard or felt </li>
            </ul>

        <h2>4 Secure</h2>
            <ul>
                <li>Press Velcro down securing the strap</li>
            </ul>

        <h2>5 Feet</h2>
        <ul>
            <li>Secure feet together </li>
        </ul>

    <h1>Post Procedure</h1>    
    
        <h2>Check</h2>
        
            <ul>
                <li>Position</li>
                <li>Compression </li>
                <li>Recheck after movement</li>
            </ul>
        
        <h2>Secure</h2>
            <ul>
                <li>Strap</li>
            </ul>

    
    <h1 style="color: orange">Risk</h1>
        <ul>

            <li style="color: orange;">No Improvement </li>
                <ul>
                    <li>Continuing internal non-compressible bleeding</li>
                    <li>Consider AAJT</li>
                </ul>
            
        </ul>

    <hr>

    <iframe src="https://player.vimeo.com/video/843197271?h=29cf9a61a0" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </app-lg-info>

  </div>
  