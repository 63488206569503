<div class="container">
  <app-lightgreen-box name="FurSecondarySurvey">Secondary Survey</app-lightgreen-box>

  <app-lg-info>

      <h1>Goal</h1>
      <ul>
          <li>To question the casualty & search from head to toe to find and treat non-life-threatening injuries</li>
      </ul>
      
      <h1>Risk</h1>
          <ul>
            <li>Musculoskeletal injuries: Fractures / Dislocations / Sprains / Strains  </li>
            <li>Eye trauma </li>
            <li>Wounds</li>
            <li>MTBI</li>
            <li>Medical conditions</li>
          </ul>

          <h1>Injuries </h1>
          <h2>Musculoskeletal injuries</h2>
          <ul>
            <li>Fractures</li>
              <ul>
                <li>A break in the bone</li>
                <li>Types: Closed / Open - Wound over fracture / Complicated - affecting other systems</li>
                <li>Closed: Greenstick / Transverse / Comminuted / Spiral / Compound </li>
                <li>Symptoms: Bleeding / Pain / Swelling / Deformity / Abnormal movement / Crepitus </li>
                <li>Treatment: </li>
                  <ul>
                    <li>Stop all X Bleeding </li>
                    <li>Check distal neuro-vascular function</li>
                    <li>Move limb into anatomical alignment </li>
                    <li>Recheck distal neuro-vascular function</li>
                    <li>Splint along the axis of the bone</li>
                    <li>Immobilise the joint above and below</li>
                    <li>Open fracture: Stop bleeding / Clean by irrigation  / Attempt reduction</li>
                  </ul>
              </ul>

              <li>Dislocations</li>
                  <ul>
                    <li>Def: A separation of two bones where they meet at a joint</li>
                    <li>Types: Complete / Subluxation / Fracture dislocation</li>
                    <li>Symptoms: Pain / Swelling / Deformity / Abnormal joint movement </li>
                    <li>Shoulder dislocation is the most common 50% of those 95% are anterior</li>
                    <li>Treatment: Attempt reduction if the technique is known - Use FARES technique for anterior shoulder dislocation</li>
                    <li>If not - Immobilise the joint as much as possible </li>
                  </ul>

            <li>Sprains & Strains</li>
                  <ul>
                    <li>Def: A sprain is an injury to the ligaments and capsule of a joint in the body</li>
                    <li>Def: A strain is an injury to muscles or tendons</li>
                    <li>Symptoms: Pain / Swelling / Deformity</li>
                    <li>Ankle inversion is most common sprain in the field</li>
                    <li>Treatment: Stop activity / Support joint or muscle (Strap) / Analgesia / Evacuation</li>
                  </ul>
            <li>Compartment syndrome</li>
                  <ul>
                    <li>Def: An increase of pressure in the muscle compartment from trauma or tourniquet </li>
                    <li>Symptoms: Severe pain in muscle compartment: Tension - Tenderness - Temperature / Distal pulselessness / Loss of sensory or motor function</li>
                    <li>Treatment: Identify / Elevate / Analgesia / Emergency fasciotomy may be limb saving if skill and equipment are available </li>
                  </ul>
          </ul>

          <h2>Eye trauma</h2>
            <ul>
              <li>Penetrating eye trauma </li>
                  <ul>
                    <li>Def: Puncture of the orbit of the eye</li>
                    <li>Symptoms: Pain / Loss of vision</li>
                    <li>Treatment: Test vision / Document / Apply a rigid eye shield / Cover only the injured eye / Give antibiotic </li>
                  </ul>

              <li>Ocular compartment syndrome</li>
                    <ul>
                      <li>Def: Build up of pressure behind the eye</li>
                      <li>Symptoms: Pain / Loss of vision / Decreased movement / Bulging eye</li>
                      <li>Treatment: Lateral canthotomy can be vision saving if skill and equipment available</li>
                    </ul>
            </ul>
          
          <h2>Wounds</h2>
            <ul>
              <li>Def: A break in the skin and damage to underlying structures</li>
              <li>Types: Minor / Lacerations / Fragmentation / Impaled objects / Bowel evisceration </li>
              <li>Symptoms: Bleeding / Pain </li>
              <li>Treatment:</li>
                  <ul>
                    <li>Minor</li>
                        <ul>
                          <li>Clean with irrigation / Direct pressure / Cover with plaster or tape</li>
                        </ul>
                    <li>Lacerations</li>
                          <ul>
                            <li>Clean with irrigation / Pack if possible / Dress the wound / Delayed closure if contaminated</li>
                          </ul>
                    <li>Fragmentation</li>
                          <ul>
                            <li>Stop bleeding / Cover and dress / Delayed surgical extraction  </li>
                          </ul>
                    <li>Impaled object</li>
                          <ul>
                            <li>Support in place / Evacuate for surgical removal </li>
                          </ul>
                    <li>Bowel evisceration</li>
                          <ul>
                            <li>Gently clean the bowel using irrigation, cover the bowel with moist dressing and plastic outer cover to keep moist</li>
                            <li>If possible attempt to replace the eviscerated bowel -  max 1 min - Do not use force</li>
                            <li>On replacement reapproximate the wound edges using a chest seal</li>
                          </ul>
                  </ul>
            </ul>

            <h2>Mild - TBI</h2>
              <ul>
                <li>Def: Mild - TBI (Concussion) from blunt trauma or blast (50m)</li>
                <li>Remove weapons & radios</li>
                <li>Use MACE Score to check for symptoms</li>
                <li>Evacuate</li>
              </ul>

            <h2>Medical Conditions </h2>
              <ul>
                <li>Check for underlying medical condition / Drugs that may be contributing to the casualty's condition</li>
                    <ul>
                      <li>Example: Many combat casualties are dehydrated prior to injury</li>
                    </ul>
              </ul>


  </app-lg-info>

</div>
