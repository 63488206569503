import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  // private atemDevEmail = 'jonny@atem.org.com'; not sure why this is here
  
  userID:string = '';
  hasSubs:boolean;
  hasAccount:boolean;
  isLoggedIn:boolean;
  userEmail:string = '';

  // public user$: Observable<any> = this.afAuth.user.pipe(shareReplay(1)); Not sure why this is here

  constructor(public afAuth : AngularFireAuth, private router: Router, private ngZone: NgZone) { 
    // this.user$.subscribe();
  }

  // async signIn (email : string, password : string){
  //   await this.firebaseAuth.signInWithEmailAndPassword(email, password)
  //   .then(res=>{
  //     this.isLoggedIn = true
  //     localStorage.setItem('user',JSON.stringify(res.user))
  //   })
  // }
  // logOut(){
  //   this.firebaseAuth.signOut()
  //   localStorage.removeItem('user')
  // }

  signInWithEmail(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
    .then((res) => {
      this.isLoggedIn = true;
      localStorage.setItem('user',JSON.stringify(res.user))
      console.log('AuthService::Successful Email login', res);
      return this.ngZone.run(() => this.router.navigate(['/']));
    });
  }

  signUpWithEmail(email: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  logout() {
    this.afAuth.signOut
    alert('youve been logged out')
  }




}
