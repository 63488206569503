<div class="container">
  <app-lightblue-box name="FurAntiBiotics">ANTIBIOTICS</app-lightblue-box>
<app-lb-info>

  <h2>Penetrating Trauma</h2>
  <ul>
      <li>Ceftriaxone 1 gram slow IV/IO</li>
      <li>OR</li>
      <li>Cefazolin 2 grams slow IV</li>
      <li>OR</li>
      <li>Ertapenem 1 gram slow IV/IO/IM </li>
      <li>OR</li>
      <li>Moxifloxacin 400mg OD Oral</li>
  </ul>

  <h2>Possible Gut Perforation</h2>
  <ul>
      <li>Ceftriaxone 2 grams slow IV</li>
      <li>And</li>
      <li>Metronidazole 1 gram suppository </li>
      <li>OR</li>
      <li>Ertapenem 1 gram infusion slow IV/IO/IM </li>
      <li>And</li>
      <li>Moxifloxacin 400mg OD Oral</li>
  </ul>

  <iframe src="https://player.vimeo.com/video/915107682?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="F - Antibiotics"></iframe>

</app-lb-info>
</div>
