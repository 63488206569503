import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aajt',
  templateUrl: './aajt.component.html',
  styleUrls: ['./aajt.component.css']
})
export class AAJTComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
