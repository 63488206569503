import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-lightblue-box',
  templateUrl: './lightblue-box.component.html',
  styleUrls: ['./lightblue-box.component.css']
})
export class LightblueBoxComponent implements OnInit {
  @Input() presented:boolean;
  @Input() info:string;
  @Input() name:string;

  constructor() {
    this.presented = false;
   }

  ngOnInit(): void {
  }

  onClicked(){
    this.presented = !this.presented;
  };
}
