
<app-white-box class="header" name="CommsTeam">Team</app-white-box>

<app-white-box-info>

  <!-- Copy and paste as necessary  -->

      <h2>Get team members to assist with:</h2>

        <ul>
            <li>Treatment</li>
            <li>Initiate EVAC - Send 9 Liner</li>
            <li>Security</li>
            <Li>Casualty Movement</Li>
            <li>HLS</li>

        </ul>

</app-white-box-info>
