<div class="container">
    <app-lightgreen-box name="AirwayIntubation">INTUBATION</app-lightgreen-box>
   
    <app-lg-info>

        <h1>Indications</h1>

            <ul>

                <li>Polytrauma after or with resuscitation using blood products</li>
                <li>Isolated TBI</li>
                <li>Elective for Airway Burns</li>
                <Li>Prior to Surgery</Li>
                
            </ul>

        <hr>

        <h1>Pre Procedure</h1>

                <ul>

                    <li>Decision</li>

                        <ul>
                            <li>Basic airway interventions inadequate ?</li>
                            <li>Time and Environment? Equipment / Skill / Experience - Available ?</li>
                            <li>Physiological impact considered ?</li>
                            <li>Anticipated difficulty ?</li>
                            <li>DSI & Cric considered ?</li>
                    
                    </ul>  

                    <li>Prep Kit: Challenge call all kit and check function</li>

                    <ul>
                        
                        <li>Basics</li>
                            <ul>
                                <li>PPE</li>
                                <Li>IV/IO Access x 2 </Li>
                                <li>Oxygen / Mask / Nasal cannula</li>
                            </ul>
                        
                        <li>Monitoring</li>
                            <ul>
                                <li>Minimum: Steth / BP Cuff / SpO2 / ETCO2</li>
                                <li>Better: Electrical ETCO2 / BP Cuff</li>
                                <li>Best: Fully equipped Patient Monitor with ECG</li>
                            </ul>
                        
                        <li>Airway</li>
                            <ul>
                                <li>OPA</li>
                                <li>Suction</li>
                                <li>Magill forceps</li>
                                <li>Laryngoscope & 2 Blades</li>
                                <li>ET Tube x 2 (8 Male - 7 Female)</li>
                                <li>Lube / Syringe / Tube Holder</li>
                                <li>Bougie (Stylet)</li>
                            </ul>
                        
                        <li>Breathing</li>
                            <ul>
                                <li>BVM</li>
                                <li>Ventilator</li>
                            </ul>
                        
                        
                        <li>Drugs <span style="color: blue;">3:2:1 </span>Prehospital - RSI</li>
                            
                            <ul>
                            
                                <li>Fentanyl: <span style="color: blue;">3</span> mcg/kg</li>
                                
                                <li>Ketamine: <span style="color: blue;">2</span> mg/kg</li>
                                
                                <li>Rocuronium: <span style="color: blue;">1</span> mg/kg</li>

                                <li>Reduce dose in Shock  Consider:  <span style="color: blue;">1:1:1</span></li>
                                
                            </ul>

                        <li>Rescue</li>
                            <ul>
                                <li>EGA - iGel</li>
                                <li>Cric kit </li>
                            </ul>
                        
                        </ul>

                        <li>Prep Cas</li>
                    
                        <ul>
                            <li>Position for optimal air movement: Casualty choice /  LAP or LIP </li>
                            <li>Ensure airway is inspected & cleared of any obstruction</li>
                            <li> Prep to move into optimal area & position for Intubation</li>
                            <ul>
                                <li>Space to work on stable platform</li>
                                <li>Tragus of ear to level of sternum</li>
                                <li>Sniffing the morning air position</li>
                            </ul>
                            
                            <li>Pre-O2 for 3 min BVM & Nasal cannula on high flow O2</li>
                            
                            <li>IV x 2 & Checked</li>
                            
                            <li>Monitor</li>
                            
                            <li>Consider Pre-treatment (Blood)</li>
                            
                        </ul>
                </ul>

            <h1>Procedure</h1>
            
                <ul>
                   
                    <li>1: Push drugs</li>

                        <ul>
                            <li>In order: Fentanyl / Ketamine / Rocuronium</li>
                            <li>Proceed as breathing effort stops</li>
                        </ul>
                    
                    <li>2: Consider BVM Oxygenation

                        <ul>
                            <li>If no pre-oxygenation or low SpO2</li>
                            <li>Insert OPA</li>
                            <li>Bag with O2 & Nasal cannula 15 l/min minimum 2 min 3 is optimum</li>
                        
                        </ul>

                    </li>

                    
                    <li>3: View</li>

                        <ul>

                            <li>View cords via Direct Laryngoscopy</li>
                            <li>Insert slow on right side of mouth</li>
                            <li>Sweep tongue left  </li>
                            <Li>Creep lower - place tip of blade anterior to epiglottis </Li>
                            <li>Extend handle away from head at 45 degrees</li>
                            <li>BURP if required </li>
                        
                        </ul>

                    <li>4: Pass tube </li>

                        <ul>
                            <li>Consider preloading bougie into tube</li>
                            <li>Insert Bougie through cords (tracheal clicks)</li>
                            <li>Railroad Tube over Bougie to depth (23cm Male - 21cm Female)</li>
                            <li>Inflate Cuff</li>
                            <li>Hold tube in place until placement confirmed</li>
                        
                        </ul>
                    
                    <li>5: Confirmation</li>

                        <ul>
                            
                            <li>ETCO2 confirmation</li>
                            
                            <li>On ventilation auscultate: Stomach /  R & L Lung Sounds</li>

                            <li>Tie in tube once placement confirmed</li>
                        
                        </ul>
  
                    <li>30 Sec Drill</li>

                        <ul>

                            <li>If no view - Optimise or change:</li>

                            <li>Position: of operator & or patient</li>
                            
                            <li>Kit: Blade size or type</li>

                            <li>View:</li>
                                <ul>
                                    <li>Suction</li>
                                    <li>BURP</li>
                                    <li>Deep insertion slow withdrawal</li>
                                </ul>
                            
                        </ul>
                      
                    <li>Failed Intubation Drill</li>
                        <ul>
                            <li>Optimise Oxygenation using ventilation using BVM + O2 with airway adjuncts</li>
                            <li>Attempt placement of iGel (EGA) and ventilate</li>
                            <li>If that fails perform Surgical Cric</li>
                        </ul>
                    
                </ul>

            <h1>Post Procedure</h1>

                <ul>

                    <li>Check</li>

                        <ul>
                            <li>Placed on Ventilator</li>
                            
                            <li>Continuous ETCO2 monitoring</li>
                            
                            <li>Maintenance of Sedation</li>
                            
                        </ul>

                    <li>Secure</li>

                        <ul>
                            
                            <li>Tube tie</li>
                            <li>Ventilator hose</li>

                        </ul>
                   
                </ul>

    <hr>

    <h1 style="color: orange">Risk</h1>

        <ul>

            <li style="color: orange">Oesophageal Intubation</li>
            <ul>
                <li>Mitigate by confirmation of: View / ETCO2 / ODD / BVM with Chest expansion & Auscultation on ventilation</li>
            </ul>

        </ul>
        
        <ul>
            
            <li style="color: orange">Exacerbating shock with Drugs & PPV</li>
            <ul>
                <li>Consider resuscitation with blood products first</li>
                <li>Reduce induction drug dose</li>
                <li>Use a ventilation strategy that limits impact on CO</li>
            </ul>
            
            <li style="color: orange">Hyperventilation in TBI </li>
                <ul>
                    <li>Adjust ventilator to ETCO2 </li>
                    <li>Use a cadence device if using a BVM</li>
                </ul>
        
        </ul>

    <hr>

        <h1>Notes</h1>
        <ul>
            <li>S Ketamine - give half dose</li>
        </ul>

     <hr>

     <iframe src="https://player.vimeo.com/video/738898954?h=62b00731de" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </app-lg-info>

</div>