

<div class="container">
    <app-lightgreen-box name="DisabilityHypotension">Hypotension</app-lightgreen-box>
   
    <app-lg-info>

        <h1 style="color: green;" >Indications</h1>

        <ul style="color: red
        ;">

            <li>Avoid Hypotension in TBI</li>

     
        </ul>

        <hr>s

        <h1>Procedure</h1>
        
            <ul>

                <li>Maintain Airway</li>
                
                <li>Ventilate at 10 breaths per minuite maximum - with timekeeper</li>

                <li>Keep ETCO2 low normal</li>

                    <ul>
                        <li>35 - 45 mmHg</li>
                        <li>4.6 - 5.3 kPa</li>
                    </ul>

            </ul>

        <hr>

        <h1 style="color: orange">Risk</h1>

            <ul style="color: orange;">
                
                <li>Positive pressure ventilation will decrease cardiac output</li>
                    <ul>
                        <li>Hyperventilation</li>
                        <li>High ventilation pressures including PEEP</li>
                        <li>High tidal volumes</li>
                    </ul>
                
            </ul>


    </app-lg-info>

</div>