<div class="container" *ngIf=true>
    <div class="imgBox">
      <img src="../../assets/logo.png" alt="">
    </div>

    <div class="input-fields">
        <div>
            <p>Please input a registered email address. </p>
        </div>
      <div class="email-field">
        <input type="email" placeholder="Email" [(ngModel)]="userEmail">
      </div>

      <div class="buttons">
        <button (click)="PasswordReset()">Reset Password</button>

      </div>
    </div>
  
    
  

  
    <a class=" webLink" href="http://www.atem.org.uk">www.atem.org.uk</a>
  
    <footer>&copy; Copyright 2021 ATEM <br> v0.2</footer>
  
  </div>