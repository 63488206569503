<div class="container" id="element-to-export">
    <!-- <button (click)='export2Pdf()'>Export To PDF</button> -->
    <img class="logo" src="../../assets/logo.png" (click)='export2Pdf()'>
    
    
        <div class="scenario">
        
            <h1 class="heading-main">ATEM Acute Critical Care Drill</h1>

                <h2 class="scenario-Casualty">Casualty: {{this.scenarioInfo.CasualtyDetails}}</h2>
                
                <h2 class="scenario-Incident">Incident: {{this.scenarioInfo.Incident}}</h2>
               
                <h2 class="scenario-Time">Time: {{this.scenarioInfo.Time}}</h2>
                
                <h2 class="scenario-Medic">Medic Name: {{this.scenarioInfo.MedicName}}</h2>
        </div>
        
    <hr>

        <div class="Pre-Primary">
        
            <h2 class="psp-heading">Pre-Primary Survey Priorities</h2>
            
                <h3 class="psp">Safety</h3>

                <!-- top level  -->
                <div *ngIf="!data.Safety.blank || data.Safety.notes != '' " style="padding: 0px;">
                    <p style="margin-left: 15px;">{{data.Safety.time}}</p>
                   
                    

                    <div class="selection" *ngIf="data.Safety.tick">
                        <img src="../../assets/icons/black-tick.png" >
                    </div>
        
                    <div class="selection" *ngIf="data.Safety.cross">
                        <img src="../../assets/icons/ATEMSelectCross.png" >
                    </div>

                    <p style="display: block;">{{data.Safety.notes}}</p>
                </div>

                <!-- end of top level -->
                
                    <div *ngFor="let item of data | keyvalue: sortNull">
                        <div *ngIf="item.value.header === 'safety'">
                            <div *ngIf="!item.value.blank || item.value.notes != '' " style="padding: 0px;">
                                <h3 class="sub-headings">{{item.value.name}}</h3>
                                <p style="margin-left: 15px;">{{item.value.time}}</p>
                                                                
                                <div class="selection" *ngIf="item.value.tick">
                                    <img src="../../assets/icons/black-tick.png" >
                                </div>
                    
                                <div class="selection" *ngIf="item.value.cross">
                                    <img src="../../assets/icons/ATEMSelectCross.png" >
                                </div>
                                <p style="display: block;">{{item.value.notes}}</p>
                            </div>
                        </div>
                    </div>
               
                <h3 class="psp">Assess</h3>

                <div *ngIf="!data.Assess.blank || data.Assess.notes != '' " style="padding: 0px;">
                    <p style="margin-left: 15px;">{{data.Assess.time}}</p>
                   
                    

                    <div class="selection" *ngIf="data.Assess.tick">
                        <img src="../../assets/icons/black-tick.png" >
                    </div>
        
                    <div class="selection" *ngIf="data.Assess.cross">
                        <img src="../../assets/icons/ATEMSelectCross.png" >
                    </div>

                    <p style="display: block;">{{data.Assess.notes}}</p>
                </div>

                <div *ngFor="let item of data | keyvalue : sortNull ">
                    <div *ngIf="item.value.header === 'assess'">
                        <div *ngIf="!item.value.blank || item.value.notes != '' " style="padding: 0px;">
                            <h3 class="sub-headings">{{item.value.name}}</h3>
                            <p style="margin-left: 15px;">{{item.value.time}}</p>
                                                            
                            <div class="selection" *ngIf="item.value.tick">
                                <img src="../../assets/icons/black-tick.png" >
                            </div>
                
                            <div class="selection" *ngIf="item.value.cross">
                                <img src="../../assets/icons/ATEMSelectCross.png" >
                            </div>
                            <p style="display: block;">{{item.value.notes}}</p>
                        </div>
                    </div>
                </div>

                <h3 class="psp">Comms</h3>

                <!-- top level  -->
                <div *ngIf="!data.Safety.blank || data.Comms.notes != '' " style="padding: 0px;">
                    <p style="margin-left: 15px;">{{data.Comms.time}}</p>
                   
                    

                    <div class="selection" *ngIf="data.Comms.tick">
                        <img src="../../assets/icons/black-tick.png" >
                    </div>
        
                    <div class="selection" *ngIf="data.Safety.cross">
                        <img src="../../assets/icons/ATEMSelectCross.png" >
                    </div>

                    <p style="display: block;">{{data.Comms.notes}}</p>
                </div>

                <!-- end of top level -->

                <div *ngFor="let item of data | keyvalue: sortNull">
                    <div *ngIf="item.value.header === 'comms'">
                        <div *ngIf="!item.value.blank || item.value.notes != '' " style="padding: 0px;">
                            <h3 class="sub-headings">{{item.value.name}}</h3>
                            <p style="margin-left: 15px;">{{item.value.time}}</p>
                                                            
                            <div class="selection" *ngIf="item.value.tick">
                                <img src="../../assets/icons/black-tick.png" >
                            </div>
                
                            <div class="selection" *ngIf="item.value.cross">
                                <img src="../../assets/icons/ATEMSelectCross.png" >
                            </div>
                            <p style="display: block;">{{item.value.notes}}</p>
                        </div>
                    </div>
                </div>

                <h3 class="psp">Triage</h3>

                <!-- top level  -->
                <div *ngIf="!data.Triage.blank || data.Triage.notes != '' " style="padding: 0px;">
                    <p style="margin-left: 15px;">{{data.Triage.time}}</p>
                   
                    

                    <div class="selection" *ngIf="data.Triage.tick">
                        <img src="../../assets/icons/black-tick.png" >
                    </div>
        
                    <div class="selection" *ngIf="data.Triage.cross">
                        <img src="../../assets/icons/ATEMSelectCross.png" >
                    </div>

                    <p style="display: block;">{{data.Triage.notes}}</p>
                </div>

                <!-- end of top level -->

                <div *ngFor="let item of data | keyvalue : sortNull ">
                    <div *ngIf="item.value.header === 'triage'">
                        <div *ngIf="!item.value.blank || item.value.notes != '' " style="padding: 0px;">
                            <h3 class="sub-headings">{{item.value.name}}</h3>
                            <p style="margin-left: 15px;">{{item.value.time}}</p>
                                                            
                            <div class="selection" *ngIf="item.value.tick">
                                <img src="../../assets/icons/black-tick.png" >
                            </div>
                
                            <div class="selection" *ngIf="item.value.cross">
                                <img src="../../assets/icons/ATEMSelectCross.png" >
                            </div>
                            <p style="display: block;">{{item.value.notes}}</p>
                        </div>
                    </div>
                </div>

        </div>

    <hr>

        <div class="Primary-Survey">
            <h2 class="primary-heading">Primary Survey</h2>

                <h3 class="primary">Xsanguination</h3>

                 <!-- top level  -->
                 <div *ngIf="!data.Xsanguination.blank || data.Xsanguination.notes != '' " style="padding: 0px;">
                    <p style="margin-left: 15px;">{{data.Xsanguination.time}}</p>
                   
                    

                    <div class="selection" *ngIf="data.Xsanguination.tick">
                        <img src="../../assets/icons/black-tick.png" >
                    </div>
        
                    <div class="selection" *ngIf="data.Xsanguination.cross">
                        <img src="../../assets/icons/ATEMSelectCross.png" >
                    </div>

                    <p style="display: block;">{{data.Xsanguination.notes}}</p>
                </div>

                <!-- end of top level -->

                <div *ngFor="let item of data | keyvalue : sortNull ">
                    <div *ngIf="item.value.header === 'xsanguination'">
                        <div *ngIf="!item.value.blank || item.value.notes != '' " style="padding: 0px;">
                            <h3 class="sub-headings">{{item.value.name}}</h3>
                            <p style="margin-left: 15px;">{{item.value.time}}</p>
                                                            
                            <div class="selection" *ngIf="item.value.tick">
                                <img src="../../assets/icons/black-tick.png" >
                            </div>
                
                            <div class="selection" *ngIf="item.value.cross">
                                <img src="../../assets/icons/ATEMSelectCross.png" >
                            </div>
                            <p style="display: block;">{{item.value.notes}}</p>
                        </div>
                    </div>
                </div>

                <h3 class="primary">Airway</h3>

                 <!-- top level  -->
                 <div *ngIf="!data.Airway.blank || data.Airway.notes != '' " style="padding: 0px;">
                    <p style="margin-left: 15px;">{{data.Airway.time}}</p>
                   
                    

                    <div class="selection" *ngIf="data.Airway.tick">
                        <img src="../../assets/icons/black-tick.png" >
                    </div>
        
                    <div class="selection" *ngIf="data.Airway.cross">
                        <img src="../../assets/icons/ATEMSelectCross.png" >
                    </div>

                    <p style="display: block;">{{data.Airway.notes}}</p>
                </div>

                <!-- end of top level -->

                <div *ngFor="let item of data | keyvalue : sortNull ">
                    <div *ngIf="item.value.header === 'airway'">
                        <div *ngIf="!item.value.blank || item.value.notes != '' " style="padding: 0px;">
                            <h3 class="sub-headings">{{item.value.name}}</h3>
                            <p style="margin-left: 15px;">{{item.value.time}}</p>
                                                            
                            <div class="selection" *ngIf="item.value.tick">
                                <img src="../../assets/icons/black-tick.png" >
                            </div>
                
                            <div class="selection" *ngIf="item.value.cross">
                                <img src="../../assets/icons/ATEMSelectCross.png" >
                            </div>
                            <p style="display: block;">{{item.value.notes}}</p>
                        </div>
                    </div>
                </div>

                
                <h3 class="primary">Breathing</h3>

                 <!-- top level  -->
                 <div *ngIf="!data.Breathing.blank || data.Breathing.notes != '' " style="padding: 0px;">
                    <p style="margin-left: 15px;">{{data.Breathing.time}}</p>
                   
                    

                    <div class="selection" *ngIf="data.Breathing.tick">
                        <img src="../../assets/icons/black-tick.png" >
                    </div>
        
                    <div class="selection" *ngIf="data.Breathing.cross">
                        <img src="../../assets/icons/ATEMSelectCross.png" >
                    </div>

                    <p style="display: block;">{{data.Breathing.notes}}</p>
                </div>

                <!-- end of top level -->

                <div *ngFor="let item of data | keyvalue : sortNull ">
                    <div *ngIf="item.value.header === 'breathing'">
                        <div *ngIf="!item.value.blank || item.value.notes != ''" style="padding: 0px;">
                            <h3 class="sub-headings">{{item.value.name}}</h3>
                            <p style="margin-left: 15px;">{{item.value.time}}</p>
                                                            
                            <div class="selection" *ngIf="item.value.tick">
                                <img src="../../assets/icons/black-tick.png" >
                            </div>
                
                            <div class="selection" *ngIf="item.value.cross">
                                <img src="../../assets/icons/ATEMSelectCross.png" >
                            </div>
                            <p style="display: block;">{{item.value.notes}}</p>
                        </div>
                    </div>
                </div>


                <h3 class="primary">Circulation</h3> 

                 <!-- top level  -->
                 <div *ngIf="!data.Circulation.blank || data.Circulation.notes != '' " style="padding: 0px;">
                    <p style="margin-left: 15px;">{{data.Circulation.time}}</p>
                   
                    

                    <div class="selection" *ngIf="data.Circulation.tick">
                        <img src="../../assets/icons/black-tick.png" >
                    </div>
        
                    <div class="selection" *ngIf="data.Circulation.cross">
                        <img src="../../assets/icons/ATEMSelectCross.png" >
                    </div>

                    <p style="display: block;">{{data.Circulation.notes}}</p>
                </div>

                <!-- end of top level -->

                <div *ngFor="let item of data | keyvalue : sortNull ">
                    <div *ngIf="item.value.header === 'circulation'">
                        <div *ngIf="!item.value.blank || item.value.notes != '' " style="padding: 0px;">
                            <h3 class="sub-headings">{{item.value.name}}</h3>
                            <p style="margin-left: 15px;">{{item.value.time}}</p>
                                                            
                            <div class="selection" *ngIf="item.value.tick">
                                <img src="../../assets/icons/black-tick.png" >
                            </div>
                
                            <div class="selection" *ngIf="item.value.cross">
                                <img src="../../assets/icons/ATEMSelectCross.png" >
                            </div>
                            <p style="display: block;">{{item.value.notes}}</p>
                        </div>
                    </div>
                </div>


                <h3 class="primary">Disability</h3>

                 <!-- top level  -->
                 <div *ngIf="!data.Disability.blank || data.Disability.notes != '' " style="padding: 0px;">
                    <p style="margin-left: 15px;">{{data.Disability.time}}</p>
                   
                    

                    <div class="selection" *ngIf="data.Disability.tick">
                        <img src="../../assets/icons/black-tick.png" >
                    </div>
        
                    <div class="selection" *ngIf="data.Disability.cross">
                        <img src="../../assets/icons/ATEMSelectCross.png" >
                    </div>

                    <p style="display: block;">{{data.Disability.notes}}</p>
                </div>

                <!-- end of top level -->

                <div *ngFor="let item of data | keyvalue : sortNull ">
                    <div *ngIf="item.value.header === 'disability'">
                        <div *ngIf="!item.value.blank || item.value.notes != '' " style="padding: 0px;">
                            <h3 class="sub-headings">{{item.value.name}}</h3>
                            <p style="margin-left: 15px;">{{item.value.time}}</p>
                                                            
                            <div class="selection" *ngIf="item.value.tick">
                                <img src="../../assets/icons/black-tick.png" >
                            </div>
                
                            <div class="selection" *ngIf="item.value.cross">
                                <img src="../../assets/icons/ATEMSelectCross.png" >
                            </div>
                            <p style="display: block;">{{item.value.notes}}</p>
                        </div>
                    </div>
                </div>


                <h3 class="primary">Exposure</h3>
                 <!-- top level  -->
                 <div *ngIf="!data.Exposure.blank || data.Exposure.notes != '' " style="padding: 0px;">
                    <p style="margin-left: 15px;">{{data.Exposure.time}}</p>
                   
                    

                    <div class="selection" *ngIf="data.Exposure.tick">
                        <img src="../../assets/icons/black-tick.png" >
                    </div>
        
                    <div class="selection" *ngIf="data.Exposure.cross">
                        <img src="../../assets/icons/ATEMSelectCross.png" >
                    </div>

                    <p style="display: block;">{{data.Exposure.notes}}</p>
                </div>

                <!-- end of top level -->

                <div *ngFor="let item of data | keyvalue : sortNull ">
                    <div *ngIf="item.value.header === 'exposure'">
                        <div *ngIf="!item.value.blank || item.value.notes != '' " style="padding: 0px;">
                            <h3 class="sub-headings">{{item.value.name}}</h3>
                            <p style="margin-left: 15px;">{{item.value.time}}</p>
                                                            
                            <div class="selection" *ngIf="item.value.tick">
                                <img src="../../assets/icons/black-tick.png" >
                            </div>
                
                            <div class="selection" *ngIf="item.value.cross">
                                <img src="../../assets/icons/ATEMSelectCross.png" >
                            </div>
                            <p style="display: block;">{{item.value.notes}}</p>
                        </div>
                    </div>
                </div>


                <h3 class="primary">Further Treatment</h3>

                 <!-- top level  -->
                 <div *ngIf="!data.FurtherTreatment.blank || data.FurtherTreatment.notes != ''" style="padding: 0px;">
                    <p style="margin-left: 15px;">{{data.FurtherTreatment.time}}</p>
                   
                    

                    <div class="selection" *ngIf="data.FurtherTreatment.tick">
                        <img src="../../assets/icons/black-tick.png" >
                    </div>
        
                    <div class="selection" *ngIf="data.FurtherTreatment.cross">
                        <img src="../../assets/icons/ATEMSelectCross.png" >
                    </div>

                    <p style="display: block;">{{data.FurtherTreatment.notes}}</p>
                </div>

                <!-- end of top level -->

                <div *ngFor="let item of data | keyvalue : sortNull ">
                    <div *ngIf="item.value.header === 'further'">
                        <div *ngIf="!item.value.blank || item.value.notes != '' " style="padding: 0px;">
                            <h3 class="sub-headings">{{item.value.name}}</h3>
                            <p style="margin-left: 15px;">{{item.value.time}}</p>
                                                            
                            <div class="selection" *ngIf="item.value.tick">
                                <img src="../../assets/icons/black-tick.png" >
                            </div>
                
                            <div class="selection" *ngIf="item.value.cross">
                                <img src="../../assets/icons/ATEMSelectCross.png" >
                            </div>
                            <p style="display: block;">{{item.value.notes}}</p>
                        </div>
                    </div>
                </div>


        </div>
    
   


<!-- <div *ngFor="let item of data | keyvalue ">
        
        <div class="item" *ngIf="!item.value.blank ">
            <h2>You've Completed</h2>

                <h3>{{item.key}}</h3>

                <h3>{{item.value.time}}</h3>

                <h3>{{item.value.notes}}</h3>

            <div *ngIf="item.value.tick">
                <img src="../../assets/icons/ATEMSelectOn.png" >
            </div>

            <div *ngIf="item.value.cross">
                <img src="../../assets/icons/ATEMSelectCross.png" >
            </div>
        </div>
    </div> -->
<!-- 
<div *ngFor="let item of data | keyvalue ">
    <div *ngIf="item.key === 'Safety'">
  
        <div class="item" *ngIf="!item.value.blank ">
            <p class="item-notes">{{item.value.notes}}</p>
            <h4 class="item-notes">{{item.value.time}}</h4>
            
                <div *ngIf="item.value.tick">
                    <img src="../../assets/icons/ATEMSelectOn.png" >
                </div>
                <div *ngIf="item.value.cross">
                    <img src="../../assets/icons/ATEMSelectCross.png" >
                </div>
        </div>
        
    </div>
    
    <div *ngIf="item.value.header === 'safety'">
        
        <h3 class="sub-headings">{{item.value.name}}</h3>
            <div class="item" *ngIf="!item.value.blank ">
            <p class="item-notes">{{item.value.notes}}</p>
            <h4 class="item-notes">{{item.value.time}}</h4>
            
                <div *ngIf="item.value.tick">
                    <img src="../../assets/icons/ATEMSelectOn.png" >
                </div>
                <div *ngIf="item.value.cross">
                    <img src="../../assets/icons/ATEMSelectCross.png" >
                </div>
            </div>
    </div>

</div> -->