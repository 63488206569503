import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fur-antibiotics',
  templateUrl: './fur-antibiotics.component.html',
  styleUrls: ['./fur-antibiotics.component.css']
})
export class FurAntibioticsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
