<div class="container">

    <app-lightgreen-box name="AirwayProtect">PROTECT</app-lightgreen-box>
   
    <app-lg-info>

        <h1>Advanced Airway</h1>

            <ul >

                <li>The gold standard for airway protection is a cuffed tube in the trachea</li>
                <li>An LMA is lower level of protection - particularly on a non-fasted patient</li>
                <li>Intubation provides proper protection but usually the patient is paralyzed necessitating positive pressure ventilation (Bad for Shock !)</li>
                <li>A Cric gives proper protection and maintains spontaneous breathing but requires surgical incision </li>
                
            </ul>
        
        <hr>
        <iframe src="https://player.vimeo.com/video/855689110?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="A - Protect"></iframe>

    </app-lg-info>

</div>