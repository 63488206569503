import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cat-hem-reassess',
  templateUrl: './cat-hem-reassess.component.html',
  styleUrls: ['./cat-hem-reassess.component.css']
})
export class CatHemReassessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
