import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-safety-scene',
  templateUrl: './safety-scene.component.html',
  styleUrls: ['./safety-scene.component.css']
})
export class SafetySceneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
