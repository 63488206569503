import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fur-secondary-survey',
  templateUrl: './fur-secondary-survey.component.html',
  styleUrls: ['./fur-secondary-survey.component.css']
})
export class FurSecondarySurveyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
