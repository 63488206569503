<div class="container">
    <app-lightblue-box name="AirwayReAssess">REASSESS</app-lightblue-box>

    <app-lb-info>

        <h2>Effectiveness</h2>
            <ul>
                <li>Intervention Success</li>
                    <ul>

                        <li>Know how to identify right - for all procedures</li>
                    
                    </ul>

                <li>Failed Procedure Plan</li>

                    <ul>
                        <li>Know how to identify wrong</li>
                            <ul>
                                <li>Optimise Ventilation and Oxygenation</li>
                            </ul>

                        <li>Escalate</li>

                            <ul>

                                <li>Postural Drainage with adjuncts </li>
                                
                                <li>Advanced airway:</li>
                                    <ul>
                                        <li>EGA</li>
                                        <li>Intubation </li>
                                        <li>Cric</li>
                                    </ul>

                            
                            </ul>

                        <li>Change</li>

                            <ul>

                                <li><span style="color: blue;">HOPE - T</span></li>

                                <li><span style="color: blue;">H</span>elp</li>

                                <li><span style="color: blue;">O</span>perator</li>

                                <li><span style="color: blue;">P</span>osition</li>

                                <li><span style="color: blue;">E</span>quipment</li>
                                
                                <li><span style="color: blue;">T</span>echnique</li>
                                 
                            </ul>

                    </ul>
            </ul>
  
    <hr>
    <h2>Secure</h2>
    <ul>
        <li>Intervention is secure:</li>
            <ul>
                 <li>Ensure Cric or ET Tube is very secure</li>
                 <li>Check after movement</li>
            </ul>
    </ul>

    <hr>

    <h2>Condition</h2>
    <ul>
        <li>Predicted clinical outcome:</li>
        <ul>
            <li>Recovery</li>
            <li>Increase in responsiveness</li>           
        </ul>
    </ul>

    <iframe src="https://player.vimeo.com/video/747997360?h=0dfa3f8d58" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

</app-lb-info>
</div>