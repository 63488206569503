<div class="container">
  <app-lightgreen-box name="AirwayTreatment">Treatment</app-lightgreen-box>

  <app-lg-info>

    <h1>Treatment Considerations </h1>

    <ul>
        <li>Treatment will depend on: </li>
            <ul>
                <li>Assessment</li>
                <li>Injury</li>
                <li>Casualty physiology</li>
            </ul>
    </ul>

    <H2>Risk Benefit Analysis</H2>
    <ul>
      <li>Risk of no treatment</li>
        <ul>
          <li>Speaking no intervention required </li>
          <li>Not Speaking intervention required  </li>
        </ul>
      <li>Risk of specific intervention</li>
        <ul>
          <li>Very LOW RISK from Airway positioning</li>
          <li>Advanced procedures carry much higher risk</li>
        </ul>
      <li>Ability to offset risks</li>
        <ul>
          <li>Experience / Equipment / Environment </li>
        </ul>
      <li>Get Help</li>
        <ul>
          <li>Higher level experience and skill </li>
          <li>Extra hands</li>
          <li>Telemedicine</li>
        </ul>
    </ul>

    <hr>

<h1>Treatment Options for A</h1>

    <ul>
      <li>High Risk Environment</li>
        <ul>
          <li>Move to safe area</li>
          <li>Roll face down</li>
        </ul>
      <li>Specific treatment sequence </li>
        <ul>
          <li>Inspect</li>
          <li>Clear</li>
          <li>Open</li>
          <li>Maintain</li>
          <li>Protect</li>
        </ul>
      <li>All of the above are BEST achieved with Airway Positioning</li>
        <ul>
          <Li>Sit up </Li>
          <li>Lateral Airway Position (LAP)</li>
        </ul>
      <li>Facial trauma or swelling may require rapid advanced airway management:</li>
        <ul>
          <li>Cric</li>
          <li>EGA</li>
          <li>Intubation</li>
        </ul>
    </ul>

    <hr>

    <iframe src="https://player.vimeo.com/video/749937046?h=d1f1738d39" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>



  </app-lg-info>
</div>
