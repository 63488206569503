import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../database.service';

@Component({
  selector: 'app-scenario',
  templateUrl: './scenario.component.html',
  styleUrls: ['./scenario.component.css']
})
export class ScenarioComponent implements OnInit {
  scenarioInfo;
  constructor(private databaseService:DatabaseService) { }

  ngOnInit(): void {
    this.scenarioInfo = this.databaseService.scenarioInfo
  }
updateScenario(casname,incident,medicname,time){
  this.scenarioInfo.CasualtyDetails = casname.value;
  this.scenarioInfo.Incident = incident.value;
  // this.scenarioInfo.Time = new Date().toLocaleTimeString();
  this.scenarioInfo.Time = time.value;
  this.scenarioInfo.MedicName = medicname.value;
  this.databaseService.scenarioInfo = this.scenarioInfo
}
refreshPage(){
  window.location.href=window.location.href
}


}
