<div class="container">
    <app-lightblue-box name="XsanguinationAssess">ASSESS</app-lightblue-box>

    <app-lb-info>
        
    <h1>Goal</h1>
        <ul>
            <li>Identify & Treat Compressible Xsanguinating Bleeding - Squirting & Flowing Blood or Amputation</li>
        </ul>
    <hr>

    <h1>Risk</h1>
        <ul><li>Disruption of large vessels – Rapid blood loss leading to irreversible Haemorrhagic Shock </li></ul>
    <hr>

    <h1>Consider</h1>
    <h2>Pathophysiology</h2>
    <ul> 
        <li style="color: red;">Injury severity</li>
         <li>Mechanism of Injury</li>
        <li>Injury pattern</li>
        <li>Associated injuries</li>
       
    </ul>

    <h2>Individual physiology</h2>
    <ul>
      <li>Underlying medical conditions</li>
      <li>Medications</li>
      <li>Injury environment </li>
    </ul>

    <hr>

        <h1>Exam</h1>
            <h2>Look</h2>
    <ul>
        <li>Rapid search of limbs and junctions for OBVIOUS COMPRESSIBLE XSANGUINATING BLEEDING or amputation or partial amputation</li>
    </ul>
    
    <h2>Turn</h2>
    <ul>
        <li>Check down-side</li>
    </ul>
    
    
    <h2>Cut</h2>
    <ul>
        <li>Dry-suits / Goretex</li>
        <li>Expose wound to evaluate</li>
    </ul>
   
    <hr>
    <h1>Measure</h1>
    <h2>Type of bleeding</h2>
    <ul>
        <li>Squirting / flowing / trickling</li>
    </ul>
    <h2>Amount of bleeding</h2>
    <ul>
        <li>Volume estimation</li>
    </ul>
    
    <hr>

    <iframe src="https://player.vimeo.com/video/740707070?h=099f125f67" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

</app-lb-info>
</div>

