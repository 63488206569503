import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breathing-assess',
  templateUrl: './breathing-assess.component.html',
  styleUrls: ['./breathing-assess.component.css']
})
export class BreathingAssessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
