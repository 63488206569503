import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circ-think',
  templateUrl: './circ-think.component.html',
  styleUrls: ['./circ-think.component.css']
})
export class CircThinkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
