<div  class="notes" (click)="openModal('custom-modal-1',input1)">
    <img  *ngIf = "!notesChecker()" src="../../../assets/icons/ATEMNoteOff.png">
    <img *ngIf="notesChecker()" src="../../../assets/icons/ATEMNoteOn.png">
    <!-- <img *ngIf="data.notes !== null && data.notes !== ''" src="../../../assets/icons/ATEMNoteOn.png">     -->
</div>

<jw-modal id="custom-modal-1">
 <textarea
 #input1
 [(ngModel)]="data.notes">
</textarea>
<div class="modal-buttons">
    <button (click)="closeModal('custom-modal-1', input1)">Close</button>
    <button #saveButton (click)="upDateNotes('custom-modal-1', input1)">Save</button>
</div>
</jw-modal>
