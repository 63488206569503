import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circ-whole-blood-transfusion',
  templateUrl: './circ-whole-blood-transfusion.component.html',
  styleUrls: ['./circ-whole-blood-transfusion.component.css']
})
export class CircWholeBloodTransfusionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
