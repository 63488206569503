import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
database = {
  // PrePrimary
  PrePrimary:{
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
  },
  // Safety
  Safety:{
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'safety-main',
  },

  SafetyTactics:{
    name:'Tactics',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'safety',
  },

  SafetySelf:{
    name:'Self',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'safety',
  },
  SafetyScene:{
    name:'Scene',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'safety',
  },
  SafetyCasualty:{
    name:'Casualty',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'safety',
  },
  // Assess
  Assess:{
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'assess-main',
  },

  AssessScene:{
    name:'Scene',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'assess',
  },
  // Comms
  Comms:{
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'comms-main',
  },
  CommsTeam:{
    name:'Team',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'comms',
  },
  CommsEvacuation:{
    name:'Evacuation',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'comms',
  },
  CommsCasualty:{
    name:'Casualty',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'comms',
  },
  // Triage
  Triage:{
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'triage-main',
  },
  TriageSearch:{
    name:'Search',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'triage',
  },
  TriageCasualty:{
    name:'>1Casualty',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'triage',
  },
  TriageEvac:{
    name:'Evacuation',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'triage',
  },
  // Primary
  Primary:{
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:''
  },
  // Xanguination
  Xsanguination:{
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
  },
  XsanguinationAssess:{
    name:'Assess',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'xsanguination',
  },
  XsanguinationIntervention:{
    name:'Intervention',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'xsanguination',
    presented: false,
  },
  XsanguinationReAssess:{
    name:'Reassess',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'xsanguination',
  },
  XsanguinationTreatment:{
    name:'Treatment',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'xsanguination',
  },
  XsanguinationProx:{
    name:'Proximal Control',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'xsanguination',
  },
  XsanguinationLimbTq:{
    name:'Limb TQ',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'xsanguination',
  },
  XsanguinationPacking:{
    name:'Packing',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'xsanguination',
  },
  XsanguinationITClamp:{
    name:'ITClamp',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'xsanguination',
  },
  XsanguinationJunctionTQ:{
    name:'Junctional TQ',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'xsanguination',
  },
  XsanguinationFoley:{
    name:'Foley',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'xsanguination',
  },
  XsanguinationAAJT:{
    name:'AAJT',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'xsanguination',
  },
  // Airway
  Airway:{
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:''
  },
  AirwayAssess:{
    name:'Assess',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'airway',
  },
  AirwayIntervention:{
    name:'Intervention',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'airway',
    presented: false,
  },
  AirwayTreatment:{
    name:'Treatment',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'airway',
  },
  AirwayPosturalDrainage:{
    name:'Postural Drainage',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'airway',
  },
  AirwayInspect:{
    name:'Inspect',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'airway',
  },
  AirwayOpen:{
    name:'Open',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'airway',
  },
  AirwayMaintain:{
    name:'Maintain',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'airway',
  },
  AirwayProtect:{
    name:'Protect',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'airway',
  },
  AirwaySurgicalCric:{
    name:'Surgical Cric',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'airway',
  },
  AirwayEGA:{
    name:'EGA',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'airway',
  },
  AirwayIntubation:{
    name:'Intubation',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'airway',
  },
  AirwayReAssess:{
    name:'Reassess',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'airway',
  },
  // Breathing
  Breathing:{
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
  },
  BreathingAssess:{
    name:'Assess',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'breathing',
  },
  BreathingIntervention:{
    name:'Intervention',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'breathing',
    presented: false,
  },
  BreathingTreatment:{
    name:'Treatment',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'breathing',
  },
  BreathingChestSeal:{
    name:'Chest Seal',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'breathing',
  },
  BreathingDecompress:{
    name:'Decompress',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'breathing',
  },
  BreathingChestDrain:{
    name:'Chest Drain',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'breathing',
  },
  BreathingVentilate:{
    name:'Ventilate',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'breathing',
  },
  BreathingReAssess:{
    name:'Reassess',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'breathing',
  },
  // Circulation
  Circulation:{
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationAssess:{
    name:'Assess',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationIntervention:{
    name:'Intervention',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
    presented: false,
  },
  CirculationTreatment:{
    name:'Treatment',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationStart:{
    name:'Start',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationStop:{
    name:'Stop',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationTractionFractures:{
    name:'Traction Splint',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationPelvicBinder:{
    name:'Pelvic Binder',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationMaintain:{
    name:'Maintain',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationIvAccess:{
    name:'IV Access',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationIoAccess:{
    name:'IO Access',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationReconPlasma:{
    name:'Reconstituting Plasma',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationWholeBloodTransfusion:{
    name:'Whole Blood Transfusion',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationAdminister:{
    name:'Administer',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationRetain:{
    name:'Retain',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationTitrate:{
    name:'Titrate',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationEmergencyDonor:{
    name:'Emergency Donor Panel',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationWholeBloodDonation:{
    name:'Whole Blood Donation',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationThink:{
    name:'Think',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },
  CirculationReAssess:{
    name:'Reassess',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'circulation',
  },

  // Disability
  Disability:{
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
  },
  DisabilityAssess:{
    name:'Assess',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilityIntervention:{
    name:'Intervention',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
    presented: false,
  },
  DisabilityTreatment:{
    name:'Treatment',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilityHyperventilation:{
    name:'Hyperventilation',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilityHypoxia:{
    name:'Hypoxia',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilityHypotension:{
    name:'Hypotension',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilityHighICP:{
    name:'High ICP',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilityHypoglycemia:{
    name:'Hypoglycemia',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilityTBI:{
    name:'Traumatic Brain Injury',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilityCbtCas:{
    name:'Combative Casualties',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilityIBA:{
    name:'Impact Brain Apnoea',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilityLoweredLOC:{
    name:'Lowered LOC',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilitySpinalInjury:{
    name:'Spinal Injury',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilitySeizures:{
    name:'Seizures',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilityMildTBI:{
    name:'Mild TBI',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  DisabilityReAssess:{
    name:'Reassess',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'disability',
  },
  
  // Exposure
  Exposure:{
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
  },
  ExposureAssess:{
    name:'Assess',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'exposure',
  },
  ExposureIntervention:{
    name:'Intervention',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'exposure',
    presented: false,
  },
  ExposureTreatment:{
    name:'Treatment',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'exposure',
  },
  ExposureHypothermia:{
    name:'Hypothermia',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'exposure',
  },
  ExposureHyperthermia:{
    name:'Hyperthermia',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'exposure',
  },
  ExposureAltitude:{
    name:'Altitude',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'exposure',
  },
  ExposureDiving:{
    name:'Diving',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'exposure',
  },
  ExposureBurns:{
    name:'Burns',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'exposure',
  },
  ExposureToxins:{
    name:'Toxins',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'exposure',
  },
  ExposureNerveAgent:{
    name:'Nerve Agent',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'exposure',
  },
  ExposureCyanide:{
    name:'Cyanide',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'exposure',
  },
  ExposureOpiates:{
    name:'Opiates',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'exposure',
  },
  ExposureReAssess:{
    name:'Reassess',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'exposure',
  },

  // FurtherTreatment
  FurtherTreatment:{
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
  },
  FurSecondarySurvey:{
    name:'Secondary Survey',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'further',
  },
  FurReAssess:{
    name:'Reassess',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'further',
  },
  FurPackage:{
    name:'Package',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'further',
  },
  FurHistory:{
    name:'History',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'further',
  },
  FurAnalgesia:{
    name:'Analgesia',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'further',
  },
  FurAntiBiotics:{
    name:'Antibiotics',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'further',
  },
  FurHandover:{
    name:'Handover',
    blank:true,
    tick:false,
    cross:false,
    time:'',
    notes:'',
    header: 'further',
  },

}

scenarioInfo={
  CasualtyDetails:'',
  Incident: '',
  Time: '',
  MedicName:''
}


}
