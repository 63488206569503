
<div class="container">
    <div *ngIf="blank" class="blank" (click)=onBlankClicked()>
        <img src="../../../assets/icons/ATEMSelectOff.png">
    </div>
    <div *ngIf="tick" class="tick" (click)=onTickClicked()>
        <img src="../../../assets/icons/black-tick.png">
    </div>
    <div *ngIf="cross" class="cross" (click)=onCrossClicked()>
        <img src="../../../assets/icons/ATEMSelectCross.png" >
    </div>
</div>