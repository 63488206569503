<!-- Greybox edit  -->
<div class="boxFlex" >
    <div class="greybox" >
        <div class="left">
            <app-notes [name]="name"></app-notes>
        </div>
        <div class="center" (click)="onClicked()">
            <p>{{heading}}</p>
        </div>
        <div class="right">
            <app-tick [name]="name"></app-tick>
        </div>
    </div>
</div>

<ng-content *ngIf="presented"></ng-content>  
