<div class="container">
    <app-lightblue-box name="CirculationAssess">ASSESS</app-lightblue-box>
<app-lb-info>
    
    <h1>Goal</h1>
    <ul><li>Identify and treat life-threatening Circulation System Injury and SHOCK / Repay Oxygen Debt </li></ul>
    <hr>

        <h1>Risk</h1>
        <ul><li>Shock:</li>
            <ul><li>Hypovolemic </li>
                <ul>
                <li>HAEMORRHAGIC</li> 
                <li>Non-Haemorrhagic</li>
                </ul>
               <li>Cardiogenic</li> 
                <li>Distributive</li>
                <li>Obstructive</li>
            </ul>
        </ul>
        <hr>

        <h1>Consider</h1>

        <h2>Pathophysiology</h2>
    
        <ul> 
            <li style="color: red;">Injury Severity</li>
            <li>Mechanism of Injury</li>
            <li>Injury Pattern</li>
            <li>Associated Injuries</li>
        </ul>
    
        <h2>Casualty</h2>
        
        <ul>
          <li>Specific physiology</li>
          <li>Underlying pathology</li>
          <li>Medications</li>
          <li>Injury environment </li>
        </ul>
        <hr>

    <h1>Exam</h1>

        <ul>
            <li>Search for and treat all compressible bleeds and identify possible non-compressible bleeds</li>
        </ul>    


        <h2>Reassess</h2>

            <ul>
                <li>Any intervention done in X & correct if necessary</li>
                
                <li>Consider downgrade of TQ </li>
                
            </ul>

        <h2>External Bleed Search</h2>

            <ul>

                <li>Head to Toe</li>

                <li>Gloved hand to skin</li>

                <li>In good light</li>


            </ul>
        
        <h2>Internal Bleed Search</h2>

            <ul>
                <li>For possible non-compressible bleeding in body cavities</li>
                <ul>
                    <li>Thorax</li>
                    <li>Abdomen</li>
                    <li>Pelvis</li>
                    <li>Femurs</li>
                </ul>
               
            </ul>

            <h2>E-FAST</h2>
            <ul><li>Right Upper Quadrant</li>
                <li>Left Upper Quadrant</li>
                <li>Subcostal</li>
                <li>Suprapubic</li>
                <li>Lungs</li>
            </ul>

    <hr>

    <h1>Measure</h1>
            <ul>
                <li>Best metrics for shock are:</li>
                <ul>
                    <li>Mentation - if no drugs or TBI</li>
                    <li>Vital Sign TRENDS OVER TIME</li>
                    <li>Look of the casualty (Experience)</li>
                </ul>
            </ul>

            <h2>Mentation</h2>

                <ul>
                    <li><span style="color: green;"> Alert</span></li>
                    <li><span style="color: orange;"> Voice responsive </span></li>
                    <li><span style="color: orange;"> Pain responsive </span></li>
                    <li><span style="color: red;"> Unresponsive </span></li>

                        <li>Alert but:</li>
                        <ul>
                            <li><span style="color: orange;">Restless </span></li>
                            <li><span style="color: orange;">Confused  </span></li>
                            <li><span style="color: orange;">Anxious  </span></li>
                            <li><span style="color: orange;">Combative  </span></li>
                        </ul>


                </ul>    

            <h2>Pulse rate</h2>
                
                <ul>
                    <li><span style="color: red;"> < 50 </span></li>
                    
                    <li><span style="color: green;"> 50 -100 </span></li>
                    <li><span style="color: orange;"> 100 - 120 </span></li>
                    <li><span style="color: red;"> > 120 </span></li>

               </ul>

                <h2><style></style> Pulse Location (Approx BP)</h2>
                    <ul>
                        <li><span style="color: red;"> Loss of radial: 70 - 90 mmHg </span></li>
                        <li><span style="color: red;"> Loss of femoral: 60 - 80 mmHg </span></li>
                        <li><span style="color: red;"> Loss of carotid: 50 - 70 mmHg </span></li>
                    </ul>
                   
                    <h2>SBP in mmHg</h2>
                    <ul>
                        <li><span style="color: green;"> > 100 </span></li>
                        <li><span style="color: orange;"> < 100 </span></li>
                        <li><span style="color: red;"> < 90 </span></li>
                    </ul>   
                    
                    <h2>Shock Index HR/SBP</h2>
                    <ul>
                        <li><span style="color: green;"> < 1 </span></li>
                        <li><span style="color: orange;"> 1 </span></li>
                        <li><span style="color: red;"> > 1.5 </span></li>
                    </ul>   
        

            <h2>Central Capillary Refill</h2>
                <ul>
                           
                    <li><span style="color: green;">< 2 sec </span></li>
                    <li><span style="color: orange;"> 2 - 4 sec</span></li>
                    <li><span style="color: red;"> > 4 sec</span></li>

                </ul>

            <h2>Skin colour</h2>
                <ul>
                    <li><span style="color: green;"> Normal </span></li>
                    <li><span style="color: orange;"> Pale / Cold / Clammy </span></li>
                    <li><span style="color: red;">  Pallor / Cynosis </span></li>
                </ul>

            <h2>Temperature</h2>
            <ul>
                <li><span style="color: green;"> 37 - 36C </span></li>
                <li><span style="color: orange;"> 36 - 34C </span></li>
                <li><span style="color: red;"> < 34C </span></li>
            </ul>   


            <h2>Lactate in mmol/l</h2>
            <ul>
                <li><span style="color: green;"> < 2 </span></li>
                <li><span style="color: orange;"> 2 - 4 </span></li>
                <li><span style="color: red;"> > 4 and not dropping </span></li>
            </ul>   

<hr><iframe src="https://player.vimeo.com/video/834716998?h=42c73b303c" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

</app-lb-info>

</div>

