import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../database.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private databaseService:DatabaseService) { }

  onClicked(){
    console.log(this.databaseService.database);
  }

  ngOnInit(): void {
  }

}
