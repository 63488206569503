import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cat-hem-treatment',
  templateUrl: './cat-hem-treatment.component.html',
  styleUrls: ['./cat-hem-treatment.component.css']
})
export class CatHemTreatmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
