import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DatabaseService } from 'src/app/database.service';
import { ModalService } from '../../_modal'

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {
  @Input() name:string;
  notes:boolean;
  data;

  constructor(
    private databaseService:DatabaseService,
    private modalService: ModalService, 
    ) {}
  
  ngOnInit(): void {
    this.data = this.databaseService.database;
       
    }
  
  openModal(id: string,input1) {
    this.data.name = this.name;
    this.data.notes = this.databaseService.database[this.data.name].notes;
    this.modalService.open(id);
  }
  
  closeModal(id: string, input1) {
   this.modalService.close(id);
  }

  upDateNotes(id: string, input1){
    this.data[this.data.name].notes=input1.value;
    this.databaseService.database[this.data.name].notes = this.data[this.data.name].notes;
    this.modalService.close(id);
  }

  notesChecker(){
    if(this.databaseService.database[this.name].notes == ''){
      return false;
    }else{ return true };
  };

}
