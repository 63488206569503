import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dis-mild-tbi',
  templateUrl: './dis-mild-tbi.component.html',
  styleUrls: ['./dis-mild-tbi.component.css']
})
export class DisMildTbiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
