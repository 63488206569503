
<app-white-box class="header" name="TriageEvac">Evac via CCP</app-white-box>

<app-white-box-info>

  <!-- Copy and paste as necessary  -->


      <h2>Casualty Clearing Point (CCP)</h2>

        <ul>
          <li>Attempt to colocate casualties at Point of Injury</li>
            <li>Move from Point of Injury to Evac area via CCP</li>
            <li>Treat in CCP while awaiting Evac</li>
        </ul>
       <ul>
         <li>Attempt to place CCP on route to Evac area</li>
         <li>Control entry and exit</li>
         <li>Arrange casualties in CCP to maximise access & resources</li>
         <li>Sort casualties into priority order for evacuation</li>
         <li>Provide treatment</li>
         <li>Do NOT delay evacuation</li>
       </ul>

</app-white-box-info>
