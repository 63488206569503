import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dis-high-icp',
  templateUrl: './dis-high-icp.component.html',
  styleUrls: ['./dis-high-icp.component.css']
})
export class DisHighICPComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
