export const environment = {
  production: false,
  stripeKey: 'pk_live_51JJjlqFTpscF2VfwUMhpfOYeCOX4zmURiW3OdpV681SAbamsOwRQBSwjvlAR15hB1AjQC9qJtOa3mlVdXvvK9Cfc00Y4UfMXBp',
  firebaseConfig: {
      apiKey: "AIzaSyCcLt7HP3B3B9xNTYeq7cwCsiti1pB4Mdw",
      authDomain: "atem-app-corrections-3fb9b.firebaseapp.com",
      projectId: "atem-app-corrections-3fb9b",
      storageBucket: "atem-app-corrections-3fb9b.appspot.com",
      messagingSenderId: "1061938054126",
      appId: "1:1061938054126:web:1f0d1beee293b11be044ff",
      measurementId: "G-6P0GX5HMEH"
      }
};
