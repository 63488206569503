import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dis-cbt-cas',
  templateUrl: './dis-cbt-cas.component.html',
  styleUrls: ['./dis-cbt-cas.component.css']
})
export class DisCbtCasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
