<div class="container">
    <app-lightgreen-box name="BreathingChestSeal">CHEST SEAL</app-lightgreen-box>
   
    <app-lg-info>

  
        <h1>Indications</h1>
    
        <ul>
            <li>Any open wound in the thorax that might communicate with the pleural space</li>
        </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit: PPE - Gloves & Eye pro / Gauze / Vented chest seal</li>
                <li>Cas: Position for inspection and application of chest seal </li>
            </ul>
        
    <h1>Procedure</h1>

        <h2>1 Clean</h2>
        
            <ul>
                <li>Clean and dry the area around the wound </li>
            </ul>

        <h2>2 Apply </h2>
            
            <ul>
                <li>Apply with the valve over the main wound </li>
                <li>Ensure there are no folds in the material</li>
            </ul>

    <h1>Post Procedure</h1>    
    
        <h2>Check</h2>
        
            <ul>
                <li>The Chest seal has adhered to the skin and is intact</li>
            </ul>
        
        <h2>Secure</h2>
            <ul>
                <li>Ensure the chest seal remains on the upside for inspection and prevention of the chest seal slipping off position</li>
            </ul>

        <hr>
    
    <h1 style="color: orange">Risk</h1>
        <ul>

            <li style="color: orange;">Chest seal unsticks </li>
                <ul><li>Reassess regularly especially after movement</li></ul>
            
            <li style="color: orange;">Vent blocking: The vent may block with gas trapping below the seal  </li>
                <ul><li> Reassess regularly especially after movement </li></ul>
        </ul>

        <ul>

            <li style="color: orange;">Developing Tension Pneumothorax </li>
                <ul><li>Lift one side of the chest seal burping the trapped gas</li></ul>
            
        </ul>

    <hr>

    <iframe src="https://player.vimeo.com/video/774209448?h=f9723330cf" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>


    </app-lg-info>

</div>
