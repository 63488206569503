<app-goal-risk>GOALS AND RISKS</app-goal-risk>

<app-goal-info>

  <h1>Goals</h1>

      <ul>
          <li>Search & Identify LIFE THREATENING Conditions</li>
          <li>TREAT as you find </li>
          <li>Identify time critical casualty</li>

      </ul>

  <hr>

<h1>Risks</h1>
      <ul>
        <li>Consider</li>
          <ul>
            <li>Mechanism of Injury</li>
            <li>Pathophysiology & known injury patterns</li>
            <li>Underlying pathology</li>
          </ul>
      </ul>

<hr>

<h1>Assess</h1>
      <ul>
        <li>Physical Exam & Measurement</li>
        <li>Diagnosis & Differential</li>
        <li>Get baseline vitals ASAP</li>
      </ul>

      <hr>

<h1>Intervention</h1>
      <ul>
        <li>Indications</li>
        <li>Contraindications</li>
        <li>Cautions</li>
        <li>Complications</li>
        <li>Risk v Benefit Analysis (R/B-A) of Procedure or Drug</li>
      </ul>

    <hr>

<h1>Reassess</h1>
      <ul>
        <li>Effectiveness of intervention & response to intervention by the casualty </li>
        <li>Consider differential diagnosis </li>
      </ul>

    <hr>

<h1>Notes</h1>
      <ul>
        <li>Important additional information and tips</li>
      </ul>

</app-goal-info>
