<div class="container">
  <app-lightblue-box name="ExposureAssess">ASSESS</app-lightblue-box>

  <app-lb-info>

    <h1>Goal</h1>
        <ul>
            <li>Identify & Treat Life-threatening Environmental Exposure</li>
        </ul>
        <hr>

    <h1>Risk</h1>
        <ul>
            <li>Temperature</li>
            <li>Altitude</li>
            <li>Diving  </li>
            <li>Burns</li>
            <li>Toxins</li>
        </ul>

        <hr>

        <h1>Consider</h1>

        <h2>Pathophysiology</h2>
    
        <ul> 
            <li style="color: red;">Injury Severity</li>
            <li>Mechanism of Injury</li>
            <li>Injury Pattern</li>
            <li>Associated Injuries</li>
        </ul>

        <h2>Casualty</h2>
        
        <ul>
          <li>Specific physiology</li>
          <li>Underlying pathology</li>
          <li>Medications</li>
          <li>Injury environment </li>
        </ul>

        <hr>

      <h1>Exam</h1>
  <ul>
      <li>During X to D examination - consider environment cas was exposed to </li>
  </ul>

  <hr>
  <h1>Measure</h1>

  <h2>Temp</h2>
  <ul>
      <li>Core Temperature (Oral / Tympanic / Internal)</li>
      <li>Hypothermia</li>
        <ul>
            <li>Mild: 34-36°C </li>
              <li>Moderate: 32-34°C</li>  
              <li>Severe: < 32°C </li>  
        </ul>
        <li>Hyperthermia</li>
            <ul>
                <li>Mild to Moderate: 38-40°C </li>
                <li>Severe: > 40°C</li>
            </ul>

  </ul>

  <h2>SPO2 @ Altitude</h2>
  <ul>
      <li>Normal SPO2 at Sea level in %</li>
            <ul>
                <li>100 - 95 Within Normal Range</li>
                <li>95 - 90 Moderate Hypoxia </li>
                <li> < 90 Severe Hypoxia </li>
            </ul>
      <li>Normal SPO2 at Altitude in %</li>
        <ul>
            <li>1000m - 97</li>
            <li>2000m - 96</li>
            <li>3000m - 94</li>
            <li>4000m - 93 </li>
            <li>5000m - 91</li>
            <li>6000m - 90</li>
            <li>7000m - 88</li>
        </ul>

  </ul>

    <h2>Diving Profile</h2>
    <ul>
        <li>Dive profile: Number / Duration / Depth  </li>
        <li>Gas used: Pure O2 / Air / Nitrox / Mixed Gas</li>
    </ul>

  <h2>%BSAB</h2>
  <ul>
      <li>% Body Surface Area Burnt (Full thickness) / Burns above 20% require resuscitation</li>
  </ul>
  <h2>Toxins</h2>
  <ul>
      <li>Use Chemical Agent Monitor (CAM) to detect agent </li>
  </ul>

  <iframe src="https://player.vimeo.com/video/894050599?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="E - Assess"></iframe>

</app-lb-info>
</div>

