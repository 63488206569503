<div class="container">
  <app-lightblue-box name="ExposureReAssess">REASSESS</app-lightblue-box>
<app-lb-info>
  <h1>Effectiveness</h1>
  <ul>
  <li>Check effectiveness for specific procedure (core temp) or drug</li>
  <li>Failed Procedure Plan:</li>
    </ul>

  <h2>Escalate</h2>
  <ul>
      <li>As needed</li>
  </ul>

  <h2>Change</h2>
  <ul>
      <li><span style="color: blue;">Hope-T</span>
          <ul>
              <p></p>
              <li><span style="color: blue;">H</span>elp</li>
              <p></p>
              <li><span style="color: blue;">O</span>perator</li>
              <p></p>
              <li><span style="color: blue;">P</span>osition</li>
              <p></p>
              <li><span style="color: blue;">E</span>quipment</li>
              <p></p>
              <li><span style="color: blue;">T</span>echnique</li>
          </ul>
      </li>
  </ul>

  <hr>
  <h1>Secure</h1>
  <ul>
      <li>Intervention is secure:</li>
      <p></p>
      <ul>
      <li>Casualty covered and warm</li>
      <p></p>
      <li>Check intervention after movement</li>
      <p></p>
        </ul>
  </ul>
  <hr>
  <h1>Condition</h1>
  <ul>
      <li>Prediced clinical outcome:</li>
      <p></p>
      <ul><li>Progressive hypothermia is ominous</li>
      <p></p>
    </ul>
      
  </ul>



</app-lb-info>
</div>
