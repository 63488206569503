import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circ-tract-fractures',
  templateUrl: './circ-tract-fractures.component.html',
  styleUrls: ['./circ-tract-fractures.component.css']
})
export class CircTractFracturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
