<div class="container">
  <app-lightgreen-box name="CirculationAdminister">TXA / CALCIUM </app-lightgreen-box>

  <app-lg-info>

    <hr>
    <h1>TXA</h1>
    <hr>
    <h1>Indications</h1>
    
    <ul>
        <li>Haemorrhagic Shock requiring resuscitation < 3hrs after injury</li>
    </ul>

<hr>

<h1>Pre-Procedure</h1>

    <h2>Prep</h2>
        <ul>
            <li>Kit: </li>
                <ul>
                    <li>PPE: Gloves & Eye pro</li>
                    <li>2 grams of TXA</li>
                    <li>Syringe & needle</li>
                </ul>
            <li>Cas: </li>
                <ul>
                    <li>IV or IO access</li>

                </ul>
        </ul>
    
<h1>Procedure</h1>

      <h2>1 Check </h2>
    <ul>
        <Li> Time of injury is < 3 hrs before administration of TXA </Li>
    </ul>

    <h2>2 Draw</h2>
    
        <ul>
            <li>Draw up 2 grams of TXA into syringe</li>
        </ul>

    <h2>3 Administer</h2>
        <ul>
            <li> Slow IV / IO push </li>
        </ul>

<h1>Post Procedure</h1>    

    <h2>Check</h2>
    
        <ul>
            <li>For drug reaction</li>
            <li>Documentation</li>
        </ul>

        <h2>Secure</h2>
    <ul>
      <li>Sharps </li>
    </ul>


        <hr>

<h1 style="color: orange">Risk</h1>
    <ul>

        <li style="color: orange;"> 3 hrs after injury mortality is increased </li>
            <ul><li>Only give within 3 hrs of injury</li></ul>
        
        <li style="color: orange;">Hypotension on rapid administration </li>
            <ul>
                <li>This is not usually a concern - give as a slow push </li>
            </ul>

    </ul>

  <hr>

  <iframe src="https://player.vimeo.com/video/857827439?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="C - Adminnister"></iframe>


<hr>
<h1>Calcium</h1>
<hr>       
<h1>Indications</h1>

<ul>
    <li>Hypocalcaemia from trauma, shock and transfusion of citrated blood products</li>
</ul>

<h1>Pre-Procedure</h1>

<h2>Prep</h2>
    <ul>
        <li>Kit: </li>
            <ul>
                <li>PPE: Gloves & eye pro </li>
                <li>1 gram of calcium per 4 units of blood</li>
                <ul>
                    <li>Calcium chloride</li>
                    <li>Calcium gluconate</li>
                </ul>
                <li>Syringe / Needle / Flush</li>
            </ul>
        <li>Cas: </li>
            <ul>
                <li>Proven IV / IO Access </li>
            </ul>
    </ul>

<h1>Procedure</h1>

  <h2>1 Check </h2>
<ul>
    <Li> Good IV / IO flow with flush  </Li>
</ul>

<h2>2 Draw</h2>

    <ul>
        <li>Draw up 1 gram of calcium into syringe </li>
    </ul>

<h2>3 Administer</h2>
    <ul>
        <li> Give 1 gram of calcium via slow IV / IO push over 5 - 10min  </li>
        <li>With 1st unit of blood and every 4th unit</li>
        <li>DO NOT GIVE IN THE SAME LINE AS BLOOD</li>
    </ul>

<h1>Post Procedure</h1>    

<h2>Check</h2>

    <ul>
        <li>Measure serum calcium ASAP</li>
        <li>Document drug administration</li>
    </ul>

<h2>Secure</h2>
    <ul>
      <li>Sharps </li>
    </ul>

<hr>

<h1 style="color: orange">Risk</h1>
<ul>

    <li style="color: orange;"> Clot formation if given in same line as blood </li>
        <ul><li>DO NOT GIVE CALCIUM IN THE SAME LINE AS BLOOD</li></ul>
    
    <li style="color: orange;"> Arrhythmias with rapid infusion   </li>
        <ul>
            <li> Give slowly over 5 to 10 min  </li>
        </ul>

        <li style="color: orange;"> Tissue necrosis   </li>
        <ul>
            <li> Ensure good flow in IV / IO line prior to administration  </li>
        </ul>

</ul>
<hr>

<iframe src="https://player.vimeo.com/video/857827439?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="C - Adminnister"></iframe>

  </app-lg-info>

</div>
