<div class="container">
  <app-lightblue-box name="FurHistory">HISTORY</app-lightblue-box>
<app-lb-info>

  <h2>Take an AMPLE History</h2>
  <ul>
      <li>Allergies</li>
      <li>Medications</li>
      <li>Past Medical History</li>
      <li>Last: Meal / Toilet / Mensturation </li>
      <li>Event: What happened? - Chief complaint</li>
  </ul>



</app-lb-info>
</div>
