import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FirebaseService } from '../firebase.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  currentUser: string;

  constructor(
    public afAuth: AngularFireAuth,
    public firebaseService: FirebaseService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser = this.firebaseService.userEmail;
  }

  logOut() {
    this.afAuth.signOut()
      .then(() => {
        this.firebaseService.userEmail = '';
        this.currentUser = '';
        this.router.navigateByUrl('/')
      }
      )
  }

  alertManage() {
    alert('Please send an email to info@atem.org.uk to request updates to your account.')
  }

  // this.router.navigateByUrl('/')
}
