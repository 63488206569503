import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-triage-casualty',
  templateUrl: './triage-casualty.component.html',
  styleUrls: ['./triage-casualty.component.css']
})
export class TriageCasualtyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
