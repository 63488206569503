<div class="container">
    <app-lightgreen-box name="AirwayMaintain">MAINTAIN</app-lightgreen-box>
   
    <app-lg-info>

        <h1>Indications</h1>
            <ul>
                <li>To prevent airway obstruction from tongue </li>
                <li>Improve air movement </li>
                <li>To replace airway maintenance using manual manoeuvres</li>
            </ul>
        <hr>

    <h1>Pre-Procedure</h1>

    <h2>Prep</h2>
    <ul>
        <li>Kit: Gloves & Eye pro / Stethoscope / Laryngoscope / Water-based Lubrication
            </li>
                <ul>
                    <li> Measured NPA: 6mm for most females, 7mm for most males, 8mm for large males</li>
                    <li>Measured OPA: from the midline of the teeth to the angle of the jaw</li>
                </ul>

        <li>Cas: Place the casualty in the position that optimizes air movement using Casualty Choice, LAP and LIP. Make sure mouth or nose is inspected & cleared of any obstruction </li>
           
    </ul>

    <hr>

        <h1>Procedure</h1>
        
            <h1>NPA</h1>

            <h2>1 Inspect</h2>
                <ul>
                    <li>The nostrils check for deformity / Size & best air movement</li>
                </ul>
            <h2>2 Lube</h2>
                <ul>
                    <li>Apply lube to NPA</li>
                </ul>
            <h2>3 Insert</h2>
                <ul>
                    <li>Gentle insertion perpendicular to face</li>
                </ul>
            <H2>4 Do NOT Force</H2>
                <ul>
                    <li>Gentle rotation may help</li>
                </ul>
            <h2>5 Re-inspect</h2>
                <ul>
                    <li>For bleeding</li>
                </ul>
            <h2>6 Airway positioning</h2>

                <ul>
                    <li>May still be required</li>
                </ul>

            <h1>OPA</h1>

            <h2>1 Consider</h2>
                <ul>
                    <li>Placing the OPA with laryngoscope</li>
                </ul>

            <h2>2 Open</h2>
                <ul>
                    <li>The mouth after clearing </li>
                </ul>

            <h2>3 Insert</h2>
                <ul>
                    <li>Insert inverted then twist 180 degrees into position</li>
                </ul>

            <h2>4 Assist</h2>
                <ul>
                    <li>Placement with a Jaw Thrust</li>
                </ul>

            <h2>5 Airway positioning</h2>
                <ul>
                    <li>May still be required</li>
                </ul>


           <h1>Post-procedure</h1>

           <ul>
               <li>Check</li>
                <ul>
                    <li>For air movement & bleeding from NPA and Gagging from OPA</li>
                </ul>
                <li>Secure</li>
                    <ul>
                        <li>These devices may dislodge when combining with airway positioning, positioning takes preference</li>
                    </ul>
           </ul>

        <hr>

        <h1 style="color: orange">Risk</h1>

        <h2>NPA</h2>
                <ul>
                    <li style="color: orange;">Damage to vessels causing bleeding</li>
                    <ul>
                        <li> Avoid aggressive insertion</li>
                    </ul>
                    <li style="color: orange;">Blockage of the tube </li>
                        <ul>
                            <li>Regular reassessment</li>
                        </ul>
                </ul>

        <h2>OPA</h2>
                <ul>
                    <li style="color: orange;">Gagging or vomiting </li>
                    <ul>
                        <li>Remove if this happens and clear</li>
                    </ul>
                </ul>
       <hr>
       <iframe src="https://player.vimeo.com/video/727770315?h=53ce27bcbb" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </app-lg-info>

</div>