<div class="container">
    <app-lightblue-box name="BreathingAssess">ASSESS</app-lightblue-box>
<app-lb-info>
    
    <h1>Goal</h1>
    <ul><li>Identify & Treat Life-threatening Thoracic Injury / Breathing Difficulty / Respiratory System Compromise</li></ul>
    <hr>

        <h1>Risk</h1>
        <ul><li>MOI-Consider life-threatening chest / respiratory injury: BLATOMFC</li>
            <ul><li>Blast Lung</li>
               <li>Airway obstruction</li> 
                <li>Tension Pneumothorax (TPX)</li>
                <li>Open PX</li>
                <li>Massive Haemothorax</li>
                <li>Flail Chest</li>
                <li>Cardiac tamponade</li>
            </ul>
        </ul>
        <hr>

        <h1>Consider</h1>

        <h2>Pathophysiology</h2>
    
        <ul> 
            <li style="color: red;">Injury Severity</li>
            <li>Mechanism of Injury</li>
            <li>Injury Pattern</li>
            <li>Associated injuries</li>
        </ul>
    
        <h2>Casualty</h2>
        
        <ul>
          <li>Specific physiology</li>
          <li>Underlying pathology</li>
          <li>Medications</li>
          <li>Injury environment </li>
        </ul>
        <hr>

    <h1>Exam</h1>

        <h2>Talk </h2>
        <ul>
            <li>Talk to the casualty looking for any difficulty breathing & asking about history / pain / shortness of breath  </li>
        </ul>

        <h2>Look</h2>

            <ul>
                <li>Expose & Examine the entire thorax to umbilicus / root of neck and arms / in good light</li>

                <li>Front / Back / Sides</li>
                
                <li>Clean off blood</li>
                
                <li>Rake the skin</li>
                
                <li>Equality of chest movement</li>
                
            </ul>

        <h2>Feel</h2>

            <ul>

                <li>Palpate the bones of the thoracic cage</li>

                <li>Feel for:</li>

                <ul>

                    <li>Crepitus</li>

                    <li>Emphysema</li>

                </ul>
            </ul>
        
        <h2>Listen</h2>

            <ul>
                <li>For bilateral air entry on auscultation</li>
               
                <li>Resonance on percussion</li>
                    <ul>
                        <li>Hyper-Resonance - Air</li>
                        <li>Hypo-Resonance - Fluid (Blood)</li>
                    </ul>     
            </ul>

         <h2>No Breathing ?</h2> 
         <ul>
             <li>With the airway open - move to Traumatic Cardiac Arrest Drill</li>
         </ul>

    <hr>

    <h1>Measure</h1>

            <h2>Rate</h2>

                <ul>
                    <li ><span style="color: red;">< 10 </span> </li>

                    <li ><span style="color: green;"> 10 - 20 </span></li>   

                    <li ><span style="color: orange;"> 20 - 30 </span></li>

                    <li ><span style="color: red;"> > 30 </span></li>
                </ul>    

            <h2>Difficulty</h2>
                
                <ul>

                    <li>Get casualty to count for one breath</li>
                    
                    <li><span style="color: red;"> < 10 </span></li>
                    
                    <li><span style="color: green;"> > 10 </span></li>
                    
                    <li>Depth & Noise</li>
                   
                    <li>Accessory muscle use & Recession</li>
                   
                    <li> Position</li>
                </ul>

            <h2>Skin colour</h2>
                
                <ul>

                    <li>Cyanosis</li>

                </ul>

            <h2>SPO2</h2>

                <ul>
                    <li><span style="color: green;"> > 95 </span></li>
                    <li><span style="color: orange;"> 95 - 90 </span></li>
                    <li><span style="color: red;">  < 90 </span></li>
                </ul>
     <hr>
     
     <iframe src="https://player.vimeo.com/video/808285274?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="B - Assess"></iframe>
</app-lb-info>

</div>

