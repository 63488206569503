<div class="container">
    <app-lightblue-box name="DisabilityReAssess">REASSESS</app-lightblue-box>
<app-lb-info>
    <h1>Effectiveness</h1>
    <h2>Intervention Success</h2>
    <ul>
        <li>The 5 H Bombs avoided</li>
    </ul>

    <h2>Failed Procedure Plan</h2>
    <ul>
        <li>Optimise Oxygenation and Perfusion</li>
    </ul>

    <h2>Escalate</h2>
    <ul>
        <li>Consider Ketamine to treat combative TBI</li>
    </ul>

    <h2>Change</h2>
    <ul>
        <li><span style="color: blue;">Hope-T</span>
            <ul>
                <p></p>
                <li><span style="color: blue;">H</span>elp</li>
                <p></p>
                <li><span style="color: blue;">O</span>perator</li>
                <p></p>
                <li><span style="color: blue;">P</span>osition</li>
                <p></p>
                <li><span style="color: blue;">E</span>quipment</li>
                <p></p>
                <li><span style="color: blue;">T</span>echnique</li>
            </ul>
        </li>
    </ul>

    <hr>
    <h1>Secure</h1>
    <ul>
        <li>Interventions are secure</li>
        <p></p>
        <p></p>
        <li>Check after movement</li>
        <p></p>
    </ul>
    <hr>
    <h1>Condition</h1>
    <ul>
        <li>Predicted clinical outcome</li>
        <p></p>
        <li>Avoid secondary brain injury</li>
        <p></p>
    </ul>

    <hr>

    <iframe src="https://player.vimeo.com/video/873271019?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="D - Reassess"></iframe>

</app-lb-info>
</div>
