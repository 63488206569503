<div class="container">

    <app-lightgreen-box name="AirwayPosturalDrainage">AIRWAY POSITION</app-lightgreen-box>
   
    <app-lg-info>


        <h1>Indications</h1>
        <ul>
            <li>Any severely injured casualty</li>
            <li>Lowered responsiveness</li>
            <li>Facial trauma</li>
            <li>Airway obstruction like vomiting or bleeding</li>
            <li>Airway burns</li>
        </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit: Gloves & Stethoscope</li>
                <li>Cas: Note position / Check air movement</li>
            </ul>

    <h1>Procedures</h1>

        <h2>1 Leave</h2>
        
            <ul>
                <li>A conscious casualty will often maintain best position for open airway</li>
                <li>Leave casualty in postion if effective air movement </li>
                <li>DO NOT force casualty onto their back</li>

            </ul>

        <h2>2 Sit up</h2>
            
            <ul>
                <li>Sitting up and leaning forward may help the conscious casualty  </li>
            </ul>

        <h2>3 Lateral Airway Position (LAP)</h2>
            <ul>
                <li>LAP provides drainage and airway patency </li>
                <li>Angle face towards ground for drainage</li>
            </ul>

        <h2>4 Lateral Inline-Airway Position</h2> (LIP)
            <ul>
                <li>Maintaining spinal alignment for suspected C-spine injury by logrolling into the lateral position with face angled towards ground</li>
            </ul>

        <h1>Post Procedure</h1>

        <h2>Check</h2>
            
            <ul>
                <li>Air movement is maintained especially after movement </li>
            </ul>

        <h2>Secure</h2>
        <ul>
            <li>Secure casualty in airway position – especially on stretcher</li>
            <li>Strap in to maintain position</li>
        </ul>

            <hr>




        <h1 style="color: orange">Risk</h1>

            <ul style="color: green;">

                <li>IS LOW: PROCEDURE OF CHOICE FOR AIRWAY MANAGEMENT</li>
            </ul>
            
            <ul>
                <li style="color: orange;">Casualty may move out of airway position</li>
                    <ul>
                        <li>Reassess regularly especially after movement </li>
                    </ul>
                <li style="color: orange;">Airway may become obstructed from a change in cas condition</li>
                    <ul>
                        <li>Reassess regularly </li>
                    </ul>

            
            </ul>
        
    <hr>

            <iframe src="https://player.vimeo.com/video/693017645?h=3fbf68b49a" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </app-lg-info>

</div>
