<div class="container">
    <app-lightblue-box name="CirculationReAssess">REASSESS</app-lightblue-box>
    
    <app-lb-info>
        
        <h2>Effectiveness</h2>

            <ul>
                
                <li>Intervention Success</li>

                    <ul>

                        <li>Know how to identify right - for all procedures</li>
                    
                    </ul>

                <li>Failed Procedure Plan</li>

                    <ul>
                        <li>Know how to identify wrong</li>

                        <li>Escalate</li>

                            <ul>

                                <li>DCS for Non-Compressible Torso Haemorrhage</li>
                                
                                <li>Resuscitate to deliver a viable casualty</li>
                            
                            </ul>

                        <li>Change</li>

                            <ul>

                                <li><span style="color: blue;">HOPE - T</span></li>

                                <li><span style="color: blue;">H</span>elp</li>

                                <li><span style="color: blue;">O</span>perator</li>

                                <li><span style="color: blue;">P</span>osition</li>

                                <li><span style="color: blue;">E</span>quipment</li>
                                
                                <li><span style="color: blue;">T</span>echnique</li>
                                 
                            </ul>

                    </ul>
            </ul>

        <h2>Secure</h2>

            <ul>

                <li>Intervention is secure</li>

                <li>Check before & after movement</li>
            
            </ul>

        <h2>Condition</h2>

            <ul>

                <li>Predicted clinical outcome</li>

                <li>Improvement of vital sign trends over time</li>
                
                <li>Lactate clearance</li>
            </ul>
            
            <hr>

            <iframe src="https://player.vimeo.com/video/864777787?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="C - Reassess"></iframe>

    </app-lb-info>

</div>


