
<div class="container">
    <app-lightgreen-box name="DisabilityTreatment">D Treatment</app-lightgreen-box>

    <app-lg-info>
        <h1>Treatment Considerations </h1>

        <ul>
            <li>Treatment will depend on: </li>
                <ul>
                    <li>Assessment</li>
                    <li>Pathophysiology</li>
                    <li>Casualty physiology</li>
                    <li>Risk Benefit Analysis</li>
                </ul>
        </ul>

        <H2>Risk Benefit Analysis</H2>
        <ul>
          <li>Risk of no treatment</li>
          <li>Risk of specific intervention</li>
          <li>Ability to offset risks</li>
          <li>Experience</li>
          <li>Help</li>
        </ul>

        <hr>

<h1>Treatment Options for D</h1>

<ul>
    <li>Sedation of combative casualty</li>
    <li>Prevent the 5 H Bombs in Moderate to Severe TBI</li>
    <li>Ventilate Impact Brain Apnoea  </li>
    <li>Find and treat causes of Lowered Level of Consciousness</li>
    <li>Spinal Cord Injury</li>
    <li>Seizures</li>
    <Li>Hypoglycaemia</Li>
    <li>Mild TBI</li>
</ul>

<hr>

<iframe src="https://player.vimeo.com/video/871774734?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="D - Treatment"></iframe>

<hr>

    </app-lg-info>
</div>