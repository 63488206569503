<div class="container">
    <app-lightgreen-box name="AirwayEGA">EGA / LMA</app-lightgreen-box>
   
    <app-lg-info>

        <h1>Indications</h1>

        <ul>

            <li>Elective procedure on a fasted patient</li>

            <li>Cardiac arrest</li>
   
            <li>Failed intubation rescue airway</li>
     
        </ul>

        <hr>

        <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
        <ul>
            <li>Kit: Gloves & Eye pro / Stethoscope / Laryngoscope / Water-based Lubrication / Measured iGel
                </li>
                    <ul>
                        <li> 50-90kg Size 4 (Green)</li>
                        <li>90kg+ Size 5 (Orange)</li>
                    </ul>
    
            <li>Cas: Place the casualty in the position that optimizes air movement using Casualty choice, LAP and LIP. Make sure mouth or nose is inspected & cleared of any obstruction </li>
               
        </ul>
    
            <h1>Procedure</h1>
    
                <h2>1 Lube</h2>
                    <ul>
                        <li>The back, sides & front</li>
                    </ul>
                <h2>2 Grasp</h2>
                    <ul>
                        <li>Hold the lubed iGel by the bite block</li>
                        <li>The lumen must be facing the chin</li>
                    </ul>
                <h2>3 Insert</h2>
                    <ul>
                        <li>Open the mouth by pressing the chin down</li>
                        <li>Advance the tip in the direction of the hard pallet</li>
                        <li>Glide the device backwards & downwards along the hard pallet</li>
                        <li>Continue until a definite resistance is felt</li>
                        <li>The front teeth should be at the level of the bite block</li>
                        <li>A jaw thrust may assist with placing the device</li>
                    </ul>
    
               <h1>Post-procedure</h1>
    
               <ul>
                   <li>Check</li>
                    <ul>
                        <li>For air movement & bleeding from iGel and Gagging</li>
                    </ul>
                    <li>Secure</li>
                        <ul>
                            <li>Tape or strap the device in place</li>
                        </ul>
               </ul>
    

        <hr>

        <h1 style="color: orange">Risk</h1>

                <ul>
                    <li style="color: orange;">Gagging and Vomiting</li>
                    <ul>
                        <li>Patient must have no gag reflex</li>
                    </ul>
                    <li style="color: orange;">Aspiration</li>
                        <ul>
                            <li>This is not a definitive airway and aspiration is still possible</li>
                            <li>Consider Cric or Intubation</li>
                        </ul>
                </ul>

        <hr>

        <iframe src="https://player.vimeo.com/video/730271260?h=864b826468" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </app-lg-info>

</div>