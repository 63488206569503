<div class="container">
    <app-lightgreen-box name="CirculationWholeBloodTransfusion">Whole Blood Transfusion</app-lightgreen-box>
  
    <app-lg-info>
        
        <h1>Indications</h1>
    
        <ul>
            <li>Haemorrhagic Shock</li>
        </ul>

    <h2>Contraindications</h2>
        <ul>
            <li>ONLY GIVE COMPATIBLE BLOOD (LTOWB OR TYPE SPECIFIC)</li>

        </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit: </li>
                    <ul>
                        <li>PPE: Gloves & eye pro</li>
                        <li>Unit of LTOWB or Type Specific Whole Blood</li>
                        <li>Cold stored at 2-6 Centigrade with unbroken cold chain OR</li>
                        <li>Warm fresh whole blood from Walking Blood Bank or Emergency Donor Panel</li>
                        <li>1 gram of calcium with first unit of blood</li>
                    </ul>
                <li>Cas: </li>
                    <ul>
                        <li>Consent from casualty for transfusion</li>
                        <li>Ensure base line vitals and transfusion triggers</li>
                        <li>If you do not know the casualty's blood type only LTOWB may be used as a universal product</li>
                        <li>If using type specific whole blood - you MUST know the casualty's blood type and only give the same blood type e.g. A to A </li>
                        <li>If any doubt exists type the casualty with an Eldon Card</li>

                    </ul>
            </ul>
        
    <h1>Procedure</h1>

        <h2>1 Check</h2>
        
            <ul>
                <li>The unit of WB is compatible for the casualty</li>
                <li>Unit is in date</li>
                <li>Cold chain maintained</li>
                <li>Integrity of bag</li>
            </ul>

        <h2>2 Spike </h2>
            
            <ul>
                <li>Spike the bag with a blood transfusion administration set </li>
                <li>Run the blood through the line</li>
            </ul>

        <h2>3 Warm</h2>
            
            <ul>
                <li>If using warm fresh whole blood: Proceed</li>
                <li>If using cold stored whole blood: Warm the blood </li>
                    <ul>
                        <li>MEQU Fluid Warmer</li>
                        <li>Open and remove warming unit</li>
                        <li>Connect a blood transfusion admin set to the short tube with blue cap</li>
                        <li>Prime the warmer with blood or fluid to red end</li>
                        <li>Stick the warmer unit to patient</li>
                        <li>Connect the red end to patient cannula</li>
                        <li>Connect the plug wait for continuous green light </li>
                    </ul>
                
            </ul>

        <h2>4 Transfuse unit</h2>
            <ul>
                <Li>Open line and start transfusion </Li>
                <li>Monitor patient</li>
                <li>Redo vitals at least once during transfusion and after</li>
                    <ul>
                        <li>Strong response - Hold further transfusion</li>
                        <li>Transient response - Transfuse to 100 mmHg SBP</li>
                        <li>Little response - Multiple units may be required - consider resources</li>
                    </ul>
            </ul>

        <h2>5 Record</h2>
            <ul>
                <li>Record the transfusion  </li>
                <li>Send the empty bag with the cas</li>
            </ul>

    <h1>Post Procedure</h1>    
    
        <h2>Check</h2>
        
            <ul>
                <li>For transfusion reaction</li>
                <ul>
                    <li>Difficult to detect in shocked casualty</li>
                    <li>Sudden deterioration of condition or collapse</li>
                    <Li>Anaphylaxis: Swelling / rash / difficulty breathing</Li>
                </ul>
            </ul>
        
        <h2>Secure</h2>
            <ul>
                <li>All lines before movement - recheck after movement</li>
            </ul>

            <hr>
    
    <h1 style="color: orange">Risk</h1>
        <ul>

            <li style="color: orange;">Massive Hemolytic Transfusion Reaction</li>
            <ul>
                <li>Only use compatible blood</li>
                <li>If suspected stop transfusion and switch to new unit</li>
            </ul>

            <li style="color: orange;">Anaphylaxis</li>

            <ul>
                <li>Stop transfusion</li>
                <li>Give 500 micrograms of adrenalin - Deep IM injection</li>

            </ul>

            <li style="color: orange;">Transfusion Transmitted Disease</li>

            <ul>
                <li>Use blood from Blood Bank or test blood to Blood Bank Standards</li>
                <li>Use known tested donors</li>
                <li>Attempt to test unknown donors</li>

            </ul>

            <li style="color: orange;">TRALI</li>
            <ul>
                <li>Only take blood from males or never pregnant females</li>
            </ul>
        
        <li style="color: orange;">TACO</li>

            <ul>
                <li>Aim for 100mmHg Systolic </li>
                <li>Slow transfusion once above 100mmHg</li>

            </ul>

            <li style="color: orange;">Infection</li>

            <ul>
                <li>Practise aseptic technique</li>
                <li>Maintain cold chain</li>

            </ul>

            <li style="color: orange;">Hypocalcaemia from using citrated blood products</li>

            <ul>
                <li>Give 1 gram of calcium with first unit and then with 4th unit</li>
                <li>Measure serum calcium ASAP</li>
            </ul>

        </ul>
    <hr>

    </app-lg-info>

  </div>
  