
<div class="container">
    <app-lightgreen-box name="DisabilitySeizures">Seizures</app-lightgreen-box>
   
    <app-lg-info>

   <h2>Indications </h2>
        <ul>
            <li>Seizures post TBI resulting in hypoxia </li>
        </ul>

   <h2>Procedure</h2>
        <ul>
            <li>Control seizures with:</li>
                <ul>
                 <li>Midazolam 5mg IV/IO/IM every 5 minutes until seizures stop</li>   
                 <li>OR</li>
                 <li>Diazepam 5mg IV/IO/IM every 5 minutes until seizures stop</li>  
                </ul>
            <li>Consider a prophylactic anti-epilepsy drug  (e.g. Levetiracetam)</li>
        </ul>

   <h2 style="color: orange;">Risk</h2>
   <ul style="color: orange;">
       <li>Benzodiazepines may affect:</li>
       <ul>
           <li>Cardiovascular system </li>
            <li>Level of consciousness</li>
            <li>Loss of ability to perform a neurological assessment </li>
       </ul>
   </ul>

   <iframe src="https://player.vimeo.com/video/873295003?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="D - Seizures"></iframe>


    </app-lg-info>

</div>