<div class="container">
  <app-lightgreen-box name="CirculationTitrate">Titrate Additional Blood Products</app-lightgreen-box>

  <app-lg-info>


      <h1>Blood Product Hierarchy</h1>

          <ul>
            <li>Whole Blood</li>
                <ul>
                    <li>Low Titre - Group "O" - Whole Blood (LTOWB) </li>
                    <li>Type Specific Whole Blood</li> 
                </ul>

              <li>Plasma : PRC : Platelets (1:1:1) (Reconstituted Whole Blood)</li>

              <li>Plasma : PRC (1:1)</li>

              <li>Plasma or PRC</li>

          </ul>

    <h1>WB Options</h1>
        <ul>
            <li style="color: green;">1. LTOWB from Blood Bank</li>
            <ul>
                <li>Lowest risk / universal product / each unit tested</li>
                <li>Known / Questioned / Typed / Titred / Tested Donor </li>
                <li>Cold Stored from Blood Bank </li>
                <li>Warm Fresh Whole Blood from Walking Blood Bank</li>
            </ul>

            <li style="color:orange">2. LTOWB from Known EDP </li>
            <ul>
                <li>Moderate risk / universal product / unit not tested  </li>
                <li>Warm Fresh Whole Blood Transfusion</li>
                <li>Known / Questioned / Typed / Titred / Tested Donor</li>
                <li>Double identification of LTOWB donor</li>
            </ul>
            <li style="color: #ff5e00;"> 3. Type Specific WB from Known EDP </li>
            <ul>
                <li>Higher risk / not universal product / unit not tested</li>
                <li>Warm Fresh Whole Blood Transfusion</li>
                <li>Known / Questioned / Typed / Tested / Donor</li>
                <li>Double identification of donor</li>
                <li>The casualty's blood type MUST be known</li>
            </ul>
            <li style="color: red;"> 4. Type Specific WB from UNKNOWN EDP </li>
            <ul>
                <li>Highest risk / not a universal product / unit not tested</li>
                <li>Field Questioned / Typed / Tested NOT to Blood Bank standard</li>
                <li> Use Type Specific as Group O Titre will be unknown</li>
                <li>Warm Fresh Whole Blood Transfusion</li>
                <li>Use ONLY when no other option is available</li>
                
            </ul>
        </ul>

  <hr>

        <h1 style="color: orange;"> Risk</h1>
        <ul>
            <li style="color: red;">Major ABO Mismatch causing a Massive Haemolytic Transfusion Reaction</li>
            <li style="color: red;">Anaphylaxis</li>
            <li style="color: orange;">TTD (Disease)</li>
            <li style="color: orange;">TRALI (Lung Injury)</li>
            <li style="color: orange;">TACO (Fluid Overload)</li>
            <li style="color: orange;">GvHD (Graft v Host)</li>
            <li style="color: orange;">Infection</li>
            <li style="color: orange;">Hypocalcaemia from citrate anticoagulant in blood products</li>
            
        </ul>
<hr>
<iframe src="https://player.vimeo.com/video/863926514?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="C - Titrate Blood Products"></iframe>
  </app-lg-info>
  
  <app-lightgreen-box name="CirculationEmergencyDonor"><a routerLink="/CircEmergencyDonor">Emergency Donor Panel</a></app-lightgreen-box>
  <app-lightgreen-box name="CirculationWholeBloodDonation"><a routerLink="/CircWholeBloodDonation">Whole Blood Donation</a></app-lightgreen-box>
</div>
