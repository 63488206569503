<div class="container">
    <app-lightgreen-box name="XsanguinationITClamp">ITCLAMP</app-lightgreen-box>

    <app-lg-info>
        <h1>Indications</h1>

            <ul>
                <li>X Bleeding from wound amenable to clamping on limbs, junction or scalp </li>
            </ul>

        <hr>
        
        <h1>Pre-Procedure</h1>
            <h2>Prep</h2>
            <ul>
                <li>Kit: Remove clamp from case / Avoid closing accidently </li>
                <li>Cas: Position for access to wound</li>
            </ul>

        <h1>Procedure</h1>

            <h2>1 Align</h2>
                <ul>
                    <li>Align open clamp with edges parallel to wound </li>
                </ul>

            <h2>2 Press</h2>

                <ul>
                    <li>Press the open clamp into tissue either side of the wound</li>
                </ul>


            <h2>3 Close</h2>

                <ul>
                    <li>Close clamp pressing the wound edges together sealing the wound </li>
                </ul>

            <h1>Post Procedure</h1>
                <h2>Check</h2>
                <ul>
                    <li>Wipe away excess blood / Assess for leaking</li>
                </ul>

                <h2>Secure</h2>
                    <ul>
                        <li>Consider securing with a bandage placing padding on either side</li>
                    </ul>
        <hr>

        <h1 style="color: orange;">Risk</h1>
            <ul>
                <li style="color: orange;">Leaking from wound edge if the wound is too large for the clamp </li>
                    <ul>
                        <li>Consider 2 Clamps or packing</li>
                    </ul>
            </ul>

            <iframe src="https://player.vimeo.com/video/608417947?h=9bec24055e" width="320" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </app-lg-info>
</div>