import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dis-hyperventilation',
  templateUrl: './dis-hyperventilation.component.html',
  styleUrls: ['./dis-hyperventilation.component.css']
})
export class DisHyperventilationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
