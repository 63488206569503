import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-protect',
  templateUrl: './protect.component.html',
  styleUrls: ['./protect.component.css']
})
export class ProtectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
