<div class="container">
  <app-lightgreen-box name="CirculationMaintain">Maintain Critical Perfusion</app-lightgreen-box>

  <app-lg-info>


      <h1>Access</h1>

          <ul>

              <li>Attempt to obtain 2 circulatory access points</li>
              <li>IV Access</li>
              <ul>
                  <li>18G or larger</li>
              </ul>

              <li>IO Access</li>
              <ul>
                  <li>Sternal FAST1 provides best flow</li>
                  <li>Humeral head provides best flow for EZ-IO</li>
              </ul>
          </ul>

          <h1>Transfusion Triggers</h1>
            <ul>
                <li>MOI + 1 or more S&S below</li>
                <li>MOI</li>
                    <ul>
                        <li>Obvious X bleeding</li>
                        <li>Penetrating torso trauma</li>
                        <li>Amputation or partial amputation </li>
                        <li>Poly trauma</li>
                        <li>Pelvic or femur fracture</li>
                        <li>Internal non-compressible bleeding</li>
                    </ul>

                <li>Signs & Symptoms</li>
                    <ul>
                        <li>Loss of mentation (VP or U in AVPU) in absence of drugs or TBI</li>
                        <li>Loss of radial pulse</li>
                        <li>SI > 1</li>
                        <li>Lactate > 4 mm/l</li>
                        <li>Poor perfusion (Pale / Cold / Clammy - Slow CRT) </li>
                    </ul>
            
                <li>Clinical experience</li>
                
            </ul>

        <h1>Administer</h1>
          <ul>
              <li>1 unit of Whole Blood or Plasma</li>
            <li>250ml bolus of Crystalloid if nothing else available and long evac time</li>
          </ul>
          <h1>Target</h1>
          <ul>
              <li>> 100 mmHg</li>
              <li>Trend to low normal <br>(100 - 110 SBP)</li>
              <li>Normal for TBI 120 mmHg</li>
          </ul>
      <hr>

      <h1 style="color: orange">Risk</h1>

          <ul style="color: orange;">
                <li>Blood products carry risk - Blood Transfusion</li>
              <li>Crystolloids cause Hemodilution & Oedema</li>
              <li>Colloids cause Hemodilution & Coagulopathy</li>
          </ul>

  </app-lg-info>

  <app-lightgreen-box name="CirculationIvAccess"><a routerLink="/CircIvAccess">IV Access</a></app-lightgreen-box>
  <app-lightgreen-box name="CirculationIoAccess"><a routerLink="/CircIoAccess">IO Access</a></app-lightgreen-box>
  <app-lightgreen-box name="CirculationReconPlasma"><a routerLink="/CircReconPlasma">Plasma</a></app-lightgreen-box>
  <app-lightgreen-box name="CirculationWholeBloodTransfusion"><a routerLink="/CircWholeBloodTransfusion">Whole Blood Transfusion</a></app-lightgreen-box>
  <ul> 
      <li></li>
  </ul>

</div>
