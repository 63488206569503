<div class="container">
    <app-lightgreen-box>X Treatment</app-lightgreen-box>

    <app-lg-info>
  

        <h1>Treatment Considerations </h1>

        <ul>
            <li>Treatment will depend on: </li>
                <ul>
                    <li>Assessment</li>
                    <li>Injury</li>
                    <li>Casualty physiology</li>
                </ul>
        </ul>
    
        <H2>Risk Benefit Analysis</H2>
        <ul>
          <li>Risk of no treatment</li>
            <ul>
              <li>Rapid blood loss leading to irreversible shock </li>
            </ul>
          <li>Risk of specific intervention</li>
            <ul>
              <li>Very LOW RISK from Direct Pressure</li>
              <li>Risk from indirect pressure is time dependant </li>
              <li>Note time of indirect control and limit by moving to direct control ASAP</li>
              <li>Risk from closure is location dependent - airway at risk from expanding hematoma </li>
              <li>Abdominal TQ carries much higher risk</li>
            </ul>
          <li>Ability to offset risks</li>
            <ul>
              <li>Experience / Equipment / Environment </li>
            </ul>
          <li>Get Help</li>
            <ul>
              <li>Higher level experience and skill </li>
              <li>Extra hands</li>
              <li>Telemedicine</li>
            </ul>
        </ul>
    
    <h1>Options for X Bleeding</h1>
        <ul>
            <Li>High risk environment (CUF)</Li>
                <ul>
                  <li>Proximal control</li>
                <li>Tourniquet for limbs</li>
                <li>Direct pressure & iTClamp for junctions</li>
                </ul>
            <li>Direct pressure</li>
                <ul>
               <li>To surface of the wound with the palm</li>
                <li>Inside the wound to source of bleeding with finger</li>
                <li>Packing with pressure</li>
                    <ul>
                        <li>Haemostatic gauze</li>
                        <li>Injectable haemostatic material</li>
                        <li>Regular gauze</li>
                        <li>Regular gauze with TXA</li>
                    </ul>
                <li>Pressure bandage</li>
                </ul>
            <li>Elevation</li>
                <ul>
                    <li>Above the level of the heart</li>
                </ul>
            <li>Indirect pressure</li>
                <ul>
                    <li>Digital Proximal Control</li>
                    <li>Limb Tourniquet</li>
                    <li>Junctional Tourniquet</li>
                    <li>AAJT</li>
                </ul>
            <li>Closure</li>
                <ul>
                    <li>iTClamp</li>
                    <li>Foley catheter</li>
                </ul>
        </ul>

<hr>
<iframe src="https://player.vimeo.com/video/749946142?h=68eb992f48" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </app-lg-info>
</div>