<div class="boxFlex">
    <div class="bluebox">
        <div class="left">
            <app-notes [name]="name" ></app-notes>
        </div>
        <div class="center" >
            <ng-content></ng-content>
        </div>
        <div class="right">
            <app-tick [name]="name" ></app-tick>
        </div>
    </div>
</div>