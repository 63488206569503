<div class="container">
    <app-lightblue-box name="XsanguinationReAssess">REASSESS</app-lightblue-box>
<app-lb-info>
    <h1>Effectiveness</h1>
    <h2>Intervention Success</h2>
    <ul>
        <li> Has bleeding stopped</li>
        <p></p>
        <li>Check for rebleeding</li>
        <p></p>
    </ul>

    <h2>Failed Procedure Plan</h2>
    <ul>
        <li>Escalate    
        <ul>
            <li>Direct Pressure</li>
            <li>Digital Proximal Control</li>
            <Li>Limb TQ</Li>
            <li>2nd TQ</li>
            <li>Wound Packing</li>
            <li>Pressure Dressing</li>
            <li>Junctional TQ</li>
            <li>AAJT</li>
            <li>Evac to REBOA / Thoracotomy</li>
        </ul>
        <p></p>
        </li>
        <li>Change: <span style="color: blue;">HOPE - T</span> 
            <ul>
                <li><span style="color: blue;">H</span>elp</li>
                <li><span style="color: blue;">O</span>perator</li>
                <li><span style="color: blue;">P</span>osition</li>
                <li><span style="color: blue;">E</span>quipment</li>
                <li><span style="color: blue;">T</span>echnique</li>
            </ul>
        </li>
    </ul>

    <hr>
    <h1>Secure</h1>
    <ul>
        <li>Intervention is secure (TQ strap is tucked away)</li>
        <p></p>
        <li>Pressure dressing on packing</li>
        <p></p>
        <li>Recheck regularly especially after movement</li>
        <p></p>
    </ul>
    <hr>
    <h1>Condition</h1>
    <ul>
        <li>Predicted clinical course</li>
        <p></p>
        <li>Worsening shock </li>
        <p></p>
        <li>Could there be non-compressible bleeding in the torso?</li>
    </ul>

    <hr>

    <iframe src="https://player.vimeo.com/video/745762130?h=a07a0b931f" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

</app-lb-info>
</div>