
<div class="container">
    <app-lightgreen-box name="ExposureAltitude">ALTITUDE</app-lightgreen-box>
  
    <app-lg-info>

        <h2>Symptoms</h2>
        <h2></h2>
        
            <ul>
                <li>Acute Mountain Sickness (AMS)</li>
                    <ul>
                <li>Mild: Headache + at least one other symptom</li>
                <li>Fatigue</li>
                <li>Nausea & Vomiting </li>
                <li>Insomnia</li>
                <li>Dizziness</li>
                <li>Moderate: Increase in symptom intensity</li>
                    </ul>
            </ul>  

        <h2></h2>
            <ul>
                <li>High Altitude Cerebral Edema (HACE)</li>
                    <ul>
                <li>End stage of AMS</li>
                <li>Neuro symptoms</li>
                <li>Ataxia</li>
                <li>Speech</li>
                <li>Loss of coordination</li>
                <li>Decreased Level Of Consciousness (LOC)</li>
                    </ul>
            </ul>

            <ul>
                <li>High Altitude Pulmonary Edema (HAPE)</li>
                    <ul>
                <li>Shortness Of Breath (SOB)</li>
                <li>Decreased Exercise Ability</li>
                <li>Increased Recovery Time</li>
                <li>Fatigue</li>
                <li>Dry Cough</li>
                <li>Cyanosis</li>
                <li>Frothy Bloody Sputum</li>
                    </ul>
            </ul>
        
    
        <h2>IA Drill</h2>
        <ul>
            <li>Stop ascent </li>
            <li>Descend ASAP 500 - 1000m or until symptoms resolve</li>
            <li>Oxygen - SPO2 > 90% </li>
            <li>Portable Hyperbaric Chamber (PHC)</li>
        </ul>

        <h2>Treatment</h2>
            <ul>
                <li>AMS</li>
                <ul>
                    <li>Acetazolamide: Primary drug for AMS and to facilitate acclimatization</li>
                    <li>Prophylaxis options:</li>
                        <ul>
                            <li>Acetazolamide: 125 mg every 12 hrs</li>
                            <li>Dexamethasone: 2 mg every 6 hrs or 4 mg every 8 hrs (High risk missions)</li>
                        </ul>
                    <li>Treatment of AMS: Acetazolamide: 250 mg every 12 hrs</li>
                    <li>Consider Ibuprofen: 600mg every 8 hrs</li>
                </ul>
            </ul>

        <ul>
            <li>HACE</li>
            <ul>
                <li>Primary: Dexamethasone 8 mg initial dose then 4mg every 6 hrs </li>
                <li>Consider: Acetazolamide 250 mg every 12 hrs</li>
            </ul>
        </ul>

            <ul>
                <li>HAPE</li>
                    <ul>
                    <li>Nifedipine 30 mg ER version every 12 hrs OR 20 mg ER version every 8 hrs </li>
                   <li>Consider: Dexamethasone & or Sildenafil 50 mg every 8 hrs</li>
                    </ul>
            </ul>

            <ul>
                <li>HACE & HAPE</li>
                <ul>
                    <li>Add Dexamethasone to Nifedipine</li>
                </ul>
            </ul>
            
            <ul>
                <li>Consider </li>
                
                <ul>
                    <li>Alternative Cause: dehydration / hypothermia / hypoglycaemia</li>
                </ul>
            </ul>

    
        <h2 style="color: orange;">Risk</h2>
        <ul>
            <li style="color: orange;">The PRC will slide on steep ground</li>
                <li>Move with rope anchor over steep ground</li>
            <li style="color: orange;">All of the above treatments may only offer a temporary resolution</li>
            <li>Only solution is decent</li>

        </ul>

        <iframe src="https://player.vimeo.com/video/896520466?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="E - Altitude"></iframe>
        
        </app-lg-info>
      
      </div>
      
  
  