<!-- LightGreen Box-box -->
<div class="boxFlex" >
    <div class="lg-box">
        <div class="left">
            <app-notes [name]="name"></app-notes>
        </div>
        <div class="center" (click)="onClicked()">
            <p><ng-content></ng-content></p></div>
        <div class="right">
            <app-tick [name]="name"></app-tick>
        </div>
    </div>
</div>

<!-- <ng-content *ngIf="presented"></ng-content>   -->
