
<app-white-box class="header" name="TriageCasualty">>1 Casualty</app-white-box>

<app-white-box-info>

  <!-- Copy and paste as necessary  -->

      <h2>If more then one casualty:</h2>

        <ul>
            <li>Search</li>
            <li>Triage</li>
            <li>Mark or Label</li>
            <li>Treat X with TQ & Airway with lateral position</li>
        </ul>

      <h2>Triage Catagory</h2>
      <ul>
        <li><span style="color: red;">T1</span>: Emergency - Threat to Life </li>
        <li><span style="color: rgb(221, 191, 19);">T2</span>: Urgent: Serious injury cannot walk</li>
        <li><span style="color: green;">T3</span>: Less severe - Walking wounded</li>
        <li><span style="color: blue;">T4</span>: Expectant: Potentially non survivable injury</li>
        <li>Dead: Not breathing - no signs of life / Mark Dead and move to temporary mortuary </li>
      </ul> 

</app-white-box-info>
