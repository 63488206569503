import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-grey-box',
  templateUrl: './grey-box.component.html',
  styleUrls: ['./grey-box.component.css']
})
export class GreyBoxComponent implements OnInit {
  @Input() presented:boolean;
  @Input() heading:string;
  @Input() name:string;

  constructor() { 
    this.presented = false;
  }

  ngOnInit(): void {
  };

  onClicked(){
    this.presented = !this.presented;
  };
}
