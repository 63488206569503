<div class="container">
    <app-lightblue-box name="BreathingReAssess">REASSESS</app-lightblue-box>
    
    <app-lb-info>
        
        <h2>Effectiveness</h2>

            <ul>
                
                <li>Intervention Success</li>

                    <ul>

                        <li>Know how to identify right - for all procedures</li>
                    
                    </ul>

                <li>Failed Procedure Plan</li>

                    <ul>
                        <li>Know haw to identify wrong</li>

                        <li>Escalate</li>

                            <ul>

                                <li>Consider alternate side of chest</li>
                                
                                <li>Thoracostomy</li>

                                <li>Drain</li>
                            
                            </ul>

                        <li>Change</li>

                            <ul>

                                <li><span style="color: blue;">HOPE - T</span></li>

                                <li><span style="color: blue;">H</span>elp</li>

                                <li><span style="color: blue;">O</span>perator</li>

                                <li><span style="color: blue;">P</span>osition</li>

                                <li><span style="color: blue;">E</span>quipment</li>
                                
                                <li><span style="color: blue;">T</span>echnique</li>
                                 
                            </ul>

                    </ul>
            </ul>

        <h2>Secure</h2>

            <ul>

                <li>Intervention is secure</li>

                <li>Protect Needle D cannula</li>

                <li>Chest Tube with chest seals (temp) suture in place</li>
                
                <li>Check after movement - Chest seals loosen and slide</li>
            
            </ul>

        <h2>Condition</h2>

            <ul>

                <li>Predicted clinical outcome</li>

                <li>Decreasing BR</li>
                
                <li>Decreased difficulty breathing</li>

            </ul>

            <hr>

            <iframe src="https://player.vimeo.com/video/809024135?h=57c820087c" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            
    </app-lb-info>

</div>

