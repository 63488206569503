import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-blue-box',
  templateUrl: './blue-box.component.html',
  styleUrls: ['./blue-box.component.css']
})
export class BlueBoxComponent implements OnInit {
  @Input() name:string;
  
   constructor() { }

  ngOnInit(): void {
  }

}
