<div class="container">
    <app-lightgreen-box name="AirwayInspectClear">INSPECT & CLEAR</app-lightgreen-box>
   
    <app-lg-info>

        <h1>Indications</h1>

            <ul>

                <li>Inspect and clear the mouth and airway of foreign objects and fluids</li>
                
            </ul>

        <hr>

        <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
        <ul>
            <li>Kit: Gloves & Eye pro / Head torch / Stethoscope / Laryngoscope / McGill’s forceps / Suction</li>

            <li>Cas: Check air movement then position to optimize air movement</li>
                <ul><li>If the casualty is not moving air, inspect – clear – open the airway and recheck, if there is still no air movement check the pulse and consider respiratory and/or cardiac arrest</li></ul>
        </ul>
          
        

        <h1>Procedure</h1>

            <h2> 1 Position</h2>
            <ul>
                <li>Perform all procedures from optimum airway position</li>
            </ul>

            <h2>2 Open</h2>
                <ul>
                    <li>The mouth</li>
                    <li>Ask cas to extend tongue if able</li>
                </ul>
            <h2>3 Inspect</h2>

                <ul>

                    <li>The anterior part of the mouth with a torch</li>
                    <li>The posterior pharynx can only be viewed using a Laryngoscope</li>
                
                </ul>
            
            <h2>4 Clear</h2>
                <ul>
                    <li>Spit & Cough</li>
                        <ul><li>Encourage the casualty to spit and cough to remove debris or fluid</li></ul>

                    <li>Fluid</li>
                        <ul><li>Suction only under vision</li></ul>
                        <ul><li>No longer than 10 secs</li></ul>
                        <ul><li>Utilize Postural Drainage</li></ul>
                    
                    <li>Solids</li>
                        <ul><li>Remove solids with a finger sweep in the mouth with caution</li></ul>
                        <ul><li>Use Laryngoscope to view pharynx and glottis</li></ul>
                        <ul><li>Use Mc Gill's Forceps to extract FB</li></ul>
                </ul>

        <h1>Post Procedure</h1>
                <ul>
                    <li>Check </li>
                    <ul><li>Air movement regularly especially after moving the casualty or change in condition</li></ul>
                    <ul><li>Use ETCO2 to monitor air movement</li></ul>
                    <li>Secure </li>
                    <ul><li>The casualty in the optimum airway position</li></ul>
                </ul>

        <hr>

        <h1 style="color: orange">Risk</h1>

            <ul>
                
                <li style="color: orange;">Pushing obstruction lower</li>
                    <ul><li>Inspect first and use airway positioning</li></ul>
                
                <li style="color: orange;">Triggering gag reflex</li>
                    <ul><li>Be gentle and cautious / remove instrument or suction if this happens</li></ul>
                
                <li style="color: orange;">Limiting air movement by prolonged suction</li>
                    <ul><li>Max 10 seconds repeat if necessary</li></ul>
            
            </ul>
                
        <hr>

        <iframe src="https://player.vimeo.com/video/699388488?h=bc62938cd1" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </app-lg-info>

</div>
