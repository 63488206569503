import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DatabaseService } from '../database.service';
import * as html2pdf from 'html2pdf.js'


@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.css']
})
export class PdfComponent implements OnInit {
data;
scenarioInfo;
  constructor(private databaseService:DatabaseService) { }

  ngOnInit(): void {
    this.data = this.databaseService.database;
    this.scenarioInfo = this.databaseService.scenarioInfo
    
  }
sortNull() {}

 export2Pdf(){
   const options = {
     filename: this.scenarioInfo.MedicName,
     image: {type: 'jpeg'}, //png, webp
     html2canvas: {position:0},
     jsPDF: {format:[110,700],orientation: 'portrait'}
     
   };
   
   const content: Element = document.getElementById('element-to-export');
   html2pdf()
   .set(options)
   .from(content)
   .save();
  };
}
