import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dis-spinal-injury',
  templateUrl: './dis-spinal-injury.component.html',
  styleUrls: ['./dis-spinal-injury.component.css']
})
export class DisSpinalInjuryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
