<div class="container">
    <app-lightgreen-box name="CirculationWholeBloodDonation">Whole Blood Donation</app-lightgreen-box>
  
    <app-lg-info>
        
        <h1>Indications</h1>
    
        <ul>
            <li>Blood is required to treat potentially life-threatening Haemorrhagic Shock</li>
        </ul>

    <h2>Contraindications</h2>
    <ul>
        <li>ONLY GIVE COMPATIBLE BLOOD (LTOWB or Type Specific) </li>
    </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit: </li>
                    <ul>
                        <li>PPE - Gloves & eye pro</li>
                        <li>Whole blood collection bag (CPD or CPDA-1)</li>
                        <li>Venous TQ</li>
                        <li>Tape - cut 2 strips</li>
                        <li>Disinfectant wipe</li>
                        <li>Gauze</li>
                        <li>Permanent marker</li>
                        <li>Eldon card</li>
                        <li>Oral Hydration Solution 500ml</li>
                        <li>Weight Scale</li>
                    </ul>

                <li>Cas </li>
                    <ul>
                        <li>ENSURE you have the right donor with right blood type </li>
                        <li>Inform of the procedure and best to lie down if first donation </li>
                        <li>Give 500ml oral hydration to sip</li>
                        <li>Check veins on both arms and select </li>
                    </ul>
            </ul>
        
    <h1>Procedure</h1>

        <h2>1 Prepare bag</h2>
            <ul>
                <li>Open and inspect bag for integrity and expiry date</li>
                <li>Label the donor details on the bag </li>
                <ul>
                    <li>Donor Name / Date of donation & blood expiry / Time / Donor Blood Type / Medic</li>
                </ul>
                <li>Place 1 loose knot at the bag end of the line and ensure the clip is open </li>
                <li>Place bag 30cm below vein puncture site avoiding very hot or cold surfaces </li>
            </ul>

        <h2>2 Vein</h2>
        
            <ul>
                <li>Apply venous TQ </li>
                <li>Select best vein</li>
                <li>Disinfect puncture site</li>
            </ul>

        <h2>3 Spike</h2>
            
            <ul>
                <li>Twist off needle cover</li>
                <li>Spike vein bevel up</li>
                <li>Check flow </li>
                <li>Tape in place at entry site and lower down</li>
            </ul>

        <h2>4 Fill </h2>
            
            <ul>
                <li>Mix the anticoagulant and blood with gentle rocking </li> 
                <li>Measure filling by: Weight / Shape / Height </li>
                <li>450ml of whole blood weighs 477 grams not including weight of bag and anticoagulant </li>
            </ul>

        <h2>5 Stop</h2>
            <ul>
                <Li>Close clip when bag is full</Li>
                <li>Tighten knot as close to the bag as possible </li>
                <li>Place & tighten second knot</li>
            </ul>

        <h2>6 Remove</h2>
            <ul>
                <li>Remove the needle  </li>
                <li>Apply direct pressure and dressing to puncture site </li>
                <li>Cover needle with protective cover </li>
            </ul>

            <h2>7 Line</h2>
            <ul>
                <li>Cut line between knots OR </li>
                <li>Wind up and tape </li>
            </ul>

    <h1>Post Procedure</h1>    
    
        <h2>Check</h2>
        
            <ul>
                <li>Bag is correctly marked</li>
                <li>Donor is well - coach not to stand too quickly </li>
                <li>Donation is documented </li>
            </ul>
        
        <h2>Secure</h2>
            <ul>
                <li>Move bag to the cas requiring transfusion </li>
                <li>If not used within 8hrs cool to 2-6 degrees Centigrade</li>
            </ul>

    
    <h1 style="color: orange">Risk</h1>
        <ul>

            <li style="color: orange;">Donor syncope </li>
                <ul><li>Coach / give fluid / lie down / raise legs</li></ul>
            
            <li style="color: orange;">Flow stops </li>
                <ul>
                    <li>Lift the base of the needle gently</li>
                    <li>Adjust needle</li>
                </ul>

                <li style="color: orange;">Lysis </li>
                <ul>
                    <li>Bag must not be placed on very hot or freezing surface </li>
                </ul>
        </ul>

    <hr>

    <iframe src="https://player.vimeo.com/video/864772902?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="C - Whole blood donations"></iframe>

    </app-lg-info>

  </div>
  