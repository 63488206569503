<div class="boxFlex" >
    <div class="lb-box">
        <div class="center">
            <ng-content></ng-content>
        </div>
    </div>
</div>




