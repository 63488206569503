<div class="container">
  <app-lightgreen-box name="CirculationStop">Stop Bleeds</app-lightgreen-box>

  <app-lg-info>





          <ul>
              <li>Reassess any X interventions</li>
              <li>Find and stop all compressible bleeding </li>
                <ul>
                  <li>Head to toe search </li>
                <li>Gloved hand to skin </li>
                <li>Using good light </li>
                </ul>
              <li>Identify non-compressible torso bleeding</li>
                <ul>
                <li>Consider E-FAST</li>
                </ul>
              <li>Traction splint femur fractures</li>

              <li>Pelvic binder for all suspected pelvic fractures</li>

              <li>Consider AAJT for uncontrolled lower limb or pelvic bleeding</li>

    


          </ul>

        </app-lg-info>

  <app-lightgreen-box name="CirculationTractionFractures"><a routerLink="/tractfractures">Traction Splint</a></app-lightgreen-box>

  <app-lightgreen-box name="CirculationPelvicBinder"><a routerLink="/CircPelvicBinding">Pelvic Binder</a></app-lightgreen-box>


</div>
