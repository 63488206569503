import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chest-seal',
  templateUrl: './chest-seal.component.html',
  styleUrls: ['./chest-seal.component.css']
})
export class ChestSealComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
