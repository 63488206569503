<app-goal-risk>GOALS & RISKS</app-goal-risk>

<app-goal-info>

  <h1>Goal</h1>

    <h2>Critical Actions to optimise:</h2>

      <ul>
          <li> Safety </li>
          <li> Scene management</li>
          <li>Patient outcome</li>
          <li>Prevent further harm</li>
          <li>Ideally done concurrently with treatment</li>

      </ul>

      <hr>

      <h1>Risk</h1>

      <h2>Failure to perform PSPs may result in:</h2>

      <ul>
          <li> Additional casualties</li>
          <li> Missed casualties</li>
          <li> Additional injuries</li>
          <li> Poor communication</li>
          <li> Poor scene management</li>
          <li>Delay in evacuation</li>

      </ul>


</app-goal-info>
