<div class="container">
    <app-lightgreen-box name="XsanguinationPacking">PACK & DRESS</app-lightgreen-box>

    <app-lg-info>

        <h1>Indications</h1>

            <ul>

                <li>X Bleeding from junctional wound & all non-superficial wounds below TQ</li>
           
            </ul>
        
        <h1>Contra Indications</h1>
            <ul>
                <li>Packing into the Torso or Cranial cavities</li>
            </ul>

        <hr>

         <h1>Pre-Procedure</h1>
            <h2>Prep</h2>
                <ul>
                    <li>Kit: Use TCCC Haemostatic material or regular Gauze / Prevent contamination prior to packing  </li>
                    <li>Cas: Position for best access to wound / 2nd person gains proximal control / Consider analgesia if conscious</li>
                </ul>

        <h1>Procedure</h1>

            <h2>1 Examine</h2>

                <ul>

                    <li>Sweep blood from wound and identify source of bleeding</li>
                    
                    <li>If not visible then pack blind</li>

                </ul>
                
            <h2>2 Pressure</h2>

                <ul>
                    <li>Apply fingertip pressure to the damaged vessel IN the wound</li>
                </ul>
        

            <h2>3 Packing</h2>

            <ul>

                <li>Pack haemostatic dressing or gauze with Pressure  </li>
                <li>Pack out entire wound tract</li>
                <li>Above level of skin</li>
            
            </ul>
        
            <h2>4 Pressure</h2>

            <ul>

                <li>3 minutes of DIRECT pressure on the packing</li>
            
            </ul>

            <h2>5 Dressing</h2>
                <ul>
                    <li>Apply pressure dressing </li>
                    <li>Consider leaving a 'tail' of packing material</li>
                </ul>
            
                <h1>Post Procedure</h1>

            <h2>Check</h2>
                <ul>
                    <li>Check for rebleeding after packing by leaving a tail OR lifting dressing</li>
                </ul>

            <h2>Secure</h2>

            <ul>

                <li>Ensure the bandage end is tied and tucked away </li>

            </ul>
    
        <hr>

        <h1 style="color: orange;">Risk</h1>
        
            <ul>

                <li style="color: orange;">Re-bleeding on movement</li>
                <ul>
                    <li>Reassess regularly especially after movement </li>
                    <li>Repack or apply TQ</li>
                </ul>
                <li style="color: orange;">Pressure dressing too tight becoming a tourniquet </li>
                    <ul>
                        <li>Reassess distal pulse / sensation / colour & loosen </li>
                    </ul>

            </ul>

            <iframe src="https://player.vimeo.com/video/608404529?h=6b0289e515" width="320" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        
    </app-lg-info>

</div>