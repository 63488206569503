
<app-white-box class="header" name="AssessScene">Assess Scene</app-white-box>

<app-white-box-info>

  <!-- Copy and paste as necessary  -->

      <h2>What happened?</h2>

        <ul>
            <li>Consider Mechanism of Injury & Injury Pattern</li>
        </ul>

        <h2>What is happening now?</h2>

         <ul>
          <li>Number injured (Search)</li>
          <li>Severity of injury on approach (Eyeball)</li>
        </ul>

        <H2>What is your plan?</H2>
        <ul>
          <li>What do you have?</li>
          <li>What do you need?</li>
        </ul>

</app-white-box-info>
