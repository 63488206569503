import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-surgical-cric',
  templateUrl: './surgical-cric.component.html',
  styleUrls: ['./surgical-cric.component.css']
})
export class SurgicalCricComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
