import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circ-pelvic-binder',
  templateUrl: './circ-pelvic-binder.component.html',
  styleUrls: ['./circ-pelvic-binder.component.css']
})
export class CircPelvicBinderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
