<div class="container">
  <app-lightgreen-box name="ExposureHyperthermia">HYPERTHERMIA</app-lightgreen-box>

  <app-lg-info>

    <h2>Classification</h2>
    <ul>
      <li>Mild 38 - 40°C</li>
      <li>Moderate to Severe > 40°C</li>
      <li>Does depend on acclimatization </li>
    </ul>

    <h2>Symptoms</h2>
    <ul>
        <li>Sweating profusely then stops  </li>
        <li>Flushed initially becoming pale </li>
        <li>Loss of coordination & staggering gait</li>
        <li>Confused & Aggressive then unresponsive </li>
        <li>Core temperature above 39°C & increasing  </li>
        <li>Hypotension & cardiovascular collapse</li>
    </ul>

    <h2>IA Drill</h2>
    <ul>
        <li>Stop all physical activity  </li>
        <li>Move to shade</li>
        <li>Remove kit & clothing </li>
        <li>Wet & fan</li>
    </ul>

    <h2>Treatment</h2>
    <ul>
        <li>Continue to cool using tepid water and evaporation with fanning </li>
        <li>Give IV Saline in 250ml boluses & reassess</li>
    </ul>

    <h2>Consider</h2>
      <ul>
        <li>Cardiovascular collapse: resuscitate with saline</li>
      </ul>

    <h2 style="color: orange;">Risk</h2>
    <ul>
        <li style="color: orange;">Over cooling & Hypothermia </li>
        <ul>
          <li>Reassess regularly</li>
        </ul>
        <li style="color: orange;">Associated dehydration</li>
        <ul>
          <li>Rehydrate: Oral or IV</li>
        </ul>
        <li style="color: orange;">Associated hyponatremia  </li>
        <ul>
          <li>Give ORS or IV Saline </li>
        </ul>

    </ul>

    <iframe src="https://player.vimeo.com/video/894075111?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="E - Hyperthermia"></iframe>
  
      </app-lg-info>
    
    </div>
    

