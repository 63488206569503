<div class="container">
    <app-lightgreen-box name="ExposureOpiates">Opiates</app-lightgreen-box>
  
    <app-lg-info>
  
      <h2>Detection</h2>
            <ul>
                <li>Monitor will not detect </li>
                <li>No colour change on detector paper </li>
                <li>T-CRESS</li>
                  <ul>
                    <li>T: Minutes</li>
                    <li>C: Sedation</li>
                    <li>R: Decreased to apnoea </li>
                    <li>E: Miosis</li>
                    <li>S: Normal</li>
                    <li>S: Normal</li>
                  </ul>
            </ul>

      <h2>Decon</h2>
            <ul>
              <li>Remove and bag equipment and clothing </li>
              <li>Wipe away gross contamination /  RSDL cut line /  Cut out of Suit</li>
              <li>RSDL residual contamination on skin (>2min contact time, then wipe away) </li>
              <li>If no RSDL use dry / wet decon </li>
              <li>Remove and replace contaminated treatments (tourniquets)</li>
            </ul>

      <h2>Antidote</h2>
            <ul>
              <li>Naloxone: 2 to 4mg titrated to respiratory effort</li>
              <li>Ventilate – oxygenate </li>
            </ul>

          <h2 style="color: orange;">Risk</h2>
            <ul>
              <li style="color: orange;">Naloxone half life is short  </li>
              <li>Naloxone infusion may be required</li>
            </ul>
            
            <iframe src="https://player.vimeo.com/video/908205419?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="E- Opiates"></iframe>

    </app-lg-info>
  </div>
  