<div class="container">
    <app-lightgreen-box name="CirculationIvAccess">IV Access</app-lightgreen-box>
  
    <app-lg-info>
        
        <h1>Indications</h1>
    
        <ul>
            <li>Route for the administration of IV Blood / Fluids or Drugs</li>
        </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit: </li>
                    <ul>
                        <li>PPE - Gloves & Eye pro</li>
                        <li>Venous Tourniquet or BP Cuff</li>
                        <li>Disinfectant wipe</li>
                        <li>IV Cannula (18 Gauge or larger)</li>
                        <Li>Tape</Li>
                        <li>Syringe & Flush</li>
                        <li>Gauze</li>
                        <li>Sharps container</li>
                    </ul>
                <li>Cas: </li>
                    <ul>
                        <li>Use uninjured arm</li>
                        <li>Position for access</li>
                    </ul>
            </ul>
        
    <h1>Procedure</h1>

        <h2>1 Tourniquet</h2>
        
            <ul>
                <li> Apply IV TQ or BP Cuff</li>
                <li> In shocked cas use a Boa or bandage to bring up veins</li>
            </ul>

        <h2>2 Select</h2>
            
            <ul>
                <li> Select vein - Forearm or hand  </li>
                <li> ACF is last resort - arm must be prevented from bending </li>
            </ul>

        <h2>3 Disinfect</h2>
            
            <ul>
                <li>Disinfect the puncture site</li>
                
            </ul>

        <h2>4 Cannulate </h2>
            <ul>
                <Li> Stabilise vein distally </Li>
                <li> Insert NCU bevel up at low angle and advance to flashback </li>
                <li> Advance cannula off needle to hub</li>
                <li> Remove IV TQ</li>
                <li> Compress vein</li>
                <li> Withdraw needle and dispose of safely</li>
                <li> Remove cap off back of needle - place on back of cannula </li>
                <li> Tape in position</li>
            </ul>

        <h2>5 Flush</h2>
            <ul>
                <li>With 5ml NaCl or WFI</li>
            </ul>

    <h1>Post Procedure</h1>    
    
        <h2>Check</h2>
        
            <ul>
                <li>Flush advances easily</li>
            </ul>
        
        <h2>Secure</h2>
            <ul>
                <li>Tape cannula in position </li>
                <li>Secure cannula with clingfilm</li>
            </ul>

    <h1 style="color: orange">Risk</h1>
        <ul>

            <li style="color: orange;">Pain / Infection / Damage to nerve or artery</li>
                <ul><li>Ensure good technique and practise</li></ul>
            
            <li style="color: orange;">Access to IV system </li>

                <ul>
                    <li>Ensure all fluids and drugs are indicated and administered at correct dose and route</li>
                </ul>
        </ul>
    <hr>

    <iframe src="https://player.vimeo.com/video/845293015?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180
    " frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="C - IV"></iframe>
    </app-lg-info>

  </div>
  