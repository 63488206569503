<app-white-box class="header" name="SafetyTactics">TACTICS</app-white-box>
<app-white-box-info>
    <h1 style="color: red;">R-C-M-C</h1>
 
        <ul>
            <li><span style="color: red;">R</span>eact: Return Fire & Get Cover</li>
            
            <li><span style="color: red;">C</span>ommand & Communications  </li>

            <li><span style="color: red;">M</span>ission Focus</li>

            <li><span style="color: red;">C</span>asualty Extraction</li>
            
        </ul>


</app-white-box-info>