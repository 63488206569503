
<div class="container">
    <app-lightgreen-box name="DisabilityIBA">IBA</app-lightgreen-box>
   
    <app-lg-info>

        <h1>Impact Brain Apnoea </h1>

        <h2>Indications </h2>
        <ul>
            <li>Respiratory arrest following TBI</li>
        </ul>

        <h2>Procedure </h2>
        <ul>
            <li>Ventilate at a maximum of 10 breaths per min</li>
        </ul>

        <h2 style="color: orange;">Risk</h2>
        <ul style="color: orange;">
            <li>If unrecognised may lead rapidly to cardiac arrest </li>
        </ul>

        <hr>

        <iframe src="https://player.vimeo.com/video/873286866?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="D - Impact Brain Apnoea"></iframe>


    </app-lg-info>

</div>