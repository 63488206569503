import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circ-iv-access',
  templateUrl: './circ-iv-access.component.html',
  styleUrls: ['./circ-iv-access.component.css']
})
export class CircIvAccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
