<app-goal-risk>GOALS AND RISKS</app-goal-risk>

<app-goal-info>

  <h1>MAIN HEADING</h1>

    <h2>SECOND HEADING</h2>

      <ul>
          <li> BULLET POINTS copy and paste as necessary</li>
          <li> BULLET POINTS</li>

            <ul>
              <li>Sub bullet points</li>
              <li>Sub bullet points</li>
              <li>Sub bullet points</li>
            </ul>

          <li> BULLET POINTS</li>
          <li> BULLET POINTS</li>
          <li> BULLET POINTS</li>

      </ul>




</app-goal-info>
