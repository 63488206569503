import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maintain',
  templateUrl: './maintain.component.html',
  styleUrls: ['./maintain.component.css']
})
export class MaintainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
