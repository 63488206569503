import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exp-diving',
  templateUrl: './exp-diving.component.html',
  styleUrls: ['./exp-diving.component.css']
})
export class ExpDivingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
