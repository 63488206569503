<div class="container">
    <app-lightgreen-box name="XsanguinationJunctionTQ">Junctional TQ</app-lightgreen-box>

    <app-lg-info>
        
        <h1>Indications</h1>
           
            <ul>
                <li>X Bleeding from junctional wound or uncontrolled limb bleeding</li>
            </ul>

        <hr>

        <h1>Pre-Procedure</h1>
            <h2>Prep</h2>
                <ul>
                    <li>Kit: Assemble components / Check functionality</li>
                    <li>Cas: Position casualty supine / Check femoral pulse location </li>
                </ul>
        
        <h1>Procedure</h1>

            <h2>1 Position</h2>
                <ul>
                    <li>Position belt under casualty with midline of belt in-line with greater trochanters </li>
                    <li>Ensure the TCD is on the same side as the wound and over the femoral pulse</li>
                </ul>
        
            <h2>2 Connect</h2>

                <ul>
                    <li>Connect the belt buckle </li>
                </ul>

            <h2>3 Tighten</h2>

                <ul>
                    <li>Tighten belt with limited movement of pelvis until buckle clicks</li>

                </ul>

            <h2>4 Inflate</h2>
                <ul>
                    <li>Inflate TCD until bleeding controlled & distal pulses eliminated </li>
                </ul>

            <h2>5 Time</h2>

                <ul>
                    <li>Mark the time of application on device and document / Maximum 4 hours</li>
                </ul>
        
        <h1>Post Procedure</h1>
            <h2>Check</h2>
                <ul>
                    <li>Check control of bleeding / Recheck regularly especially after movement</li>
                </ul>

            <h2>Secure</h2>
                <ul>
                    <li>Tuck away straps / Caution dragging or moving using the device</li>
                </ul>
        <hr>

        <h1 style="color: orange;">Risk</h1>
        <ul>
            <li style="color: orange;">Re-bleeding with movement </li>
                <ul>
                    <li>Reassess regularly especially after movement </li>
                    <li>Reposition TCD</li>
                </ul>
            <li style="color: orange;">Ischaemia - Toxic metabolites on release </li>
                <ul>
                    <li>Limit time</li>
                </ul>
            <p></p>
            <li style="color: orange;">SJT affected by altitude</li>
            <ul>
                <li>Reassess and adjust pressure in TCD</li>
            </ul>
        </ul>
        <iframe src="https://player.vimeo.com/video/608428730?h=07790f9c98" width="320" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </app-lg-info>
</div>