
<div class="container">
    <app-lightgreen-box name="DisabilityHypoglycemia">Hypoglycaemia</app-lightgreen-box>
   
    <app-lg-info>
        <h2>Indications</h2>
        <ul>
            <li>Hypoglycaemia - Blood glucose below 4 mmol/l</li>
        </ul>
        <h2>Procedure</h2>
            <ul>
    
                <li>Maintain Normal Blood Glucose</li>
                    <ul>
                        <li>4 - 8 mmol/l</li>
                        <li>70 - 140 mg/dl</li>
                    </ul>
                <li>Consider:</li>
                <ul>
                <li>15g of oral glucose (Gel / Juice / Tablet) for a conscious patient</li>
                <li>For lowered LOC:</li>
                <li>1mg Glucogon or autoinjector</li>
                <li>10% Glucose infusion 200ml over 10min </li>
                <li>25g (50ml) dextrose 50% in water (D50) solution IV/IO </li>
                <li>Recheck blood glucose every 15min </li> 
                </ul>
    
            </ul>
    
    
        <h2 style="color: orange">Risk</h2>
    
            <ul>
                <li style="color: orange;">50% Dextrose can damage blood vessels </li>
                <li>Use 10% IV infusion if available</li>
            </ul>

            <hr>

            <iframe src="https://player.vimeo.com/video/873298564?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="D - Hypoglycemia"></iframe>


    </app-lg-info>

</div>