import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exp-nerve-agent',
  templateUrl: './exp-nerve-agent.component.html',
  styleUrls: ['./exp-nerve-agent.component.css']
})
export class ExpNerveAgentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
