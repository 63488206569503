
<div class="container">
    <app-lightgreen-box name="DisabilitySpinalInjury">Spinal Cord Injury</app-lightgreen-box>
   
    <app-lg-info>

        <h2>Indications </h2>
        <ul>
            <li>History of injury to the spine </li>
                <li> Motor or sensory deficit on examination </li>
                <li>Priapism</li>
        </ul>

        <h2>Procedure </h2>
        <ul>
            <li>Spinal immobilization</li>
            <li>Cautious movement and handling</li>
        </ul>

        <h2 style="color: orange;">Risk</h2>
        <ul>
            <li style="color: orange;">Secondary spinal injury</li>
            <li style="color: orange;">Distributive shock from SCI</li>
        </ul>

        <hr>

        <iframe src="https://player.vimeo.com/video/873292125?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="D - Spinal Cord Injury"></iframe>


    </app-lg-info>

</div>