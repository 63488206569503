import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-white-box-info',
  templateUrl: './white-box-info.component.html',
  styleUrls: ['./white-box-info.component.css']
})
export class WhiteBoxInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
