import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FirebaseService } from '../firebase.service';
import { filter, first, map, startWith, switchMap } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js'
import { AngularFireAuth } from '@angular/fire/auth';
import stripe from 'stripe';

// import { objectExists }

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  //  isSignedIn = false;
  userEmail = '';
  userPassword = '';
  errorMessage = '';
  userID = '7K2WBBSd0sfqnNtiMh67yknfC7K2'; //I need to get this dynamically
  hasAccount: boolean;
  hasSubs: boolean;
  isLoggedIn: boolean;
  membership: boolean;
  isSubscribed: boolean = true;
  plan: string;
  recurringSelected: boolean = false;
  premiumSelected: boolean = false;

  notAuthorized: boolean = false;
  noSubscription: boolean = false;

  busy: boolean = false;

  emailVerified:boolean = false;

  constructor(
    public afAuth: AngularFireAuth,
    public firebaseService: FirebaseService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      this.notAuthorized = params['not-authorized'];
      this.noSubscription = params['no-subscription'];
    });
  }

  ngOnInit(): void {

    if (this.noSubscription) {
      this.isSubscribed = false
    }
  }
 
  tryAgain() {
    // this.afAuth.signOut()
    //   .then(() => {
    //     this.firebaseService.userEmail = '';
    //     this.router.navigateByUrl('/')
    //   }
    //   )
this.isLoggedIn=!this.isLoggedIn;
this.noSubscription = true;
this.isSubscribed = true;
this.router.navigateByUrl('/');
  }

  emailPasswordLogin() {
    if (this.userEmail && this.userPassword) {
      this.busy = true;
      this.afAuth.signInWithEmailAndPassword(this.userEmail, this.userPassword).then(async res => {
        console.log('successful login', res);
        this.userID = res.user.uid;
        this.firebaseService.userEmail = this.userEmail;
        this.firebaseService.userID = this.userID;
        this.firebaseService.isLoggedIn = true;
        this.isLoggedIn = this.firebaseService.isLoggedIn;
        console.log(`${this.userID} is loggedIn`);
        this.emailVerified = (await this.afAuth.currentUser).emailVerified;
        this.checkPayment();

        // if ((!this.emailVerified)){
        //   // alert('please verify your email and try again ')
        //   this.busy = false;
        //   // this.router.navigateByUrl('/');
        // }else{
        //   this.checkPayment();
        //   this.busy = false;
        // }

      }).catch((err) => {
        this.busy = false;
        alert(`${err} Please send an email to info@atem.org.uk to reset your password.`);
      });
    }
  }

  checkPayment() {
    try {
      firebase.default
        .firestore()
        .collection('production_customers')
        .doc(this.userID)
        .collection('payments')
        .where('livemode',"==",true)
        .get()
        .then(async (querySnapshot) => {
          this.busy = false;
          console.log(querySnapshot.size)
          if (querySnapshot.size > 0) {
            this.noSubscription = false;
            this.isSubscribed = true;
            this.router.navigateByUrl('/infoPage');
          }
          else {
            this.isSubscribed = false
          }
        })
    } catch (error) {
      this.busy = false;
      console.log("error validating subscribtion", error);
      alert('an error occurred while attempting to validate your subscription. Our team is busy investigating what happened, please try again later.')
    }
  }

  // checkSubs() {
  //   try {
  //     firebase.default
  //       .firestore()
  //       .collection('production_customers')
  //       .doc(this.userID)
  //       .collection('subscriptions')
  //       .where('current_period_end', '>', new Date())
  //       .get()
  //       .then(async (querySnapshot) => {
  //         this.busy = false;
  //         console.log(querySnapshot);
  //         if (querySnapshot.size > 0) {
  //           this.router.navigateByUrl('/infoPage');
  //         }
  //         else {
  //           this.isSubscribed = false
  //         }
  //       })
  //   } catch (error) {
  //     this.busy = false;
  //     console.log("error validating subscribtion", error);
  //     alert('an error occurred while attempting to validate your subscription. Our team is busy investigating what happened, please try again later.')
  //   }
  // }

  recurringClicked() {
    this.recurringSelected = !this.recurringSelected;
    if (this.premiumSelected = true) {
      this.premiumSelected = false;
      this.plan = 'price_1JnJPaFTpscF2Vfw2FGD11sv';
    }
  }

  premiumClicked() {
    this.premiumSelected = !this.premiumSelected;
    if (this.recurringSelected = true) {
      this.recurringSelected = false;
      this.plan = 'price_1MpxtoFTpscF2VfwMk43qqDu';
    }
  }

  async cancelCheckout() {
    this.isSubscribed = true
  }

  async sendToCheckout() {
    this.busy = true;
    firebase.default
      .firestore()
      .collection('production_customers')
      .doc(this.userID)
      .collection('checkout_sessions')
      .add({
        mode: "payment",
        price: this.plan, // Price ID goes here
        success_url: window.location.origin, // return user to this screen on successful purchase
        cancel_url: window.location.origin, // return user to this screen on failed purchase
        allow_promotion_codes: true
      })
      .then((docRef) => {
        // Wait for the checkoutSession to get attached by the extension
        docRef.onSnapshot(async (snap) => {
          const { error, sessionId } = snap.data();
          if (error) {
            // Show an error to your customer and inspect
            // your Cloud Function logs in the Firebase console.
            alert(`An error occurred: ${error.message}`);
          }

          if (sessionId) {
            // We have a session, let's redirect to Checkout
            // Init Stripe
            const stripe = await loadStripe(
              'pk_live_51JJjlqFTpscF2VfwUMhpfOYeCOX4zmURiW3OdpV681SAbamsOwRQBSwjvlAR15hB1AjQC9qJtOa3mlVdXvvK9Cfc00Y4UfMXBp' // stripe key goes here
            );
            console.log(`redirecting`);
            await stripe.redirectToCheckout({ sessionId });
          }
        });
      }).catch((err) => {
        this.busy = false;
      });
  }



}
