
<app-white-box class="header" name="SafetyCasualty">Casualty</app-white-box>

<app-white-box-info>

  <!-- Copy and paste as necessary  -->

    <h2>Treat immediate threats to life:</h2>


      <h2>Med Immediate Action Drills</h2>

        <ul>
            <li>CUF: X Bleed & Airway & Pain (CUF=XAP)</li>
            <li>Burning: Remove from source / Extinguish / Cool burn – Warm casualty</li>
            <li>Life-threatening Hypothermia: Protect & Warm</li>
            <li>Life-threatening Heat Injury: Stop activity & Cool</li>
            <li>CBRN: Administer Antidote</li>
            <li>Anaphylaxis: IM Adrenalin</li>
            <li>No Sign of Life: CPR</li>
        </ul>

        <h2>Weapons</h2>
        <ul>
            <li>Friendly forces remove weapon if:</li>

            <ul>
              <li>T1 - Severe injury</li>
              <li>TBI - Even M-TBI (Concussion)</li>
              <li>Drugs - Especially Ketamine</li>
            </ul>
        </ul>

        <h2>Hostile wounded</h2>
        <ul>
          <li>Disarm</li>
          <li>Restrain</li>
          <li>Search</li>
          <li>Triage</li>
          <li>Treat</li>
        </ul>

</app-white-box-info>
