<div class="container">
    <app-lightgreen-box name="CirculationTractionFractures">Traction Splint</app-lightgreen-box>
  
    <app-lg-info>
        
        <h1>Indications</h1>
    
        <ul>
            <li>Mid-shaft femur fracture</li>
        </ul>

    <h2>Contraindications</h2>
        <ul>
            <li>Knee / Ankle fractures or dislocation </li>
            <li>Partial amputation</li>

        </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit: PPE - Gloves & Eye pro / Assemble and measure traction splint on good leg </li>
                <li>Cas: Position for traction splint application  </li>
                    <ul>
                        <li>Analgesia - consider procedural sedation</li>
                        <li>Expose & assess distal neurovascular function</li>
                        <li>Move limb to normal alignment </li>
                        <li>If help available  - apply manual inline traction </li>
                        <li>If pelvic fracture is suspected then apply pelvic binder first </li>
                    </ul>
            </ul>
        
    <h1>Procedure</h1>

        <h2>Kendrick Traction Device (KTD)</h2>

        <h2>1 Thigh Strap</h2>
        
            <ul>
                <li>Apply thigh strap high in groin</li>
                <li>Pole holder on the outside of the leg </li>
            </ul>

        <h2>2 Poles</h2>
            
            <ul>
                <li>Adjust length of pole by adding sections  </li>
                <li>Place pole ends in pole holder</li>
                <li>Black section of pole extending past the foot </li>
            </ul>

        <h2>3 Knee Strap</h2>
            
            <ul>
                <li>Attach yellow strap above knee  </li>
                <li>Re-tighten after traction</li>
            </ul>

        <h2>4 Ankle Strap</h2>
            
            <ul>
                <li>Padding behind ankle</li>
                <li>Tighten loop under foot by pulling green strap </li>
                <li>Ensure yellow end is long and red is short </li>
                
            </ul>

        <h2>5 Traction </h2>
            <ul>
                <Li>Yellow strap over pole end</Li>
                <li>Traction applied by gently pulling red strap</li>
                <li>Apply traction until normal alignment </li>
            </ul>

        <h2>6 Velcro Straps</h2>
            <ul>
                <li>Attach green and red Velcro straps </li>
            </ul>

        <h2>7 Boot Hitch</h2>
            <ul>
                <li>Place boot hitch over arctic boot  </li>
                <li> White label to back of boot heel </li>
            </ul>

    <h1>Post Procedure</h1>    
    
        <h2>Check</h2>
        
            <ul>
                <li>Distal neurovascular function</li>
                <li>Comfort and support </li>
                <li>Recheck after movement</li>
            </ul>
        
        <h2>Secure</h2>
            <ul>
                <li>All straps</li>
                <li>Pad and support</li>
            </ul>

    
    <h1 style="color: orange">Risk</h1>
        <ul>

            <li style="color: orange;">Pain </li>
                <ul><li>Ensure adequate analgesia</li></ul>
            
            <li style="color: orange;">Loss of distal neurovascular function </li>

                <ul>
                    <li>Check NV function before and after application</li>
                    <li>Reassess regularly especially after movement</li>
                </ul>
        </ul>


    <hr>
    <iframe src="https://player.vimeo.com/video/841181140?h=499f97f365" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </app-lg-info>

  </div>
  