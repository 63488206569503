<div class="container">
    <app-lightgreen-box name="CirculationIoAccess">IO Access</app-lightgreen-box>
  
    <app-lg-info>
        
        <h1>Indications</h1>
    
        <ul>
            <li>Access for blood / drugs / fluids where IV access was unsuccessful</li>
        </ul>

        <h2>Contraindications</h2>
        <ul>
            <li>Under 12 years </li>
            <li>Bone injury at insertion or proximal</li>
        </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit: </li>
                    <ul>
                        <li>PPE - Gloves & Eye pro</li>
                        <li>Disinfectant wipe</li>
                        <li>FAST1 Device </li>
                        <li>EZ-IO Device</li>
                        <li>Connector</li>
                        <li>3 way tap</li>
                        <li>Flush</li>
                        <li>Prime tube and 3 way tap prior to aspiration and flush</li>
                    </ul>
                <li>Cas: </li>
                    <ul>
                        <li>Position for access to IO site</li>
                    </ul>
            </ul>
        
    <h1>Procedure</h1>

    <h2>FAST1 - Sternum</h2>

        <h2>1 Disinfect</h2>
        
            <ul>
                <li>The manubrium of the sternum</li>
            </ul>

        <h2>2 Target patch</h2>
            
            <ul>
                <li> Remove the back of the target patch exposing sticky surface  </li>
                <li> Place a finger in the jugular notch use this to seat the target patch</li>
                <li> Ensure midline symmetrical placement</li>
            </ul>

        <h2>3 Prime</h2>
            <ul>
                <li>Attach 3 way tap to connector tube and prime both with flush</li>
            </ul>

        <h2>4 Insert </h2>
            
            <ul>
                <li> Kneeling at the head end facing the feet </li>
                <li> Remove safety cap and place guide needles in target patch circle  </li>
                <li> Ensure 90 degree angle </li>
                <li> Grasping the device with 2 hands push firmly into the sternum feeling the device fire </li>
                <li> Pull back leaving the IO tube in place </li>
                <li> Connect IO tube to infusion tube</li>
                <li> Place clear protector cap on target patch Velcro</li>
                <li> Place the safety cap on the needles</li>
                
            </ul>

        <h2>5 Flush</h2>
            <ul>
                <Li> Attach a syringe and draw back confirming placement as blood and marrow enters tube</Li>
                <li> Give a forceful flush in 2ml syringe to separate marrow and create vascular access</li>
            </ul>

    <h2>Easy IO</h2>

            <h2>1 Select Site & Needle </h2>
                <ul>
                    <li>Proximal Humerus (Best flow) - Yellow Needle</li>
                    <ul>
                        <li>Place wrist at umbilicus</li>
                        <li>Palpate deeply to feel the surgical neck of humerus </li>
                        <li>Site is 1 to 2cm above surgical neck on most prominent part of humeral head</li>
                        <li>Insert needle 45 degrees to anterior plane</li>

                    </ul>
                    <li>Proximal or Distal Tibia - Blue Needle </li>
                    <ul>
                        <li>Proximal tibia: 3cm below patella and 2cm medial</li>
                        <li>Distal tibia: 3cm proximal to top of medial malleolus </li>
                        <li>Insert needle perpendicular to bone surface</li>
                    </ul>
                    <li>Attach IO needle to driver</li>

                </ul>

            <h2>2 Disinfect</h2>
                <ul>
                    <li>Insertion site</li>
                </ul>

            <h2>3 Insert</h2>
                <ul>
                    <li> Push the tip through the skin to bone </li>
                    <li> Black 5mm markers must be visible above skin - if not select longer needle</li>
                    <li> Power drive or push the needle through the bone until a give is felt - stop there</li>
                    <li> Withdraw the needle leaving the cannula in place </li>
                    <li> Place the EZ-Stabilizer dressing</li>
                    <li> Connect the primed extension set </li>
                </ul>

            <h2>4 Flush</h2>
                <ul>
                    <li>Attach a syringe with flush - aspirate - then flush forcefully 5 to 10ml</li>
                </ul>

    <h1>Post Procedure</h1>    
    
        <h2>Check</h2>
        
            <ul>
                <li>Flow - additional flush may be required</li>
            </ul>
        
        <h2>Secure</h2>
            <ul>
                <li>Make sure any lines attached are taped so first pull is not on the IO cannula</li>
                <li>With EZ-IO humeral head placement secure arm to chest to avoid needle displacement </li>
            </ul>

    
    <h1 style="color: orange">Risk</h1>
        <ul>

            <li style="color: orange;">Loss of flow</li>
                <ul><li>Attempt flush or alternative placement</li></ul>
            
            <li style="color: orange;">Infection</li>
                <ul>
                    <li>Best aseptic technique</li>
                </ul>
                      
                <li style="color: orange;">Access to IV System</li>
                <ul>
                    <li>Ensure all blood / fluids / drugs are indicated and administered at correct dose and route</li>
                </ul>  
        </ul>

    <hr>

    <iframe src="https://player.vimeo.com/video/850904131?h=2412f54795" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </app-lg-info>

  </div>
  