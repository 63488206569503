import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-white-box',
  templateUrl: './white-box.component.html',
  styleUrls: ['./white-box.component.css']
})
export class WhiteBoxComponent implements OnInit {
@Input() name:string;

  constructor() { }

  ngOnInit(): void {
  }

}
