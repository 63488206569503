
<div class="container">
    <app-lightgreen-box name="DisabilityTBI">TBI</app-lightgreen-box>
   
    <app-lg-info>

        <h1>TBI Categories </h1>

        <ul>
            <li>Mild  (GCS 13–15)</li>
            <li>Moderate  GCS (9-12)</li>
            <li>Severe (GCS 3-8) (Herniation)</li>
        </ul>
    
    <h2>Mod - Sev TBI: </h2>
    <h2> Prevent the 5 H Bombs</h2>
            <ul style="color: red;"> 
                <li>Hypoxia</li>
                <li>Hyperventilation</li>
                <li>Hypotension</li>
                <li>High ICP</li>
                <li>Hypo/Hyper-Glycaemia</li>
            </ul>
    
            <hr>
    
        <h1>Hypoxia </h1>
    
        <h2>Indications</h2>
    
        <ul>
    
            <li>Avoid Hypoxia in TBI</li>
        </ul>
    
        <h2>Procedure</h2>
    
            <ul>
                <li>Optimise Airway </li>
                <li>Give supplemental oxygen for SPO2 >95%</li>
            </ul>
    
        <h2 style="color: orange">Risk</h2>
    
            <ul style="color: orange;">
    
                <li>Hypoxia will worsen TBI - A drop below SPO2 of 90% can double mortality</li>
    
            </ul>
    
            <hr>
    
            <h1>Hyperventilation</h1>
    
            <h2>Indications</h2>
            <ul>
                <li>Avoid Hyperventilation in TBI</li>
            </ul>
    
            <h2>Procedure</h2>
                <ul>
                    <li>Ventilate at 10 breaths per minute maximum - with a cadence device (timekeeper)</li>
                    <li>Keep ETCO2 around 40mmHg / 5 kPa</li>
                        <ul>
                            <li>Range: 35 - 45 mmHg (4.6 - 5.3 kPa) </li>
                        </ul>
    
                <li>Hyperventilation may help in brain herniation - Attempt telemedicine consult</li>
                </ul>
    
            <h2 style="color: orange">Risk</h2>
    
                <ul style="color: orange;">
    
                    <li>Positive pressure ventilation will decrease cardiac output</li>
                    <li>Hyperventilation causes cerebral vasospasm increasing hypoxia</li>
                </ul>
    
                <hr>
    
        <h1>Hypotension</h1>
    
        <h2>Indications</h2>
        <ul>
            <li>Avoid Hypotension in TBI</li>
        </ul>
    
        <h1>Procedure</h1>
            <ul>
                <li>Stop bleeding </li>
                <li>Resuscitate haemorrhagic shock with whole blood</li>
                <li>Aim for SBP of 120 - 130mmHg</li>
            </ul>
    
    
        <h2 style="color: orange">Risk</h2>
            <ul style="color: orange;">
                <li>Hypotension will decrease DO2 - A drop below 90mmHg may double mortality</li>
            </ul>
    
            <hr>
    
            <h1>High ICP</h1>
    
            <h2>Indications</h2>
            <ul>
                <li>Avoid High ICP in TBI</li>
            </ul>
            <h2>Procedure</h2>
                <ul>
                    <li>Elevate head of bed (HOB) 30° - 60° </li>
                        <li>Neck in midline position</li>
                        <li>Avoid the H Bombs </li>
                        <li>Aim for ETCO2 of 40mmHg</li>
                        <li>Temperature between 36 and 37C</li>
                        <li>Prevent or rapidly manage seizure activity</li>
    
                    <li>Avoid:</li>
                        <ul>
                            <li>Circumferential neck pressure</li>
                            <li>Vomiting - consider antiemetic</li>
                            <li>Increased thoracic pressure (TPX)</li>
                        </ul>
    
                    <li>Consider: Hypertonic Saline: 3% NaCl 250ml bolus over 20 minutes </li>
    
                </ul>
    
            <h2 style="color: orange">Risk</h2>
    
                <ul style="color: orange;">
                    <li>Raised ICP requires specialist management at Neuro Trauma Unit </li>  
                </ul>
    
                <hr>
    
    
        <h1>Hypo/Hyper-glycaemia</h1>
    
    
        <h2>Indications</h2>
    
        <ul>
    
            <li>Avoid Hypo/Hyper-glycaemia in TBI</li>
        </ul>
        <h2>Procedure</h2>
    
            <ul>
    
                <li>Maintain Normal Blood Glucose</li>
                    <ul>
                        <li>4 - 8 mmol/l</li>
                        <li>70 - 140 mg/dl</li>
                    </ul>
                <li>Hypoglycemia < 4 mmol/l Consider:</li>
                <ul>
                <li>15g of oral glucose (Gel / Juice / Tablet) </li>
                <li>1mg Glucogon or autoinjector</li>
                <li>10% Glucose infusion 200ml over 10min </li>
                <li>25g (50ml) dextrose 50% in water (D50) solution IV/IO </li>
                <li>Recheck blood glucose every 15min </li> 
                </ul>
    
                <Li>Hyperglycaemia > 8 mmol/l Consider:</Li>
                <ul>
                    <Li>Crystalloid administration</Li>
                    <li>Insulin therapy if glucose >10 mmol/l [180 mg/dl] - Telemedicine consult</li>
                </ul>
    
            </ul>
    
    
        <h2 style="color: orange">Risk</h2>
    
            <ul>
                <li style="color: orange;">50% Dextrose can damage blood vessels - </li>
                <li>Use 10% IV infusion if available</li>
    
            </ul>

            <hr>

            <h1>Mild TBI</h1>

            <h2>Indications </h2>
            <ul>
                <li>Head trauma</li>
                <li>Exposure to blast wave (50m)</li>
            </ul>
            <h2>Procedure</h2>
                <ul>
                    <li>Conduct a MACE exam</li>
                </ul>

            <h2 style="color: orange;">Risk</h2>
            <ul>
                <li style="color: orange;"> Missed Mild-TBI places at greater risk:</li>
                <ul style="color: orange;">
                    <li>Subsequent TBI</li>
                    <li>Behavioural changes</li>
                    <li>Decision making</li>
                </ul>
            </ul>

            <hr>

            <iframe src="https://player.vimeo.com/video/873284225?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="D - TBI"></iframe>

    </app-lg-info>

</div>