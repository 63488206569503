
<app-white-box class="header" name="CommsCasualty">Casualty</app-white-box>

<app-white-box-info>

  <!-- Copy and paste as necessary  -->


      <h2>Speak to the casualty:</h2>

        <ul>
            <li>Introduction</li>
            <li>History</li>
            <li>Chief complaint</li>
            <li>Consent</li>
        </ul>

</app-white-box-info>
