import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ega',
  templateUrl: './ega.component.html',
  styleUrls: ['./ega.component.css']
})
export class EGAComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
