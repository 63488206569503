import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-postural-drainage',
  templateUrl: './postural-drainage.component.html',
  styleUrls: ['./postural-drainage.component.css']
})
export class PosturalDrainageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
