<div class="container">

    <app-lightgreen-box name="AirwayOpen">OPEN </app-lightgreen-box>
   
    <app-lg-info>

        <h1>Manual Airway Manoeuvres</h1>

        <ul></ul>

        <h1>Indications</h1>

        <ul>

            <li>The tongue obstructing or partially obstructing the airway - considering the possibility of C-Spine injury</li>
            
        </ul>

    <hr>

    <h1>Pre-Procedure</h1>

    <h2>Prep</h2>
    <ul>
        <li>Kit: PPE – Gloves and Eye protection</li>

        <li>Cas: Ensure the patient is in a position that optimises air movement / If C-Spine injury likely, place in the Lateral Inline-Airway Position (LIP) </li>
           
    </ul>

    <h1>Procedure</h1>

        <h2>1 Head Tilt Chin Lift</h2>
        <ul>
            <li>No spinal injury suspected: Hyperextend the head while lifting the chin anteriorly </li>
        </ul>

        <h2>2 Jaw Thrust</h2>
            <ul>
                <li>Suspected C-Spine injury: Lift the jaw with the index and middle finger</li>
            </ul>

    <h1>Post Procedure</h1>
            <ul>
                <li>Check </li>
                <ul><li>Air movement regularly especially after moving the casualty or change in condition</li></ul>
  
                <li>Secure </li>
                <ul><li>Manual Airway Manoeuvres need to be maintained to be effective</li></ul>
            </ul>
    <hr>

    <h1 style="color: orange">Risk</h1>

        <ul>
            
            <li style="color: orange;">The intervention does not protect the airway from blood, vomit or debris</li>
                <ul><li>Inspect and clear the airway before using MAM</li></ul>
            
            <li style="color: orange;">Debris may move further down the airway when the airway is opened</li>
                <ul><li>Inspect and clear the airway before opening </li></ul>
            
            <li style="color: orange;">Procedure must be physically maintained   </li>
                <ul><li>Use Airway positioning – LAP or LIP</li></ul>
        
        </ul>
    
        <hr>

        <iframe src="https://player.vimeo.com/video/704466362?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="A- Open"></iframe>
        
    </app-lg-info>

</div>
