<div class="container">
  <app-lightgreen-box name="CirculationStart">Start the Clock</app-lightgreen-box>

  <app-lg-info>


    <h2>Minimize time to:</h2>

          <ul>

              <li> Tourniquet </li>

              <li> Control of junctional bleeding  </li>

              <li>Blood product based resuscitation: less than 30 min</li>

              <li>Evacuation</li>

              <li>Surgical control of bleeding</li>

              <li>Definitive care</li>


          </ul>

          <iframe src="https://player.vimeo.com/video/834735620?h=a8dde82eea" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

  </app-lg-info>

</div>
