import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circ-maintain',
  templateUrl: './circ-maintain.component.html',
  styleUrls: ['./circ-maintain.component.css']
})
export class CircMaintainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
