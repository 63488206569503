<div class="container">
    <app-lightgreen-box name="XsanguinationLimbTq">LIMB TQ</app-lightgreen-box>

    <app-lg-info>

    <h1>Indications</h1>
    
        <ul>
            <li>X Bleeding from a limb or amputation - amenable to TQ</li>
        </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit: Use TCCC TQ / use casualty's TQ first </li>
                <li>Cas: Position for TQ application  </li>
            </ul>
        
    <h1>Procedure</h1>

        <h2>1 High</h2>
        
            <ul>
                <li>Apply TQ 2 to 3 inches above the wound </li>
                <li>Or as high as possible if uncertain of wound location</li>
            </ul>

        <h2>2 Tight x 2</h2>
            
            <ul>
                <li>1. Apply TIGHT </li>
                <li>2. Tighten to STOP BLEEDING and eliminate distal pulses</li>
            </ul>

        <h2>3 Time</h2>
            
            <ul>
                <li>Mark the time of application on device and document</li>
            </ul>

    <h1>Post Procedure</h1>    
    
        <h2>Check</h2>
        
            <ul>
                <li>Bleeding controlled</li>
                <li>For junctional wounds above TQ</li>
                <li>Recheck after movement</li>
            </ul>
        
        <h2>Secure</h2>
            <ul>
                <li>Maximum Velcro fastened</li>
                <li>Secure loose end of TQ</li>
            </ul>

    
    <h1 style="color: orange">Risk</h1>
        <ul>

            <li style="color: orange;">Pain - Casualty may attempt to loosen </li>
                <ul><li>Consider analgesia</li></ul>
            
            <li style="color: orange;">Loosening & rebleeding </li>
                <ul><li>Reassess regularly especially after movement / Tighten TQ on rebleeding or apply 2nd TQ</li></ul>
        </ul>

        <ul>

            <li style="color: orange;">Ischaemia - Toxic metabolites on release /  Nerve palsy /  Compartment Syndrome </li>
                <ul><li>Limit time</li></ul>
            
        </ul>

    <hr>

    <h1>Notes</h1>
        
            <h2>2 Person</h2>

            <ul>
            
                <li>1st person gains proximal control with indirect pressure on pressure point</li>
            
                <li>2nd person places TQ</li>
            
            </ul>


        <h2>2nd TQ</h2>

        <ul>
            
            <li>Place 2nd TQ on skin</li>
            
            <li>Proximal to 1st TQ if possible and adjacent</li>
            
            <li>2 TQs on lower limbs as standard</li>

        </ul>

        <h2>Downgrading Tourniquet</h2>
            <ul>
                <li>Attempt within 2 hrs UNLESS amputation / partial amputation & OR severe shock  </li>
                <li>Pack and dress wound</li>
                <li>Slowly loosen TQ</li>
                <li>Loosen completely but leave in place</li>
                <li>Tighten on rebleeding</li>
                <li>Alternatively place a 2nd TQ closer to wound and loosen the high TQ</li>
            </ul>
            <hr>

            <<iframe src="https://player.vimeo.com/video/638161307?h=48393a9015" width="320" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>>

    </app-lg-info>

</div>
