import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fur-history',
  templateUrl: './fur-history.component.html',
  styleUrls: ['./fur-history.component.css']
})
export class FurHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
