import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-limb-tq',
  templateUrl: './limb-tq.component.html',
  styleUrls: ['./limb-tq.component.css']
})
export class LimbTQComponent implements OnInit {
  highPresented = false;
  tightPresented = false;
  timePresented = false;
  checkPresented = false;
  persPresented = false;
  TQPresented = false;
  riskPresented = false;

  onHighClicked(){
    this.highPresented = !this.highPresented;
  };
  onTightClicked(){
    this.tightPresented = !this.tightPresented;
  };
  onTimeClicked(){
    this.timePresented = !this.timePresented;
  };
  onCheckClicked(){
    this.checkPresented = !this.checkPresented;
  };
  on2PersClicked(){
    this.persPresented = !this.persPresented;
  };
  on2TQClicked(){
    this.TQPresented = !this.TQPresented;
  };
  onRiskClicked(){
    this.riskPresented = !this.riskPresented;
  };
  constructor() { }

  ngOnInit(): void {
  }
 
}
