<div class="container">
    <app-lightgreen-box name="ExposureCyanide">Cyanide</app-lightgreen-box>
  
    <app-lg-info>
  
      <h2>Detection</h2>
            <ul>
                <li>Monitor may detect dependant on concentration</li>
                <li>No colour change on detector paper to vapour </li>
                <li>T-CRESS</li>
                  <ul>
                    <li>T: Very Rapid Onset of Symptoms (seconds – minutes)</li>
                    <li>C: Altered / unconscious</li>
                    <li>R: Normal to apnoeic </li>
                    <li>E: Normal unless vapor irritant</li>
                    <li>S: None</li>
                    <li>S: May be flushed - Red (50% of the time)</li>
                  </ul>
            </ul>

      <h2>Decon</h2>
            <ul>
              <li>Remove and bag equipment and clothing </li>
            </ul>

      <h2>Antidote</h2>
            <ul>
              <li>Cyanokit (Hydroxocobalamin): 5gm IV over 5 min in 200mL NS</li>
              <li>Ventilate – oxygenate </li>
            </ul>

          <h2 style="color: orange;">Risk</h2>
            <ul>
              <li style="color: orange;">Sodium Nitrite causes Methaemoglobin</li>
                <ul>
                   <li>Caution in shock</li>
                   <li>Antidote is time critical</li>
                </ul>

            </ul>

            <iframe src="https://player.vimeo.com/video/908200347?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="E- Cyanide"></iframe>
            
    </app-lg-info>
  </div>
  