<div class="container">
    <app-lightgreen-box name="XsanguinationProx">Proximal Control</app-lightgreen-box>

    <app-lg-info>

    <h1>Indications</h1>
    
        <ul>
            <li>X Bleeding from a limb or junction </li>
        </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit: Practised Gloved Thumb</li>
                <li>Cas: Position Cas for access to pressure point</li>
            </ul>

    <h1>Procedure</h1>

        <h2>1 Locate</h2>
        
            <ul>
                <li>Locate pressure point proximal to wound: </li>
                    <ul>
                <li>Femoral for lower limbs</li>
                <li>Subclavian for upper limbs</li>
                <li>Distal humeral for forearm or hand</li>     
                    </ul>

            </ul>

        <h2>2 Compress</h2>
            
            <ul>
                <li>Feel for pulse and then compress </li>
            </ul>

        <h1>Post Procedure</h1>

        <h2>Check</h2>
            
            <ul>
                <li>Check for bleeding control / adjust if necessary </li>
            </ul>

        <h2>Secure</h2>
        <ul>
            <li>Secure by maintaining pressure on pressure point</li>
        </ul>

            <hr>

        <h1 style="color: orange;">Risk</h1>
            <ul>
                <li style="color: orange;">Finger position can shift off pulse </li>
                    <ul><li>Reassess regularly especially after movement</li></ul>
                <li style="color: orange;">Finger fatigue </li>
                    <ul><li>Change hand or provider</li></ul>
            </ul>

            <hr>

            <iframe width="320" height="180" src="https://www.youtube.com/embed/sROgeOhjUQo?si=KpUC87PctF5c0Tmi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

    </app-lg-info>

</div>

