
<div class="container">
    <app-lightgreen-box name="DisabilityMildTBI">Mild TBI</app-lightgreen-box>
   
    <app-lg-info>

        <h1>Place holder</h1>

        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Autem rerum corporis illum corrupti ipsam odit molestias amet cupiditate adipisci voluptates voluptatibus, reprehenderit consequuntur tenetur! Deserunt rerum molestias quam sint sed.</p>

    </app-lg-info>

</div>