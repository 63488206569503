<div class="container">
  <app-lightgreen-box name="CirculationRetain">Retain Heat</app-lightgreen-box>


  <app-lg-info>

    
    <h1>Indications</h1>
    
    <ul>
        <li>Hypothermia avoidence in all trauma casualties</li>
    </ul>

<hr>

<h1>Pre-Procedure</h1>

    <h2>Prep</h2>
        <ul>
            <li>Kit: </li>
                <ul>
                    <li>Stretcher </li>
                    <li>Cover</li>
                    <li>Insulation</li>
                    <li>Active warming </li>
                </ul>
            <li>Cas: </li>
                <ul>
                    <li>Move rapidly out of the wind and off the ground if possible </li>

                </ul>
        </ul>
    
<h1>Procedure</h1>

      <h2>1 Check Temperature </h2>
    <ul>
        <Li> Get baseline temp ASAP</Li>
    </ul>

    <h2>2 Move</h2>
    
        <ul>
            <li>Out of wind and off ground</li>
        </ul>

    <h2>3 Remove</h2>
        <ul>
            <li> Wet clothing and dry casualty  </li>
        </ul>

      <h2>4 Off ground </h2>
        <ul>
            <li> Break contact with the ground  </li>
        </ul>

      <h2>5 Cover </h2>
        <ul>
            <li> Casualty with weatherproof material and insulation  </li>
        </ul>

      <h2>6 Warm</h2>
        <ul>
            <li> Actively warm with warming device and warmed fluids  </li>
        </ul>

        <h2>7 Resuscitate </h2>
        <ul>
            <li> With warmed Whole Blood to improve metabolism </li>
        </ul>

<h1>Post Procedure</h1>    

    <h2>Check</h2>
    
        <ul>
            <li>Warming is effective</li>
            <li>Limit repeated exposure </li>
        </ul>

    <h2>Secure</h2>
        <ul>
          <li>Secure cover to avoid re-exposure </li>
        </ul>

<h1 style="color: orange">Risk</h1>
    <ul>

        <li style="color: orange;"> Overwarming </li>
            <ul><li>Check temp - aim for 37C</li></ul>
        
        <li style="color: orange;"> No improvement  </li>
            <ul>
                <li> External warming alone is unlikely to warm a shocked casualty - Resuscitate using warm Whole Blood </li>
            </ul>
    </ul>
<hr>


<iframe src="https://player.vimeo.com/video/857836939?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="C - Retain Heat"></iframe>
  </app-lg-info>

</div>
