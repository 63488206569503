

<div class="container">
    <app-lightgreen-box name="DisabilityHypoxia">Hypoxia</app-lightgreen-box>
   
    <app-lg-info>

        <h1 style="color: green;" >Indications</h1>

        <ul style="color: red;">

            <li>Avoid Hypoxia in TBI</li>

     
        </ul>

        <hr>

        <h1>Procedure</h1>
        
            <ul>

                <li>Give highflow O2 @ 15 lt/min to keep SPO2 >95%</li>
                

            </ul>

        <hr>

        <h1 style="color: orange">Risk</h1>

            <ul style="color: orange;">
                
                <li>Hypoxia will worsen TBI</li>
                
            </ul>


    </app-lg-info>

</div>