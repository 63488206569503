import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circ-emergency-donor',
  templateUrl: './circ-emergency-donor.component.html',
  styleUrls: ['./circ-emergency-donor.component.css']
})
export class CircEmergencyDonorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
