
<div class="info-bits">
  <app-blue-box name="PrePrimary"><a style="color: white;"  routerLink="/Pre-PrimaryGoal"> PRE-PRIMARY <br> SURVEY PRIORITIES<br> S-A-C-T</a> </app-blue-box>
    <div class="greyBoxes">

      <!-- Safety -->
      <app-grey-box heading="SAFETY" name="Safety">
        <div class="safety">
          <app-white-box name="SafetyTactics"><a routerLink="/TacticsPage">Tactics</a></app-white-box>
          <app-white-box name="SafetySelf"><a routerLink="/SafetySelf">Self</a></app-white-box>
          <app-white-box name="SafetyScene"><a routerLink="/SafetyScene">Scene</a></app-white-box>
          <app-white-box name="SafetyCasualty"><a routerLink="/SafetyCasualty">Casualty (MIA Drills)</a></app-white-box>
        </div>
      </app-grey-box>

      <!-- Assess -->
      <app-grey-box heading="ASSESS" name="Assess">
        <div class="assess">
          <app-white-box name="AssessScene"><a routerLink="/AssessScene">Scene</a></app-white-box>
        </div>
      </app-grey-box>

      <!-- Comms -->
      <app-grey-box heading="COMMS" name="Comms">
        <div class="comms">
          <app-white-box name="CommsTeam"><a routerLink="/CommsTeam">Team</a></app-white-box>
          <app-white-box name="CommsEvacuation"><a routerLink="/CommsEvacuation">Evacuation</a></app-white-box>
          <app-white-box name="CommsCasualty"><a routerLink="/CommsCasualty">Casualty</a></app-white-box>
        </div>
      </app-grey-box>

      <!-- Triage -->
      <app-grey-box heading="TRIAGE" name="Triage">
        <div class="triage">
          <app-white-box name="TriageSearch"><a routerLink="/TriageSearch">Search</a></app-white-box>
          <app-white-box name="TriageCasualty"><a routerLink="/TriageCasualty">>1 Casualty</a></app-white-box>
           <app-white-box name="TriageEvac"><a routerLink="/TriageEvacuation">Evac via CCP</a></app-white-box>
        </div>
      </app-grey-box>
    </div>

    <!-- Primary Survey -->
    <app-blue-box name="Primary"><a style="color: white;"  routerLink="/Primary-goal">PRIMARY SURVEY <br> X-A-B-C-D-E-F</a></app-blue-box>
    <div class="greyBoxes">

      <!-- Cat Hem -->

      <app-grey-box heading="XSANGUINATION" name="Xsanguination">
        <div class="catHem">

          <app-lightblue-box info="ASSESS"  name="XsanguinationAssess"><a routerLink="/CatHemAssess">ASSESS</a></app-lightblue-box>
          <app-lightgreen-box #XsanguinationIntervention name="XsanguinationIntervention">INTERVENTION</app-lightgreen-box>
          <div class="catHem" *ngIf="ifPresented(XsanguinationIntervention)">
              <app-lightgreen-box name="XsanguinationTreatment"><a routerLink="/CatHemTreatment">Treatment</a></app-lightgreen-box>
              <app-lightgreen-box name="XsanguinationProx"><a routerLink="/ExProx">Proximal Control</a></app-lightgreen-box>
              <app-lightgreen-box name="XsanguinationLimbTq"><a routerLink="/LimbTQ">Limb TQ</a></app-lightgreen-box>
              <app-lightgreen-box name="XsanguinationPacking"><a routerLink="/Packing">Pack & Dress</a></app-lightgreen-box>
              <app-lightgreen-box name="XsanguinationITClamp"><a routerLink="/itclamp" >ITClamp</a></app-lightgreen-box>
              <app-lightgreen-box name="XsanguinationJunctionTQ"><a routerLink="/JunctionalTQ" >Junctional TQ</a></app-lightgreen-box>
              <app-lightgreen-box name="XsanguinationFoley"><a routerLink="/Foley">Foley Catheter</a></app-lightgreen-box>
              <app-lightgreen-box name="XsanguinationAAJT"><a routerLink="/AAJT" >AAJT</a></app-lightgreen-box>

          </div>
          <app-lightblue-box name="XsanguinationReAssess"><a routerLink="/CatHemReAssess">REASSESS </a></app-lightblue-box>

        </div>
      </app-grey-box>

      <!-- Airway -->

      <app-grey-box heading="AIRWAY" name="Airway">
        <div class="Airway">

          <app-lightblue-box info="ASSESS" name="AirwayAssess"><a routerLink="/AirwayAssess">ASSESS</a></app-lightblue-box>

          <app-lightgreen-box #AirwayIntervention name="AirwayIntervention" >INTERVENTION</app-lightgreen-box>

          <div *ngIf="ifPresented(AirwayIntervention)">
            <app-lightgreen-box name="AirwayTreatment"><a routerLink="/AirwayTreatment">Treatment</a></app-lightgreen-box>
              <app-lightgreen-box name="AirwayPosturalDrainage"><a routerLink="/AirwayPosturalDrainage">Airway Position</a></app-lightgreen-box>
              <app-lightgreen-box name="AirwayInspect"><a routerLink="/AirwayInspectClear">Inspect & Clear</a></app-lightgreen-box>
              <app-lightgreen-box name="AirwayOpen"><a routerLink="/AirwayOpen">Open</a></app-lightgreen-box>
              <app-lightgreen-box name="AirwayMaintain"><a routerLink="/AirwayMaintain" >Maintain</a></app-lightgreen-box>
              <app-lightgreen-box name="AirwayProtect"><a routerLink="/AirwayProtect" >Protect</a></app-lightgreen-box>
              <app-lightgreen-box name="AirwayEGA"><a routerLink="/AirwayEGA" >EGA</a></app-lightgreen-box>
              <app-lightgreen-box name="AirwayIntubation"><a routerLink="/AirwayIntubation" >Intubation</a></app-lightgreen-box>
              <app-lightgreen-box name="AirwaySurgicalCric"><a routerLink="/AirwaySurgicalCric" >Surgical Cric</a></app-lightgreen-box>
          </div>

          <app-lightblue-box name="AirwayReAssess"><a routerLink="/AirwayReAssess">REASSESS </a></app-lightblue-box>

        </div>

      </app-grey-box>

      <!--Breathing-->

      <app-grey-box heading="BREATHING" name="Breathing">
        <div class="breathing">

          <app-lightblue-box  info="ASSESS" name="BreathingAssess"><a routerLink="/BreathingAssess">ASSESS</a></app-lightblue-box>

          <app-lightgreen-box #BreathingIntervention name="BreathingIntervention" >INTERVENTION</app-lightgreen-box>


          <div *ngIf="ifPresented(BreathingIntervention)">
              <app-lightgreen-box name="BreathingTreatment"><a routerLink="/BreathingTreatment">TREATMENT</a></app-lightgreen-box>
              <app-lightgreen-box name="BreathingChestSeal"><a routerLink="/BreathingChestSeal">Chest Seal</a></app-lightgreen-box>
              <app-lightgreen-box name="BreathingDecompress"><a routerLink="/BreathingDecompress">Decompress</a></app-lightgreen-box>
              <app-lightgreen-box name="BreathingChestDrain"><a routerLink="/BreathingChestDrain" >Chest Drain</a></app-lightgreen-box>
              <app-lightgreen-box name="BreathingVentilate"><a routerLink="/BreathingVentilate" >Ventilate</a></app-lightgreen-box>
          </div>

          <app-lightblue-box info="ASSESS" name="BreathingReAssess"><a routerLink="/BreathingReAssess">REASSESS</a></app-lightblue-box>

        </div>

      </app-grey-box>

      <!-- Circulation -->

      <app-grey-box heading="CIRCULATION" name="Circulation">

        <div class="cirulation">

          <app-lightblue-box name="CirculationAssess"><a routerLink="/CircAssess">ASSESS </a></app-lightblue-box>


          <app-lightgreen-box #CirculationIntervention name="CirculationIntervention">INTERVENTION</app-lightgreen-box>

          <div *ngIf="ifPresented(CirculationIntervention)">
              <app-lightgreen-box name="CirculationTreatment"><a routerLink="/CircTreatment">Treatment </a></app-lightgreen-box>
              <app-lightgreen-box name="CirculationStart"><a routerLink="/CircStart" >Start Clock</a></app-lightgreen-box>
              <app-lightgreen-box name="CirculationStop"><a routerLink="/CircStop" >Stop Bleeds</a></app-lightgreen-box>
              <app-lightgreen-box name="CirculationMaintain"><a routerLink="/CircMaintain" >Maintain Critical Perfusion</a></app-lightgreen-box>
              <app-lightgreen-box name="CirculationAdminister"><a routerLink="/CircAdminister" >Administer</a></app-lightgreen-box>
              <app-lightgreen-box name="CirculationRetain"><a routerLink="/CircRetain" >Retain Heat</a></app-lightgreen-box>
              <app-lightgreen-box name="CirculationTitrate"><a routerLink="/CircTitrate" >Titrate Blood Products</a></app-lightgreen-box>
              <app-lightgreen-box name="CirculationThink"><a routerLink="/CircThink" >Think Alt Causes</a></app-lightgreen-box>

          </div>

          <app-lightblue-box name="CirculationReAssess"><a routerLink="/CircReAssess">REASSESS</a></app-lightblue-box>

        </div>

      </app-grey-box>

      <!-- Disability -->

      <app-grey-box heading="DISABILITY" name="Disability">

        <div class="disability">

          <app-lightblue-box info="ASSESS" name="DisabilityAssess"><a routerLink="/DisAssess">ASSESS</a></app-lightblue-box>

          <app-lightgreen-box #DisabilityIntervention name="DisabilityIntervention">INTERVENTION</app-lightgreen-box>

          <div *ngIf="ifPresented(DisabilityIntervention)">
              <app-lightgreen-box name="DisabilityTreatment"><a routerLink="/DisRBA">Treatment</a></app-lightgreen-box>
              <!-- <app-lightgreen-box name="DisabilityHyperventilation"><a routerLink="/DisHyperventilation">Hyperventilation</a></app-lightgreen-box> -->
              <!-- <app-lightgreen-box name="DisabilityHypoxia"><a routerLink="/DisHypoxia" >Hypoxia</a></app-lightgreen-box>
              <app-lightgreen-box name="DisabilityHypotension"><a routerLink="/DisHypotension" >Hypotension</a></app-lightgreen-box>
              <app-lightgreen-box name="DisabilityHighICP"><a routerLink="/DisHighICP" >High ICP</a></app-lightgreen-box> -->
              <app-lightgreen-box name="DisabilityCbtCas"><a routerLink="/DisCBTCas">Combative Cas</a></app-lightgreen-box>
              <app-lightgreen-box name="DisabilityTBI"><a routerLink="/DisTBI">TBI</a></app-lightgreen-box>
              <app-lightgreen-box name="DisabilityIBA"><a routerLink="/DisIBA">Impact Brain Apnoea</a></app-lightgreen-box>
              <app-lightgreen-box name="DisabilityLoweredLOC"><a routerLink="/DisLowerLOC">Lowered LOC</a></app-lightgreen-box>
              <app-lightgreen-box name="DisabilitySpinalInjury"><a routerLink="/DisSpinalInjury">Spinal Injury</a></app-lightgreen-box>
              <app-lightgreen-box name="DisabilitySeizures"><a routerLink="/DisSeizures">Seizures</a></app-lightgreen-box>
              <app-lightgreen-box name="DisabilityHypoglycemia"><a routerLink="/DisHypoglyc" >Hypoglycaemia</a></app-lightgreen-box>
              <!-- <app-lightgreen-box name="DisabilityMildTBI"><a routerLink="/DisMildTBI">Mild TBI</a></app-lightgreen-box> -->
            </div>

            <app-lightblue-box info="ASSESS" name="DisabilityReAssess"><a routerLink="/DisReAssess">REASSESS</a></app-lightblue-box>

        </div>
      </app-grey-box>

      <!-- Exposure -->

      <app-grey-box heading="EXPOSURE" name="Exposure">
        <div class="exposure">



          <app-lightblue-box name="ExposureAssess"><a routerLink="/ExpAssess" >ASSESS</a></app-lightblue-box>


          <app-lightgreen-box #ExposureIntervention name="ExposureIntervention">INTERVENTION</app-lightgreen-box>
          
          <div *ngIf="ifPresented(ExposureIntervention)">
              <app-lightgreen-box name="ExposureTreatment"><a routerLink="/ExpTreatment">TREATMENT</a></app-lightgreen-box>
              <app-lightgreen-box name="ExposureHypothermia"><a routerLink="/ExpHypothermia">Hypothermia</a></app-lightgreen-box>
              <app-lightgreen-box name="ExposureHyperthermia"><a routerLink="/ExpHyperthermia">Hyperthermia</a></app-lightgreen-box>
              <app-lightgreen-box name="ExposureAltitude"><a routerLink="/ExpAltitude">Altitude</a></app-lightgreen-box>
              <app-lightgreen-box name="ExposureDiving"><a routerLink="/ExpDiving">Diving</a></app-lightgreen-box>
              <app-lightgreen-box name="ExposureBurns"><a routerLink="/ExpBurns" >Burns</a></app-lightgreen-box>
              <app-lightgreen-box name="ExposureToxins"><a routerLink="/ExpToxins" >Toxins</a></app-lightgreen-box>
          </div>

          <app-lightblue-box name="ExposureReAssess"><a routerLink="/ExpReAssess" >REASSESS</a></app-lightblue-box>

        </div>
      </app-grey-box>

      <!-- Futher treatment -->

      <app-grey-box heading="FURTHER TREATMENT" name="FurtherTreatment">
        <div class="further-treatment">
          <app-lightgreen-box name="FurSecondarySurvey"><a routerLink="/FurSecond">Secondary Survey</a></app-lightgreen-box>
          <app-lightblue-box name="FurReAssess"><a routerLink="/FurReAssess">REASSESS</a></app-lightblue-box>
          <app-lightgreen-box name="FurPackage"><a routerLink="/FurPackage">PACKAGE</a></app-lightgreen-box>
          <app-lightblue-box name="FurHistory"><a routerLink="/FurHistory">HISTORY</a></app-lightblue-box>
          <app-lightgreen-box name="FurAnalgesia"><a routerLink="/FurAnalgesia">ANALGESIA</a></app-lightgreen-box>
          <app-lightblue-box name="FurAntiBiotics"><a routerLink="/FurAntibiotics">ANTIBIOTICS</a></app-lightblue-box>
          <app-lightgreen-box name="FurHandover"><a routerLink="/FurHandover">HANDOVER</a></app-lightgreen-box>
        </div>
      </app-grey-box>
    </div>
  </div>