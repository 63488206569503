import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tactics-route',
  templateUrl: './tactics-route.component.html',
  styleUrls: ['./tactics-route.component.css']
})
export class TacticsRouteComponent implements OnInit {
rcmcPresented = false;

  // onRCMCClicked(){
  //   if(this.rcmcPresented == false){
  //     this.rcmcPresented = true
  //   } else {
  //     this.rcmcPresented = false
  //   }
  // }; 
  
  onRCMCClicked(){
    this.rcmcPresented = !this.rcmcPresented;
  };
  constructor() { }

  ngOnInit(): void {
  }

}
