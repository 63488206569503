<div class="container">
  <img class="logo" src="../../assets/logo.png" alt="">

  <div *ngIf="!isRegistered && !busy">
    <div class="header">

      <h1>Sign Up</h1>
      <h3>Please provide an email and password:</h3>
    </div>
    <div class="input-fields">
      <div class="email-field">
        <input type="email" placeholder="Email" [(ngModel)]="userEmail">
      </div>
      <div class="password-field">
        <input type="password" placeholder="Password" [(ngModel)]="userPassword">
      </div>

    </div>

    <div class="signUP">
      <h5 style="margin-left: 0px;" (click)="emailPasswordSignUp()">Sign Up</h5>
    </div>
    <div class="signUpError">
      <h3 *ngIf="error !== ''">{{error}}</h3>
    </div>
  </div>

  <div class="products" *ngIf="isRegistered && !busy">

    <h3>Please select a product:</h3>

    <!-- <div #recurring (click)="recurringClicked()" [ngClass]="recurringSelected ? 'recurringSelected' : 'recurring'">
      <img class="subscription" src="./../../assets/icons/recurring.jpg" alt="">
      <h4>£4 monthly</h4>
    </div> -->

    <div #premium (click)="premiumClicked()" [ngClass]="premiumSelected ? 'premiumSelected' : 'premium'">
      <img class="subscription" src="./../../assets/icons/price.png" alt="">
      <h4>£23</h4>
    </div>

    <div class="checkout">
      <img class="basket" src="../../assets/icons/basket.png" alt="">
      <h5 (click)="sendToCheckout()">Proceed to checkout</h5>
    </div>

    <div class="cancel">
      <h5 style="margin-left: 0px;" (click)="cancelCheckout()">Cancel</h5>
    </div>

  </div>

  <div class="products" *ngIf="busy">

    <h3>We're busy redirecting you...</h3>

  </div>
</div>