<div class="container">
    <app-lightgreen-box name="CirculationEmergencyDonor">Emergency Donor Panel</app-lightgreen-box>
  
    <app-lg-info>
        
        <h2>2 Types of EDP </h2>

        <ul>
            <li>KNOWN: Preselected donors that have been approved for donation to blood bank standard </li>
                <ul>
                    <Li>Questioned / Typed / Tested / Titred (if group O) (See WB Donation)</Li>
                </ul>
            <li>UNKNOWN: Unquestioned / Untyped / Untested / Untitred potential donors</li>
                <ul>
                    <li>This group is then Questioned / Typed / Tested in the field - not to blood bank standard</li>
                </ul>
        </ul>

        <hr>
        <h1>UNKNOWN EDP</h1>
        <hr>

        <h1>Indications</h1>
    
        <ul>
            <li>Whole Blood required to treat Haemorrhagic Shock</li>
        </ul>

        <h2>Contraindications</h2>
        <ul>
            <li>ONLY GIVE COMPATIBLE BLOOD (LTOWB OR TYPE SPECIFIC)</li>
        </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit: </li>
                    <ul>
                        <li>Approved Donation Questionaire</li>
                        <li>Eldon Card</li>
                        <li>Hep B & C / HIV / POC Test </li>
                    </ul>
            </ul>
        
    <h1>Procedure for Unknown EDP</h1>

        <h2>1 Brief</h2>
        
            <ul>
                <li>Explain what is needed, why and what is involved in blood donation</li>

            </ul>

        <h2>2 Volunteers</h2>
            
            <ul>
                <li>Ask for volunteers</li>
                <li>Prioritize active or previous blood donors </li>
            </ul>

        <h2>3 Defer if:</h2>
            
            <ul>
                <li>Unwell now</li>
                <li>On meds for heart / kidney / lung / blood conditions / Cancer </li>
                <li>Received blood products in the last year</li>
                <li>Told not to donate blood by doctor</li>
                <li>High risk sexual history</li>
                <li>Pregnant females</li>
                <li>Physical exam shows abnormality</li>
                <li>Less than 50kg</li>
                
            </ul>

            <h2>4 The remainder make up the EDP</h2>

        <h2>5 Risk Stratify</h2>
            <ul>
                <li>Question donor privately and score </li>

                <Li>Donation History</Li>
                    <ul>
                        <li>1 Regular donor </li>
                        <li>2 Has donated </li>
                        <li>3 Never donated </li>
                    </ul>

                <li>Veins</li>
                <ul>
                <li>1 Good </li>
                <li>2 Moderate </li>
                <li>3 Poor</li>  
                </ul>
  
                <li>Infection </li>
                    <ul>
                    <li>1 More than 21 days well</li>
                    <li>2 Less than 21 days well </li>
                    <li>3 Less than 7 days well</li> 
                    </ul>

                <li>Travel in last 6 months</li>
                <ul>
                    <li>1 Not travelled to Africa or South America or Asia </li>
                    <li>2 Two of the above </li>
                    <li>3 All of above </li>
                </ul>

                <li>Lifestyle</li>
                <ul>
                    <li>1 Sex with one partner </li>
                    <li>2 Sex with multiple partners </li>
                    <li>3 Sex with sex workers </li>
                </ul>

                <li>Medical Condition </li>
                    <ul>
                        <li>1 None </li>
                        <li>2 Well controlled </li>
                        <li>3 Untreated but well</li>
                    </ul>
            </ul>

        <h2>6 Add up</h2>
            <ul>
                <li>Add up score and document </li>
                <li>Lowest score = lowest risk </li>
            </ul>

            <h2>7 Check blood type </h2>
            <ul>
                <li>Blood type with Eldon card </li>
                <li>Document and mark each donor with their blood type </li>
            </ul>

            <h2>8 TTD test </h2>
            <ul>
                <li>Use POC test for Hep B & C / HIV / Syphilis </li>
            </ul>
            
            <h2>9 List</h2>
            <ul>
                <li>List EDP Donors in order of preference  </li>
                <li>Arrange system of notification for donation </li>
            </ul>

    <h1>Post Procedure</h1>    
    
        <h2>Check</h2>
            <ul>
                <li>Quantity of blood needed?</li>
                <li>Order of donation </li>
            </ul>
        
        <h2>Secure</h2>
            <ul>
                <li>Document all results and keep a record</li>
            </ul>

    
    <h1 style="color: orange">Risk</h1>
        <ul>

            <li style="color: orange;">Unknown titre</li>
                <ul><li>Type O WB will be of unknown titre - caution as universal product </li></ul>
            
            <li style="color: orange;">TTD POC Tests  </li>
                <ul>
                    <li>Not as accurate as a lab</li>
                </ul>

                <li style="color: orange;">Questionnaire  </li>
                <ul>
                    <li>Not as thorough as a National Blood Bank Questionnaire</li>
                </ul>

            <h2>Use ONLY in emergency</h2>
        </ul>

    <hr>

    <iframe src="https://player.vimeo.com/video/864752101?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="C - Emergency Donor Panel"></iframe>
    </app-lg-info>

  </div>
  