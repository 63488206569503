<div class="container">
    <app-lightgreen-box name="ExposureNerveAgent">Nerve Agent</app-lightgreen-box>
  
    <app-lg-info>
  
      <h2>Detection</h2>
            <ul>
                <li>Monitor should detect dependant on concentration</li>
                <li>Colour change on detector paper to liquid </li>
                <li>T-CRESS</li>
                  <ul>
                    <li>T: Immediate to delayed - minutes</li>
                    <li>C: Altered / unconscious / seizures</li>
                    <li>R: Tachypnoea, wheezing, respiratory distress </li>
                    <li>E: Miosis (may not be present with some organophosphates)</li>
                    <li>S: Copious secretions (salivation, lacrimation, bronchorrhea)</li>
                    <li>S: Diaphoresis</li>
                  </ul>
            </ul>

      <h2>Decon</h2>
            <ul>
              <li>Remove and bag equipment and clothing </li>
              <li>Wipe away gross contamination /  RSDL cut line /  Cut out of Suit</li>
              <li>RSDL residual contamination on skin (>2min contact time, then wipe away) </li>
              <li>If no RSDL use dry / wet decon </li>
              <li>Remove and replace contaminated treatments (tourniquets)</li>
            </ul>

      <h2>Antidote</h2>
            <ul>
              <li>Autoinjector containing the drugs below (Adult)</li>
              <li>Atropine: 3mg IV </li>
              <li>Pralidoxime:  2 grams IV over 30min</li>
              <li>Diazepam: 10mg IV </li>
              <li>Ventilate – oxygenate </li>
            </ul>

          <h2 style="color: orange;">Risk</h2>
            <ul>
              <li style="color: orange;">Nerve agents 'Age' after which oximes don't work</li>
              <li>Antidote is time critical</li>
            </ul>
            
            <iframe src="https://player.vimeo.com/video/908195010?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="E- Nerve Agent"></iframe>

    </app-lg-info>
  </div>
  