<app-goal-risk >GOALS AND RISKS</app-goal-risk>

<app-goal-info>


    <h2>GOALS</h2>
      <ul>
          <li>Identify & Treat Life-threatening Environmental Exposure</li>
      </ul>

      <h2>RISK</h2>
      <ul>
        <li>Hypothermia</li>
        <Li>Heat Injury (Hyperthermia)</Li>
        <li>Altitude</li>
        <li>Diving</li>
        <li>Burns</li>
        <li>Toxins</li>
          <ul><li>CBRN</li>
            <li>Toxic Industrial Chemicals</li>
          </ul>
      </ul>


</app-goal-info>
