<div class="container">
  <app-lightgreen-box name="FurAnalgesia">ANALGESIA</app-lightgreen-box>

  <app-lg-info>


      <h1 style="color: blue;">PPPPP</h1>

          <ul>

            <li>Pain Score Prior</li>
            <li>Psychological</li>
            <li>Physical</li>
            <li>Pharmacological </li>
            <li>Pain Score Post</li>

          </ul>
      <hr>

      <h1>Pain Score</h1>
      <ul>
        <li>1 - 10</li>
        <li>OR</li>
        <li>Mild / Moderate / Severe </li>
      </ul>
      <hr>

      <h1>Psychological</h1>
      <ul>
        <li>Reassurance</li>
        <li>Distraction</li>
        <li>Necessity </li>
      </ul>
      <hr>

      <h1>Physical</h1>
        <ul>
          <li>Dress wounds </li>
          <li>Splint fractures </li>
          <li>Cover burns</li>
        </ul>
        <hr>

        <h1>Pharmacological</h1>
          <h2>Mild - Moderate Pain</h2>
            <ul>
              <li>Oral Paracetamol (Acetaminophen): 500mg - 1g every 6 hrs - max 4 gram in 24 hrs </li>
              <li>OR</li>
              <li>IV Paracetamol (Acetaminophen): 1g over 15 min every 6 hours - max 4 grams in 24 hrs</li>
              <li>WITH</li>
              <li>Oral Ibuprofen: 400mg every 6 hrs - max 1,600mg in 24 hrs</li>
              <li>OR</li>
              <li>Oral Meloxicam 15mg (once in 24 hrs) </li>
              <li style="color: orange;">Risk: NSAID’s (Ibuprofen & Meloxicam) affect platelet function and should be avoided if bleeding or risk of bleeding</li>
                <ul>
                  <li>Avoid NSAID’s in deployed setting if possible </li>
                </ul>
            </ul>

          <h2>Moderate - Severe Pain </h2>
          <h2>NO Shock</h2>
            <ul>
              <li>Opiates: Fentanyl OR Morphine</li>
            </ul>
            <ul>
             <li>Fentanyl</li>
              <ul>
                <li>Oral Transmucosal Fentanyl Citrate (OTFC) 800 micrograms (Opiate)</li>
                <li>OR</li>
                <li>IV 50 micrograms repeat after 30min</li>                
              </ul>

             <li>Morphine</li>
                <ul>
                   <li>Morphine 2mg every 5 min to max 20mg / Consider a 5mg loading dose (Opiate)</li>
                </ul>
             
                <li>Antidote</li>
                  <ul>
                    <li> Naloxone - 400 micrograms IM/IV </li>
                    <li>Naloxone is short acting and may require repeat doses</li>
                  </ul>

              <li>Antiemetic</li>
                  <ul>
                    <li>Ondansetron 4mg (separate syringe)</li>                    
                  </ul>

                <li style="color: orange;">Risk:</li>
                  <ul>
                    <li style="color: orange;">  Opiates Contraindicated in Shock: SPB < 100 mmHg</li>
                    <li style="color: orange;">Shock: SPB < 100 mmHg</li>
                    <li style="color: orange;">Respiratory depression: RR < 10</li>
                    <li style="color: orange;"> TBI: GCS < 15</li>
                    <li>Avoid opiates in Shock</li>
                  </ul>
            </ul>

            <H2>Severe Pain with Shock</H2>
              <ul>
                <li>Ketamine: </li>
                    <ul>
                      <li>Analgesia of choice for shocked casualty</li>
                      <li>Can be used with TBI</li>
                    </ul>
                <li>IV/IO Dose: 20mg repeat @ 20min  </li>
                <li>IM/IN Dose: 50mg repeat @ 30min </li>
                  <ul>
                    <li>Analgesic dose: 0.1-0.3 mg/kg (IV)</li>
                    <li>Hallucinogenic dose: 0.3-1.0 mg/kg (IV)</li>
                    <li>Dissociative dose: 1-2 mg/kg (IV)</li>
                    <li>End point: Control of pain or nystagmus </li>
                  </ul>
                  <li style="color: orange;">Risk:</li>
                    <ul>
                      <li style="color: orange;">Hallucination </li>
                      <li style="color: orange;">Spontaneous movement</li>
                      <li style="color: orange;">Emergence phenomenon</li>
                      <li style="color: orange;">Hyper-salivation Laryngospasm (rare)</li>
                      <li style="color: orange;">Resp depression if opiates recently administered </li>
                      <li>Be aware of and prepared for side effects</li>
                    </ul>

                    <li>Notes</li>
                      <ul>
                        <li>Casualties must be disarmed</li>
                        <li>Reduce dose in severe shock or if opiates administered</li>
                        <li>Esketamine dose: </li>
                          <ul>
                            <li>0.5 to 1mg/kg for RSI then 0.25 to 05mg every 15min for RSI Induction</li>
                            <li>0.25 - 0.5 mg/kg for analgesia</li>
                          </ul>
                      </ul>
              </ul>

              <h2>Nerve Block</h2>
                  <ul>
                    <li>Lidocaine 1%: 10mg/ml to max 200mg (Fast onset – short duration) </li>
                    <li>Levobupivicaine: 0.25 - 150mg  (2.5mg/ml) to max dose 400mg in 24hrs (Slow onset – long duration) </li>
                    <li style="color: orange;">Risk: IV or IO injection of local anaesthetic causing toxicity </li>
                        <ul>
                          <li>Draw back on syringe checking for blood before injection</li>
                        </ul>
                  </ul>

              <h2>Procedural Sedation </h2>
                  <ul>
                    <li>Midazolam: 2mg IV/IO over 1 min followed by 0.5 to 1mg increments after 5 min to max 4mg </li>
                    <li>PLUS</li>
                    <li>Ketamine: 20mg over 1 min followed by 20mg increments until nystagmus occurs to 
                      Max dose of 100mg</li>
                      <li style="color: orange;">Risk: Ketamine & Midazolam in combination may cause apnoea</li>
                        <ul>
                          <li>Be aware of and prepared for Apnoea</li>
                        </ul>
                      
                  </ul>

                  <iframe src="https://player.vimeo.com/video/915130473?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="F - Analgesia"></iframe>

  </app-lg-info>

</div>
