<!-- GOAL RISK BOX -->
<div class="boxFlex" >
  <div class="Goal-box">

      <div class="center">
          <p><ng-content></ng-content></p>
      </div>

  </div>
</div>


