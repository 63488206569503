<div class="container">
  <app-lightgreen-box name="ExposureTreatment">E Treatment</app-lightgreen-box>

  <app-lg-info>

    <h1>Treatment Considerations </h1>

    <ul>
        <li>Treatment will depend on: </li>
            <ul>
                <li>Assessment</li>
                <li>Pathophysiology</li>
                <li>Casualty physiology</li>
                <li>Risk Benefit analysis</li>
            </ul>
    </ul>

    <H2>Risk Benefit Analysis</H2>
    <ul>
      <li>Risk of no treatment</li>
      <li>Risk of specific intervention</li>
      <li>Ability to offset risks</li>
      <li>Experience</li>
      <li>Help</li>
    </ul>

    <hr>

<h1>Treatment Options for E</h1>

    <h2>See below for specific treatment for:</h2>
    <ul>
      <li>Hypothermia</li>
      <li>Hyperthermia</li>
      <li>Altitude</li>
      <li>Diving </li>
      <li>Burns</li>
      <li>Toxins</li>

    </ul>

    <iframe src="https://player.vimeo.com/video/887229789?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="E - Treatment"></iframe>

  </app-lg-info>
</div>
