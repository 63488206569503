import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dis-indications',
  templateUrl: './dis-indications.component.html',
  styleUrls: ['./dis-indications.component.css']
})
export class DisIndicationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
