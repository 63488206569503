<div class="container">
  <app-lightgreen-box name="ExposureToxins">TOXINS</app-lightgreen-box>

  <app-lg-info>

    <h1>Types</h1>
      <ul>
        <li>Chemical</li>
        <ul>
          <li>Weaponised Chemical Agents</li>
          <li>Drugs</li>
          <li>Toxic Industrial Chemicals</li>
        </ul>
        <li>Biological</li>
          <ul>
            <li>Infectious disease</li>
            <li>Poisons</li>
            <li>Venoms</li>
          </ul>
        <li>Radiological</li>
          <ul>
            <li>Ionising radiation poisoning</li>
          </ul>
        <li>Nuclear</li>
          <ul>
            <li>Blast</li>
            <li>Burns</li>
            <li>Radiation poisoning </li>
          </ul>
      </ul>

      <hr>

      <h1>Incident recognition</h1>
        <ul>
          <li>Known threat - Chemical agent monitor alarm</li>
          <li>Unknown threat - 1.2.3 Rule</li>
            <ul>
              <li>1 Cas - No action</li>
              <li>2 Cas - Consider Toxins</li>
              <li>3 Cas - Toxic emergency / Raise alarm</li>
            </ul>
            <li>OR</li>
            <li>Medics show symptoms</li>
        </ul>

          <hr>

      <h1>IA Drill</h1>
      <h2>Safety</h2>
        <ul>
          <li>Tactics: Neutralise ballistic threat </li>
          <li>Self: PPE – Rapidly don CBRN Suit with Respiratory protection </li>
          <li>Scene: Safe area – Move survivors to a safe area for treatment </li>
          <li>Casualty: Med Immediate Action: Care Under Fires / Casualty PPE / Antidote  </li>
        </ul>
      <h2>Assess</h2>
        <ul>
          <li>The scene and make a plan </li>
          <li>Consider the location of the clean dirty line</li>
          <li>Identify the cause and prevent further contamination and cross contamination </li>
          <li>Move survivors – upwind / upstream / away from incident / into cover </li>
          <li>If agent is known ready medical countermeasures</li>
        </ul>
      <h2>Comms</h2>
        <ul>
          <li>Inform Team / Command / Evac – 9 liner</li>
          <li>Inform local authorities and receiving facility </li>
          <li>Type of Incident / Hazards / Cas numbers</li>
          <li>Requirements </li>
        </ul>
      <h2>Triage</h2>
        <ul>
          <li>Triage contaminated casualties using standard categories</li>
            <ul>
              <li>P1 Not talking not walking – Obvious life threating condition</li>
              <li>P2 Talking not walking </li>
              <li>P3 Walking & Talking </li>
            </ul>
          <li>Decontaminate / Critical Treatment / Antidote if known and not yet given </li>
          <li>Cross Clean Dirty Line </li>
          <li>Evacuate </li>
        </ul>

        <hr>

      <h1>Primary Survey</h1>
      <h2>X</h2>
        <ul>
          <li>Bleeding & Antidote take priority </li>
        </ul>
      <h2>A</h2>
        <ul>
          <li>Advanced airway requirement for Tox P1 casualties is high  </li>
            <ul>
              <li>Use iGel</li>
            </ul>
        </ul>
      <h2>B</h2>
        <ul>
          <li>Ventilation requirement is likely for Tox P1 </li>
            <ul>
              <li>Use CBRN canister on BVM</li>
              <li>Give oxygen - Except for Paraquat</li>
            </ul>
        </ul>
      <h2>C</h2>
        <ul>
          <li>Respiratory and circulatory arrest likely </li>
            <ul>
              <li>Administer antidote </li>
              <li>Consider futility of CPR/ALS in Mass Cas</li>
            </ul>
        </ul>
      <h2>D</h2>
        <ul>
          <li>Level of responsiveness is key triage tool – Control seizures</li>
        </ul>
      <h2>E</h2>
        <ul>
          <li>Try to identify causative agent </li>
        </ul>


        <h2 style="color: orange;">Risk</h2>
          <ul>
            <li style="color: orange;">Contamination of Medics and Evac platform</li>
              <ul>
                <li>Do not enter Hot or Warm zone without PPE</li>
                <li>Do not evac cas unless decontaminated</li>
              </ul>
            <li style="color: orange;">Misidentification of Agent & Antidote </li>
              <ul>
                <li>Use caution in symptom based identification</li>
              </ul>
            <li style="color: orange;">Degradation in PPE</li>
              <ul>
                <li>Rest and Rehydrate / Check medics for heat injury</li>
              </ul>
          </ul>

          <iframe src="https://player.vimeo.com/video/908187684?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="E- Toxins"></iframe>

  </app-lg-info>

  <app-lightgreen-box name="ExpNerveAgent"><a routerLink="/ExpNerveAgent">Nerve Agent</a></app-lightgreen-box>
  <app-lightgreen-box name="ExpCyanide"><a routerLink="/ExpCyanide">Cyanide</a></app-lightgreen-box>
  <app-lightgreen-box name="Opiates"><a routerLink="/ExpOpiates">Opiates</a></app-lightgreen-box>
</div>
