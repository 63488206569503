
<app-white-box class="header" name="CommsEvacuation">Evacuation</app-white-box>

<app-white-box-info>

  <!-- Copy and paste as necessary  -->

    <h1 >9 Liner</h1>

      <h2>Line 1</h2>

        <ul>
            <li>Location of pick up site</li>
        </ul>

      <h2>Line 2</h2>
      <ul>
        <li>Radio frequency & Call sign</li>
      </ul>

      <H2>Line 3</H2>
      <ul>
        <li>No of patients by procedure</li>
        <ul>
          <li>A: Urgent</li>
          <li>B: Urgent Surgical</li>
          <li>C: Priority</li>
          <li>D: Routine</li>
          <li>E: Convenience</li>
        </ul>
      </ul>

      <h2>Line 4</h2>
        <ul>
          <li>Special equipment required</li>
            <ul>
              <li>A: None</li>
              <li>B: Hoist</li>
              <li>C: Extraction</li>
              <li>D: Ventilator</li>
            </ul>
        </ul>

        <h2>Line 5</h2>
          <ul>
            <li>Number of patients by mobility</li>
              <ul>
                <li>A: Stretcher</li>
                <li>B: Ambulatory</li>
              </ul>
          </ul>

        <h2>Line 6</h2>
        <ul>
          <li>Security at pick up site</li>
            <ul>
              <li>N: None</li>
              <li>P: Possible</li>
              <li>E: Enemy</li>
              <li>X: Engaged</li>
            </ul>
        </ul>

          <h2>Line 7</h2>
            <ul>
              <li>Method of marking pick up site</li>
                <ul>
                  <li>A: Panels</li>
                  <li>B: Pyro</li>
                  <li>C: Smoke</li>
                  <li>D: None</li>
                  <li>E: Other</li>
                </ul>
            </ul>

              <h2>Line 8</h2>
                <ul>
                  <li>Patient status & Nationality</li>
                    <ul>
                      <li>A: US/Coalition Military</li>
                      <li>B: US/Coalition Civilian</li>
                      <li>C: Non US/Coalition Military</li>
                      <li>D: Non US/Coalitation Civilian</li>
                      <li>E: POW</li>
                      <li>F: High Value Target</li>
                    </ul>
                </ul>

                <h2>Line 9</h2>
                  <ul>
                    <li>NBC Contamination</li>
                      <ul>
                        <li>N: Nuclear</li>
                        <li>B: Biological</li>
                        <li>C: Chemical</li>
                      </ul>
                  </ul>
                  <hr>

            <h1>ATMIST</h1>
                  <ul>
                    <li>Age</li>
                    <li>Time of Injury</li>
                    <li>MOI</li>
                    <li>Injuries</li>
                    <li>Signs - Vitals</li>
                    <li>Treatment</li>
                  </ul>
</app-white-box-info>
