<div class="container">
  <app-lightgreen-box name="CirculationTreatment">C Treatment</app-lightgreen-box>

  <app-lg-info>

    <h1>Treatment Considerations </h1>


    <ul>
      <li>Treatment will depend on: </li>
          <ul>
              <li>Assessment</li>
              <li>Pathophysiology</li>
              <li>Casualty physiology</li>
              <li>Risk Benefit Analysis</li>
          </ul>
  </ul>

          <H2>Risk Benefit Analysis</H2>
          <ul>
            <li>Risk of no treatment</li>
            <li>Risk of specific intervention</li>
            <li>Ability to offset risks</li>
            <li>Experience</li>
            <li>Help</li>
          </ul>

          <hr>

<h1>Treatment Options for C</h1>

          <h2>For Hem Shock use SMART</h2>
          <ul>
            <li>Start the Clock</li>
            <li>Stop Bleeding</li>
            <li>Maintain Critical Perfusion</li>
            <li>Administer TXA</li>
            <li>Retain Heat</li>
            <li>Titrate Blood Products</li>
            <li>Think Alternative Causes</li>
          </ul>

          <iframe src="https://player.vimeo.com/video/836121059?h=a74643598d" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
  </app-lg-info>
</div>
