<div class="container">
    <div class="header">
        <img src="../../assets/logo.png" alt="">
        <h1>Disclaimer</h1>
    </div>
    
    <div class="text">
        <p>The information including demonstration videos are intended as a guide for trained medical personnel. All medical professionals should adhere to the scope of practise set by local clinical governance authority and registration bodies. All personnel should follow Standard Operating Procedures detailed by the medical chain of command. Read and adhere to the medical equipment manufacturer’s instructions for use and realise that improvisation while sometimes necessary carries with it increased risks to the patient. This information is a guide only intended to stimulate discussion and assist in training not as a replacement for sound clinical judgement. If you are unsure of what needs to be done establish communication with senior medical officials in a telemedicine consult.
            <br><br> Remember the golden rule of medicine is “First do no harm.” 

           <br> <br> P Thompson <br> 
            ATEM Ltd.</p>
    </div>
    <div class="input-box">
        <input type="checkbox" name="accept" #checkbox required [(ngModel)]= "cheese" >
        <label for="accept">Please tick if you accept</label>
      </div>
    <div class="buttons">
        <button routerLink="/" >Cancel</button>
        <button [disabled]="!cheese" routerLink="/signUp" >Proceed</button>
    </div>
    
</div>
