import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circ-administer',
  templateUrl: './circ-administer.component.html',
  styleUrls: ['./circ-administer.component.css']
})
export class CircAdministerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
