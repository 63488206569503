import { NgModule } from "@angular/core";
import { RouterModule, Routes} from '@angular/router';
import { AirwayAssessComponent } from "./AIRWAY/airway-assess/airway-assess.component";
import { AirwayGoalComponent } from "./AIRWAY/airway-goal/airway-goal.component";
import { AirwayReAssessComponent } from "./AIRWAY/airway-re-assess/airway-re-assess.component";
import { AirwayTreatmentComponent } from "./AIRWAY/airway-treatment/airway-treatment.component";
import { EGAComponent } from "./AIRWAY/ega/ega.component";
import { InspectClearComponent } from "./AIRWAY/inspect-clear/inspect-clear.component";
import { IntubationComponent } from "./AIRWAY/intubation/intubation.component";
import { MaintainComponent } from "./AIRWAY/maintain/maintain.component";
import { OpenComponent } from "./AIRWAY/open/open.component";
import { PosturalDrainageComponent } from "./AIRWAY/postural-drainage/postural-drainage.component";
import { ProtectComponent } from "./AIRWAY/protect/protect.component";
import { SurgicalCricComponent } from "./AIRWAY/surgical-cric/surgical-cric.component";
import { BreathingAssessComponent } from "./BREATHING/breathing-assess/breathing-assess.component";
import { BreathingChestDrainComponent } from "./BREATHING/breathing-chest-drain/breathing-chest-drain.component";
import { BreathingGoalComponent } from "./BREATHING/breathing-goal/breathing-goal.component";
import { BreathingReAssessComponent } from "./BREATHING/breathing-re-assess/breathing-re-assess.component";
import { BreathingTreatmentComponent } from "./BREATHING/breathing-treatment/breathing-treatment.component";
import { BreathingVentilateComponent } from "./BREATHING/breathing-ventilate/breathing-ventilate.component";
import { ChestSealComponent } from "./BREATHING/chest-seal/chest-seal.component";
import { DecompressComponent } from "./BREATHING/decompress/decompress.component";
import { CirStartComponent } from "./CIRCULATION/cir-start/cir-start.component";
import { CircAdministerComponent } from "./CIRCULATION/circ-administer/circ-administer.component";
import { CircAssesComponent } from "./CIRCULATION/circ-asses/circ-asses.component";
import { CircGoalComponent } from "./CIRCULATION/circ-goal/circ-goal.component";
import { CircMaintainComponent } from "./CIRCULATION/circ-maintain/circ-maintain.component";
import { CircReAssessComponent } from "./CIRCULATION/circ-re-assess/circ-re-assess.component";
import { CircRetainComponent } from "./CIRCULATION/circ-retain/circ-retain.component";
import { CircStopComponent } from "./CIRCULATION/circ-stop/circ-stop.component";
import { CircThinkComponent } from "./CIRCULATION/circ-think/circ-think.component";
import { CircTitrateComponent } from "./CIRCULATION/circ-titrate/circ-titrate.component";
import { CircTreatmentComponent } from "./CIRCULATION/circ-treatment/circ-treatment.component";
import { DisAssessComponent } from "./DISABILITY/dis-assess/dis-assess.component";
import { DisGoalComponent } from "./DISABILITY/dis-goal/dis-goal.component";
import { DisHighICPComponent } from "./DISABILITY/dis-high-icp/dis-high-icp.component";
import { DisHyperventilationComponent } from "./DISABILITY/dis-hyperventilation/dis-hyperventilation.component";
import { DisHypoglycaemiaComponent } from "./DISABILITY/dis-hypoglycaemia/dis-hypoglycaemia.component";
import { DisHypotensionComponent } from "./DISABILITY/dis-hypotension/dis-hypotension.component";
import { DisHypoxiaComponent } from "./DISABILITY/dis-hypoxia/dis-hypoxia.component";
import { DisIndicationsComponent } from "./DISABILITY/dis-indications/dis-indications.component";
import { DisRBAComponent } from "./DISABILITY/dis-rba/dis-rba.component";
import { DisReAssesComponent } from "./DISABILITY/dis-re-asses/dis-re-asses.component";
import { DisclaimerComponent } from "./disclaimer/disclaimer.component";
import { ExpAltitudeComponent } from "./EXPOSURE/exp-altitude/exp-altitude.component";
import { ExpAssessComponent } from "./EXPOSURE/exp-assess/exp-assess.component";
import { ExpBurnsComponent } from "./EXPOSURE/exp-burns/exp-burns.component";
import { ExpDivingComponent } from "./EXPOSURE/exp-diving/exp-diving.component";
import { ExpGoalComponent } from "./EXPOSURE/exp-goal/exp-goal.component";
import { ExpHyperthermiaComponent } from "./EXPOSURE/exp-hyperthermia/exp-hyperthermia.component";
import { ExpHypothermiaComponent } from "./EXPOSURE/exp-hypothermia/exp-hypothermia.component";
import { ExpReassessComponent } from "./EXPOSURE/exp-reassess/exp-reassess.component";
import { ExpToxinsComponent } from "./EXPOSURE/exp-toxins/exp-toxins.component";
import { ExpNerveAgentComponent } from "./EXPOSURE/exp-nerve-agent/exp-nerve-agent.component";
import { ExpCyanideComponent } from "./EXPOSURE/exp-cyanide/exp-cyanide.component";
import { ExpOpiatesComponent } from "./EXPOSURE/exp-opiates/exp-opiates.component";
import { ExpTreatmentComponent } from "./EXPOSURE/exp-treatment/exp-treatment.component";
import { AAJTComponent } from "./EXSANGUINATION/aajt/aajt.component";
import { CatHemReassessComponent } from "./EXSANGUINATION/cat-hem-reassess/cat-hem-reassess.component";
import { CatHemTreatmentComponent } from "./EXSANGUINATION/cat-hem-treatment/cat-hem-treatment.component";
import { CathemAssessComponent } from "./EXSANGUINATION/cathem-assess/cathem-assess.component";
import { EXFoleyComponent } from "./EXSANGUINATION/ex-foley/ex-foley.component";
import { ExProxComponent } from "./EXSANGUINATION/ex-prox/ex-prox.component";
import { ITClampComponent } from "./EXSANGUINATION/i-tclamp/i-tclamp.component";
import { JunctionalTQComponent } from "./EXSANGUINATION/junctional-tq/junctional-tq.component";
import { LimbTQComponent } from "./EXSANGUINATION/limb-tq/limb-tq.component";
import { PackingComponent } from "./EXSANGUINATION/packing/packing.component";
import { XGoalComponent } from "./EXSANGUINATION/xgoal/xgoal.component";
import { FurAnalgesiaComponent } from "./FURTHER TREATMENT/fur-analgesia/fur-analgesia.component";
import { FurAntibioticsComponent } from "./FURTHER TREATMENT/fur-antibiotics/fur-antibiotics.component";
import { FurHandoverComponent } from "./FURTHER TREATMENT/fur-handover/fur-handover.component";
import { FurHistoryComponent } from "./FURTHER TREATMENT/fur-history/fur-history.component";
import { FurPackageComponent } from "./FURTHER TREATMENT/fur-package/fur-package.component";
import { FurReassessComponent } from "./FURTHER TREATMENT/fur-reassess/fur-reassess.component";
import { FurSecondarySurveyComponent } from "./FURTHER TREATMENT/fur-secondary-survey/fur-secondary-survey.component";
import { HomeComponent } from "./home/home.component";
import { InfoPageComponent } from "./info-page/info-page.component";
import { PdfComponent } from "./pdf/pdf.component";
import { AssSceneComponent } from "./Pre-Primary/ASSESS/ass-scene/ass-scene.component";
import { CommsCasualtyComponent } from "./Pre-Primary/COMMS/comms-casualty/comms-casualty.component";
import { CommsEvacuationComponent } from "./Pre-Primary/COMMS/comms-evacuation/comms-evacuation.component";
import { CommsTeamComponent } from "./Pre-Primary/COMMS/comms-team/comms-team.component";
import { PrePrimaryGoalComponent } from "./Pre-Primary/pre-primary-goal/pre-primary-goal.component";
import { SafetyCasualtyComponent } from "./Pre-Primary/SAFETY/safety-casualty/safety-casualty.component";
import { SafetySceneComponent } from "./Pre-Primary/SAFETY/safety-scene/safety-scene.component";
import { SafetySelfComponent } from "./Pre-Primary/SAFETY/safety-self/safety-self.component";
import { TacticsRouteComponent } from "./Pre-Primary/SAFETY/safety-tactics/tactics-route.component";
import { TriageCasualtyComponent } from "./Pre-Primary/TRIAGE/triage-casualty/triage-casualty.component";
import { TriageEvacComponent } from "./Pre-Primary/TRIAGE/triage-evac/triage-evac.component";
import { TriageSearchComponent } from "./Pre-Primary/TRIAGE/triage-search/triage-search.component";
import { PrimaryGoalComponent } from "./Primary-Survey/primary-goal/primary-goal.component";
import { ScenarioComponent } from "./scenario/scenario.component";
import { SettingsComponent } from "./settings/settings.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { CircTractFracturesComponent } from "./CIRCULATION/circ-tract-fractures/circ-tract-fractures.component";
import { CircPelvicBinderComponent } from "./CIRCULATION/circ-pelvic-binder/circ-pelvic-binder.component";
import { CircIvAccessComponent } from "./CIRCULATION/circ-iv-access/circ-iv-access.component";
import { CircIoAccessComponent } from "./CIRCULATION/circ-io-access/circ-io-access.component";
import { CircReconPlasmaComponent } from "./CIRCULATION/circ-recon-plasma/circ-recon-plasma.component";
import { CircWholeBloodTransfusionComponent } from "./CIRCULATION/circ-whole-blood-transfusion/circ-whole-blood-transfusion.component";
import { CircEmergencyDonorComponent } from "./CIRCULATION/circ-emergency-donor/circ-emergency-donor.component";
import { CircWholeBloodDonationComponent } from "./CIRCULATION/circ-whole-blood-donation/circ-whole-blood-donation.component";
import { DisCbtCasComponent } from "./DISABILITY/dis-cbt-cas/dis-cbt-cas.component";
import { DisTBIComponent } from "./DISABILITY/dis-tbi/dis-tbi.component";
import { DisIBAComponent } from "./DISABILITY/dis-iba/dis-iba.component";
import { DisLowerLOCComponent } from "./DISABILITY/dis-lower-loc/dis-lower-loc.component";
import { DisSpinalInjuryComponent } from "./DISABILITY/dis-spinal-injury/dis-spinal-injury.component";
import { DisSeizuresComponent } from "./DISABILITY/dis-seizures/dis-seizures.component";
import { DisMildTbiComponent } from "./DISABILITY/dis-mild-tbi/dis-mild-tbi.component";
import { PasswordResetComponent } from "./password-reset/password-reset.component";

const appRoutes: Routes =[
// Login Page

// Home screen

{path: '', component: HomeComponent },

// Info page

{path: 'infoPage', component: InfoPageComponent},

//Pre-Primary Routes

{path: 'Pre-PrimaryGoal', component: PrePrimaryGoalComponent },

    //SAFETY

    {path: 'TacticsPage', component: TacticsRouteComponent},
    {path: 'SafetySelf', component: SafetySelfComponent},
    {path: 'SafetyScene', component: SafetySceneComponent},
    {path: 'SafetyCasualty', component: SafetyCasualtyComponent},

    //ASSESS

    {path: 'AssessScene', component: AssSceneComponent},

    //COMMS

    {path: 'CommsTeam', component: CommsTeamComponent},
    {path: 'CommsEvacuation', component: CommsEvacuationComponent},
    {path: 'CommsCasualty', component: CommsCasualtyComponent},

    //TRIAGE

    {path: 'TriageSearch', component: TriageSearchComponent},
    {path: 'TriageCasualty', component: TriageCasualtyComponent},
    {path: 'TriageEvacuation', component: TriageEvacComponent},

//Primary Survey Routes

{path: 'Primary-goal', component: PrimaryGoalComponent },

    // Xanguination
    
    {path: 'LimbTQ', component: LimbTQComponent},
    {path: 'ExProx', component: ExProxComponent},
    {path: 'Packing', component: PackingComponent},
    {path: 'itclamp', component: ITClampComponent},
    {path: 'CatHemAssess', component: CathemAssessComponent},
    {path: 'CatHemReAssess', component: CatHemReassessComponent},
    {path: 'JunctionalTQ', component: JunctionalTQComponent},
    {path: 'Foley', component: EXFoleyComponent},
    {path: 'AAJT', component: AAJTComponent},
    {path: 'CatHemTreatment', component: CatHemTreatmentComponent},
    {path: 'XGoals', component: XGoalComponent},

    // Airway

    {path: 'AirwayTreatment', component: AirwayTreatmentComponent},
    {path: 'AirwayAssess', component: AirwayAssessComponent},
    {path: 'AirwayReAssess', component: AirwayReAssessComponent},
    {path: 'AirwayPosturalDrainage', component: PosturalDrainageComponent},
    {path: 'AirwayInspectClear', component: InspectClearComponent},
    {path: 'AirwayOpen', component: OpenComponent},
    {path: 'AirwayMaintain', component: MaintainComponent},
    {path: 'AirwayProtect', component: ProtectComponent},
    {path: 'AirwaySurgicalCric', component: SurgicalCricComponent},
    {path: 'AirwayEGA', component: EGAComponent},
    {path: 'AirwayIntubation', component: IntubationComponent},
    {path: 'AirwayGoals', component: AirwayGoalComponent},

    //Breathing

    {path: 'BreathingAssess', component: BreathingAssessComponent},
    {path: 'BreathingReAssess', component: BreathingReAssessComponent},
    {path: 'BreathingTreatment', component: BreathingTreatmentComponent},
    {path: 'BreathingChestSeal', component: ChestSealComponent},
    {path: 'BreathingDecompress', component: DecompressComponent},
    {path: 'BreathingChestDrain', component: BreathingChestDrainComponent},
    {path: 'BreathingVentilate', component: BreathingVentilateComponent},
    {path: 'BreathingGoals', component: BreathingGoalComponent},

    //Circulation

    {path: 'CircAssess', component: CircAssesComponent},
    {path: 'CircReAssess', component: CircReAssessComponent},
    {path: 'CircTreatment', component: CircTreatmentComponent},
    {path: 'CircStart', component: CirStartComponent},
    {path: 'CircStop', component: CircStopComponent},
    {path: 'CircMaintain', component: CircMaintainComponent},
    {path: 'CircAdminister', component: CircAdministerComponent},
    {path: 'CircRetain', component: CircRetainComponent},
    {path: 'CircTitrate', component: CircTitrateComponent},
    {path: 'CircThink', component: CircThinkComponent},
    {path: 'CircGoals', component: CircGoalComponent},
    {path: 'tractfractures', component: CircTractFracturesComponent},
    {path: 'CircPelvicBinding', component: CircPelvicBinderComponent},
    {path: 'CircIvAccess', component: CircIvAccessComponent},
    {path: 'CircIoAccess', component: CircIoAccessComponent},
    {path: 'CircReconPlasma', component: CircReconPlasmaComponent},
    {path: 'CircWholeBloodTransfusion', component: CircWholeBloodTransfusionComponent},
    {path: 'CircEmergencyDonor', component: CircEmergencyDonorComponent},
    {path: 'CircWholeBloodDonation', component: CircWholeBloodDonationComponent},

    //Disability

    {path: 'DisAssess', component: DisAssessComponent},
    {path: 'DisReAssess', component: DisReAssesComponent},
    {path: 'DisRBA', component: DisRBAComponent},
    {path: 'DisIndications', component: DisIndicationsComponent},
    {path: 'DisHyperventilation', component: DisHyperventilationComponent},
    {path: 'DisHypoxia', component: DisHypoxiaComponent},
    {path: 'DisHypotension', component: DisHypotensionComponent},
    {path: 'DisHighICP', component: DisHighICPComponent},
    {path: 'DisAssess', component: DisAssessComponent},
    {path: 'DisHypoglyc', component: DisHypoglycaemiaComponent},
    {path: 'DisGoals', component: DisGoalComponent},
    {path: 'DisCBTCas', component: DisCbtCasComponent},
    {path: 'DisTBI', component: DisTBIComponent},
    {path: 'DisIBA', component: DisIBAComponent},
    {path: 'DisLowerLOC', component: DisLowerLOCComponent},
    {path: 'DisSpinalInjury', component: DisSpinalInjuryComponent},
    {path: 'DisSeizures', component: DisSeizuresComponent},
    {path: 'DisMildTBI', component: DisMildTbiComponent},

    //Exposure
    {path: 'ExpAssess', component: ExpAssessComponent},
    {path: 'ExpReAssess', component: ExpReassessComponent},
    {path: 'ExpTreatment', component: ExpTreatmentComponent},
    {path: 'ExpHypothermia', component: ExpHypothermiaComponent},
    {path: 'ExpHyperthermia', component: ExpHyperthermiaComponent},
    {path: 'ExpBurns', component: ExpBurnsComponent},
    {path: 'ExpToxins', component: ExpToxinsComponent},
    {path: 'ExpNerveAgent', component: ExpNerveAgentComponent},
    {path: 'ExpCyanide', component: ExpCyanideComponent},
    {path: 'ExpOpiates', component: ExpOpiatesComponent},
    {path: 'ExpGoals', component: ExpGoalComponent},
    {path: 'ExpDiving', component: ExpDivingComponent},
    {path: 'ExpAltitude', component: ExpAltitudeComponent},

    //Further Treatment
    {path: 'FurSecond', component: FurSecondarySurveyComponent},
    {path: 'FurReAssess', component: FurReassessComponent},
    {path: 'FurPackage', component: FurPackageComponent},
    {path: 'FurHistory', component: FurHistoryComponent},
    {path: 'FurAnalgesia', component: FurAnalgesiaComponent},
    {path: 'FurAntibiotics', component: FurAntibioticsComponent},
    {path: 'FurHandover', component: FurHandoverComponent},

    // PDF
    {path: 'pdf', component: PdfComponent},

    // ACC DRILL Form
    {path: 'form', component: ScenarioComponent},

    // Settings Page
    {path: 'settings', component: SettingsComponent},

     // Settings Page
     {path: 'disclaimer', component: DisclaimerComponent},

     // SignUp Page
     {path: 'signUp', component: SignUpComponent},

     // Password Reset
     {path: 'PasswordReset', component: PasswordResetComponent},

  ]
@NgModule ({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule]
})
export class AppRouterModule {

}
