<div class="container">
  <app-lightgreen-box name="BreathingChestDrain">FDC & Chest Drain</app-lightgreen-box>

  <app-lg-info>
    <h1>Indications</h1>
    
        <ul>
          <li>Finger decompression for Tension Pneumothorax </li>  
          <li>Chest drain is definitive treatment for Simple and Tension Pneumothorax & Haemothorax</li>
            <li>Signs & Symptoms of a TPX</li>
            <ul>
                <li>MOI: Penetrating torso or neck injury / GSW on limbs close to torso attachment / Blunt chest trauma</li>
                <li>Chest pain</li>
                <li>Increasing breathing rate</li>
                <li>Increasing breathing difficulty leading to respiratory arrest</li>
                <li>Decreasing SPO2</li>
                <li>Shock in the absence of bleeding or compounding Hem Shock</li>
                <li>Decreased movement or breath sounds on the side that has the tension</li>
                <li>Respiratory or cardiac arrest</li>
                <li>Other S&S: Subcutaneous emphysema, Tracheal deviation, Distended neck veins, Cyanosis, Hyperresonance on percussion</li>
                <li>E-FAST: Loss of lung sliding / Loss of Comet of tails (B lines) / M mode - Barcode sign</li>
            </ul>
        </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit </li>
                  <ul>
                    <li>PPE - Gloves & Eye pro</li>
                    <li>Local anaesthetic (Lidocaine 1% 3mg/kg), 22G needle & 10ml syringe</li>
                    <li>Finger Deco: Skin disinfection solution, Surgical gloves, Forceps, Scalpel (No 10)</li>
                    <li>Chest drain: Chest tube, valve, collection bag, suture kit, gauze, chest seal </li>
                    <li>Check one-way valve functionality</li>
                  </ul>
                <li>Cas </li>
                  <ul>
                    <li>Identify the side of the tension </li>
                    <li>Position for access to 5th Intercostal space - Mid axillary line</li>
                    <li>Palpate space between ribs and mark incision line</li>
                    <li>Inject local anaesthetic into incision area in a conscious casualty</li>
                    <li>Disinfect the area</li>
                    <li>Drape if possible</li>
                  </ul>
            </ul>
        
    <h1>Procedure</h1>

<h2>Finger Decompression - Ventilated Cas Only </h2>
            
            <ul>
                <li>1 Make an incision through the subcutaneous tissue</li>
                <li>2 Blunt dissect through the intercostal muscles</li>
                <li>3 Palpate the lung and perform a finger sweep in the pleural space</li>
                <li>4 Lung down - correct diagnosis / Lung up - incorrect diagnosis</li>
                <li>5 Remove finger and allow air to escape</li>

            </ul>

        <h2>Chest Drain</h2>

            <ul>
                <li>1 Perform a Finger Decompression / leave the finger in place with spontaneously breathing casualty</li>
                <li>2 Advance the chest tube along the finger into the pleural space to depth of 10cm</li>
                <li>3 Aim anterior for pneumothorax (opposite shoulder) and inferior for haemothorax</li>
                <li>4 Connect tube to one way valve and drainage bag</li>
                <li>5 Suture tube in place ensuring correct depth</li>
                <li>6 Consider placing chest seal over tube to ensure seal</li>


            </ul>

    <h1>Post Procedure</h1>    
    
        <h2>Check</h2>
        
            <ul>
                <li>Finger decompression incision may block and need reopening</li>
                <li>Chest drain tube is at correct depth</li>
                <li>Note the amount of blood drained from a  haemothorax</li>
            </ul>
        
        <h2>Secure</h2>
            <ul>
                <li>Attach distal end of tube to cas so first pull is not on the insertion site</li>
            </ul>

        <hr>
    
    <h1 style="color: orange">Risk</h1>
        <ul>

            <li style="color: orange;">Chest tube may become dislodged and slip out exposing openings</li>
                <ul>
                    <li>Mark correct depth on the tube and reassess regularly - especially after movement</li>
                </ul>
            
            <li style="color: orange;">The chest tube may not be in the pleural space</li>
                <ul>
                  <li>Slide tube along the finger into the pleural space</li>
                  <li>Check for improvement in casualty condition </li>
                </ul>
        </ul>
        <ul>

            <li style="color: orange;">Infection</li>
                <ul>
                    <li>Use surgical gloves and aseptic technique</li>
                    <li>Disinfect and drape the area</li>
                    <li>Consider antibiotics</li>
                </ul>
                
                <li style="color: orange;">One way valve becomes blocked</li>
                <ul>
                    <li>Reassess regularly </li>
                </ul>
        </ul>

    <hr>

        <h1>Notes</h1>
        <ul>
            <li>Use finger decompression for casualty in cardiac arrest</li>
            <li>Confirm chest tube placement with x-ray when available</li>
        </ul>

      <hr>

      <iframe src="https://player.vimeo.com/video/813534732?h=1ce6eb31ed" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

  </app-lg-info>
</div>
