<div class="container">
    <app-lightgreen-box name="BreathingDecompress">NDC (Needle Deco)</app-lightgreen-box>
   
    <app-lg-info>

    
  
        <h1>Indications</h1>
    
        <ul>
            <li>Tension Pneumothorax (TPX) Signs & Symptoms:</li>
            <ul>
                <li>MOI: Penetrating torso or neck wound / GSW on limbs close to torso attachment / Blunt chest trauma</li>
                <li>Chest pain</li>
                <li>Increasing breathing rate</li>
                <li>Increasing breathing difficulty leading to respiratory arrest</li>
                <li>Decreasing SPO2</li>
                <li>Shock in the absence of bleeding or compounding Hem Shock</li>
                <li>Decreased chest movement or breath sounds on the side that has the tension</li>
                <li>Respiratory or cardiac arrest</li>
                <li>Other S&S: Subcutaneous emphysema, Tracheal deviation, Distended neck veins, Cyanosis, Hyperresonance on percussion</li>
                <li>E-FAST: Loss of lung sliding / Loss of Comet of tails (B lines) / M mode - Barcode sign</li>
            </ul>
        </ul>

    <hr>
    
    <h1>Pre-Procedure</h1>

        <h2>Prep</h2>
            <ul>
                <li>Kit </li>
                    <ul>
                        <li>PPE - Gloves & Eye pro</li>
                        <li>83mm Needle catheter unit</li>
                        <li>Optional: Half-filled syringe of saline</li>
                    </ul>
                <li>Cas  </li>
                    <ul>
                        <li>Position for inspection and access to decompression site</li>
                        <li>2nd Intercostal Space - Midclavicular line</li>
                        <li>5th Intercostal Space - Anterior axillary line</li>
                    </ul>
            </ul>
        
    <h1>Procedure</h1>

        <h2>Locate</h2>
        
            <ul>
                <li>Identify the side of the tension and NDC location</li>
                <li>Palpate the space between ribs (depression)</li>
                <li>Be aware that the neurovascular bundle is located at the inferior and posterior of each rib</li>
            </ul>

<h2>No syringe technique </h2>
            
            <ul>
                <li>1 Insert needle perpendicular to the chest wall just above inferior rib</li>
                <li>2 Advance the needle through the chest wall</li>
                <li>3 No deeper than 1/3 of needle length</li>
                <li>4 Attempt to advance catheter only - NOT the needle - catheter will only advance in the pleural space </li>
                <li>5 If catheter will not advance insert 5mm deeper and attempt again</li>
                <li>6 Only insert more than half the needle on large individuals</li>
                <li>7 Once catheter advances - remove the needle </li>
            </ul>

        <h2>syringe technique</h2>

            <ul>
                <li>1 Insert needle with half filled syringe attached perpendicular to chest wall just above inferior ribs</li>
                <li>2 Advance needle through chest wall</li>
                <li>3 Push plunger in syringe to 'pop' any plug in the needle</li>
                <li>4 Advance the needle with negative pressure in the syringe </li>
                <li>5 Bubbles entering the syringe indicate air in the pleural space </li>
                <li>6 Remove Plunger and fluid will bubble as gas escapes </li>
                <li>7 At half of the needle length attempt to advance catheter</li>
                <li>8 Only advance the needle further than half in larger individuals</li>

            </ul>

    <h1>Post Procedure</h1>    
    
        <h2>Check</h2>
        
            <ul>
                <li>If blood leaks from needle or catheter remove the unit and mark area</li>
                <li>The patency of the cannula can be checked by small saline flush</li>
            </ul>
        
        <h2>Secure</h2>
            <ul>
                <li>Attempt to prevent kinking of the catheter or blockage of the opening</li>
            </ul>

        <hr>
    
    <h1 style="color: orange">Risk</h1>
        <ul>

            <li style="color: orange;">Needle can damage heart or great vessels</li>
                <ul>
                    <li>Be aware of and avoid the danger areas</li>
                    <li>Only insert the needle as far as the pleural space - NO FURTHER</li>
                    <Li>Be especially cautious when using the left lateral position</Li>
                </ul>
            
            <li style="color: orange;">Cas has a Haemothorax not Tension</li>
                <ul><li> If blood leaks from the needle or catheter remove and mark insertion </li></ul>
        </ul>

        <ul>

            <li style="color: orange;">Needle can cause a leak in the lung and a tension may develop</li>
                <ul>
                    <li>ALWAYS mark the site of insertion with NDC especially if the needle catheter is removed</li>
                    <li>Be aware of this risk especially if the casualty is positive pressure ventilated</li>
                </ul>
                
                <li style="color: orange;">Cannula may block or kink preventing the escape of gas and tension may develop</li>
                <ul>
                    <li>Check the cannula with a small flush and negative pressure </li>
                    <li>An additional NDC may be required</li>
                </ul>

                <li style="color: orange;">Infection</li>
                <ul>
                    <li>Prevent infection by Disinfection / Use sterile gloves / Avoid touching needle</li>
                </ul>
        </ul>

    <hr>

        <h1>Notes</h1>
        <ul>
            <li>Tension pneumothorax is less common in spontaneously breathing casualties</li>
            <li>Tension pneumothorax is more common in ventilated casualties</li>
        </ul>

        <iframe src="https://player.vimeo.com/video/816858104?h=82be7a47ae" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </app-lg-info>

</div>