import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatabaseService } from 'src/app/database.service';


@Component({
  selector: 'app-tick',
  templateUrl: './tick.component.html',
  styleUrls: ['./tick.component.css']
})
export class TickComponent implements OnInit {
@Input() name:string;
data;
blank:boolean;
tick:boolean;
cross:boolean;




// onClicked(){
//  console.log(this.data[this.name]);
//  this.databaseService.database[this.name].tick = true;
// };

onBlankClicked(){
 this.databaseService.database[this.name].blank=false; 
 this.blank=false;
 this.databaseService.database[this.name].tick=true; 
 this.tick=true;
 this.databaseService.database[this.name].time= new Date().toLocaleTimeString();
}

onTickClicked(){
  this.databaseService.database[this.name].tick=false; 
  this.tick=false;
  this.databaseService.database[this.name].cross=true; 
  this.cross=true;
 }
 
 onCrossClicked(){
  this.databaseService.database[this.name].cross=false; 
  this.cross=false;
  this.databaseService.database[this.name].blank=true; 
  this.blank=true;
  this.databaseService.database[this.name].time= '';
 }

  constructor(private databaseService:DatabaseService) { }

  ngOnInit(): void {
    this.data = this.databaseService.database;
    this.blank = this.data[this.name].blank;
    this.tick = this.data[this.name].tick;
    this.cross = this.data[this.name].cross;
  }

}
