<div class="container">
    <app-lightblue-box name="AirwayAssess">ASSESS</app-lightblue-box>
<app-lb-info>
    <h1>Goal</h1>
<ul>
    <li>Identify & Treat Life-threatening Airway Obstruction ensuring Airway is Inspected / Opened / Maintained / Protected </li></ul>

<hr>

    <h1>Risk</h1>

        <h2>Airway Obstruction from:</h2>

            <ul>
                <li> Decreasing Responsiveness</li>
                <li>Aspiration </li>
                <li>Foreign Object</li>
                <li>Swelling: Anaphylaxis / Burns</li>
                <li>Facial trauma</li>
            </ul>

        <h2>Consider C-Spine Injury if:</h2>

        <ul>        
            <li>MOI: IED dismounted or in vehicle / Heavy Parachute or Fast Rope landing / Fall from height</li>
            <li>RTA > 100km/h Rollover or Ejection / Axial loading - Any loss of sensation or motor function / Tingling</li>
            <li>Consider C-Spine - if likely apply Manual In-Line Stabilization (MILS) then immobilise as appropriate</li>
        </ul>

<hr>

        <h1>Consider:</h1>

        <h2>Pathophysiology</h2>
    
        <ul> 
            <li style="color: red;">Injury Severity</li>
            <li>Mechanism of Injury</li>
            <li>Injury Pattern</li>
            <li>Associated injuries</li>
        </ul>
    
        <h2>Casualty</h2>
        
        <ul>
          <li>Specific physiology</li>
          <li>Underlying pathology</li>
          <li>Medications</li>
          <li>Injury environment </li>
        </ul>

        <hr>

    <h1>Exam</h1>
    <h2>Talk</h2>

    <ul>
        <li>Talk to casualty</li>
        <p></p>
        <ul>
            <li>Check Response</li>
            <li>Introduction</li>
            <li>History</li>
            <li>Consent</li>
        </ul>
    </ul>
    
    
    <h2>Moving Air</h2>
    
    <ul>
        <li>If not talking</li>
            <ul>
                <li>Check air movement (Stethoscope)</li>
                <li>Look</li>
                <li>Listen</li>
                <li>Feel</li>
            </ul>
    </ul>

    <h2>Noise</h2>
    
    <ul>
        <li>Listen for any noise on air movement</li>
    </ul>
    
    <h2>Inspect Airway</h2>
    
    <ul>
        <li>Inspect inside mouth: Responsive ask to open mouth / Unresponsive use laryngoscope for:</li>
        <ul>
            <li>Foreign objects </li>
            <li>Fluids</li>
            <li>Burns</li>
        </ul>
    </ul>

    <h2>Inspect Face & Neck</h2>
    
    <ul>
        <li>Facial bones for fracture</li>
        <li>Neck for damage to larnyx </li>
    </ul>

    <hr>

    <h1>Measure</h1>

    <h2>Responsiveness - AVPU</h2>
    
    <ul>
        <li >Alert</li>
        
        <li >Voice</li>
        
        <li >Pain</li>
        
        <li >Unresponsive</li>
    </ul>

    <hr>

    <iframe src="https://player.vimeo.com/video/749929565?h=88a9129296&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="A - Assess"></iframe>

</app-lb-info>
</div>
