
<div class="container">
    <app-lightgreen-box name="ExposureDiving">DIVING</app-lightgreen-box>
  
    <app-lg-info>
        
        <h2>Diving Emergencies</h2>
        <ul>
            <li>Near drowning </li>
            <li>Breathing gas mixture toxicity </li>
            <li>Barotrauma and Arterial Gas Embolism </li>
            <li>Decompression Illness (DCI)</li>
        </ul>

        <h2>Symptoms</h2>
        <ul>
            <li>Near Drowning</li>
                <ul>
                    <li>Removed from water with no signs of life </li>
                </ul>

            <li>Gas Mixture Toxicity </li>
                <ul>
                    <li>Know the equipment and gas used for the dive</li>
                    <li>HYPOXIA - All gas mixtures and equipment have the risk of inadequate delivery of oxygen</li>
                        <ul>
                            <li>Loss of consciousness</li>
                        </ul>
                    <li>Hyperoxia - Pure oxygen military diving has the risk of cerebral oxygen toxicity </li>
                        <ul>
                            <li>Visual disturbance / Tremmors / Sizures</li>
                        </ul>
                    <li>Hypercapnia - Rebreathers may experience scrubber breakthrough</li>
                        <ul>
                            <li>Headache / Breathing distrubance / Loss of consciousness </li>   
                        </ul>
                    <li>CO Poisoning - Any diving mixture may be contaminated with CO</li>
                        <ul>Rapid loss of consciouness (Cherry red)</ul>
                    <li>Nitrogen - Air has a high nitrogen content causing DCI and Nitrogen narcosis</li>
                        <ul>
                            <li>Intoxication / Poor judgement </li>
                        </ul>
                </ul>

            <li>Barotrauma</li>
                <ul>
                    <li>Expanding gas on surfacing may cause barotrauma and Arterial Gas Embolism or Pneumothorax</li>
                </ul>

            <li>Arterial Gas Embolism (AGE) Rapid onset of: </li>
                <ul>
                    <li>Bloody Sputum </li>
                    <li>Dyspnoea</li>
                    <li>Neuro Symptoms</li>
                    <li>Collapse</li>
                </ul>

            <li>Decompression Illness (DCI) post dive:</li>
                <ul>
                    <li>Skin rash</li>
                    <li>Joint pain</li>
                    <li>Neuro symptoms</li>
                    <li>Dyspnoea</li>
                    <li>Collapse </li>
                </ul>

        </ul>
    
        <h2>IA Drill</h2>
        <ul>
            <li>Remove from water - Assess XABCDE</li>
            <li>Start CPR if no signs of life </li>
            <li>Give high flow O2 </li>
            <li>Transport to recompression chamber </li>
            <li>Telemedicine Consult with Diving Medical Officer</li>
            <li>Avoid: Exertion / Warming / Altitude</li>
        </ul>

        <h2>Treatment</h2>
        <ul>
            <li>Therapeutic recompression for DCI and AGE  </li>
        </ul>
    
        <h2 style="color: orange;">Risk</h2>
        <ul>
            <li style="color: orange;"> Missing non-specific symptoms </li>
                <ul>
                    <li>Have a high index of suspicion </li>
                </ul>
            <li style="color: orange;"> No access to recompression therapy</li>
                <ul>
                    <li>Have a plan to get to recompression chamber </li>
                </ul>
            <li style="color: orange;"> Always suspect HYPOXIA</li>
            <ul>
                 <li>Give oxygen </li>
            </ul>
           
        </ul>
        
        <iframe src="https://player.vimeo.com/video/901491953?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="E - Diving"></iframe>

        </app-lg-info>
      
      </div>
      
  
  