<div class="container">
    <app-lightblue-box name="DisabilityAssess">ASSESS</app-lightblue-box>

    <app-lb-info>
        
        <h1>Goal</h1>
        <ul>
            <li>Identify & Treat Life-threatening Neurological Injuries </li>
        </ul>
        <hr>

        <h1>Risk</h1>
        <ul>
            <li>Combative casualties</li>
            <li>TBI</li>
            <li>Impact Brain Apnoea</li>
            <li>Lowered level of consciousness</li>
            <li>Spinal Cord Injury </li>
            <li>Seizures </li>
            <li>Hypoglycaemia </li>
        </ul>
        <hr>

        <h1>Consider</h1>

        <h2>Pathophysiology</h2>
    
        <ul> 
            <li style="color: red;">Injury Severity</li>
            <li>Traumatic Brain Injury</li>
            <ul>
                <li>Mild GCS 15-13</li>
                <li>Moderate GCS 12-9</li>
                <li>Severe GCS < 9 </li>
            </ul>
            <li>Mechanism of Injury</li>
            <li>Injury Pattern</li>
            <li>Associated Injuries</li>
        </ul>
    
        <h2>Casualty</h2>
        
        <ul>
          <li>Specific physiology</li>
          <li>Underlying pathology</li>
          <li>Medications</li>
          <li>Injury environment </li>
        </ul>
        <hr>

        <h1>Exam</h1>
     <h2>Look</h2>
    <ul>
        <li>For any signs of injury on the head</li>
    </ul>
    
    <h2>Feel</h2>
    <ul>
        <li>For skull and facial fractures</li>
    </ul>
    
    
    <h2>Inspect</h2>
    <ul>
        <li>Ears & nose for fluid and blood</li>
    </ul>

    <h2>Listen</h2>
    <ul>
        <li>To incident history from cas / bystander</li>
    </ul>

    <h2>Neurological Exam</h2>
    <ul>
        <li>Conduct a full neurological exam ASAP</li>
    </ul>
    
    
    <hr>
    <h1>Measure</h1>

    <h2>GCS</h2>
    <ul>
        <li>Eyes 4</li>
        <li>Verbal 5</li>
        <li>Motor 6</li>
    </ul>
    <h2>Pupils</h2>
    <ul>
        <li>Size & Equality </li>
        <li>Reaction to light</li>
        <li>Accommodation</li>
    </ul>
    <h2>Mental</h2>
    <ul>
        <li>Orientated to:</li>
        <li>Time</li>
        <li>Space</li>
        <li>Situation</li>
        <li>Event recall</li>
    </ul>
    <h2>Physical</h2>
        <ul>
            <li>Equal sensation if no injury</li>
                <li>Equal strength if no injury</li>
                <li>Equal hearing if no blast</li>
                <li>Symmetry of face and tongue</li>
                <li>Limb drift</li>
                <li>Balance</li>
        </ul>

        <h2>Hypoglycaemia </h2>
        <ul>
            <li>Measure Blood Glucose / Normal Range 4 - 6 mmol/l </li>
        </ul>
        
    <h2>MACE</h2>
    <ul>
        <li>Military Acute Concussion Event for all suspected Mild - TBI</li>
    </ul>

    <hr>

    <iframe src="https://player.vimeo.com/video/869532194?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="D - Assess"></iframe>

</app-lb-info>
</div>

