<div class="container">
    <app-lightgreen-box name="AirwaySurgicalCric">SURGICAL CRIC</app-lightgreen-box>
   
    <app-lg-info>

        <h1>Indications</h1>

            <ul>
                <div>

                    <li>Facial trauma</li>
                    <li>Airway burns</li>
                    <li>Swelling</li>
                    <li>Shock with need to maintain spontaneous breathing</li>
                    <li>Failed intubation</li>

                </div>
            </ul>
        <hr>
            
        <h1>Pre-Procedure</h1>    

        <ul>

            <li>Decision</li>

                <ul>
                    <li>Basic airway interventions inadequate ?</li>
                    <li>Time and Environment? Equipment / Skill / Experience - Available ?</li>
                    <li>Anticipated difficulty ?</li>
            
            </ul>  

            <li>Prep Kit</li>

            <ul>
                
                <li>PPE: Gloves & Eye pro</li>

                <li>Cric kit</li>
                    <ul>
                        <li>Cric tube or cut ET Tube</li>
                        <li>Scalpel</li>
                        <li>Bougie</li>
                        <li>Tie</li>
                        <li>Cric hook</li>
                        <li>Syringe</li>
                    </ul>
                
                </ul>

                <li>Prep Cas</li>
            
                <ul>
                    <li>Position for optimal air movement: Casualty choice, LAP ot LIP </li>
                    <li>Ensure airway is inspected & cleared of any obstruction</li>
                    <li> Prep to move into optimal area & position for Cric</li>
                <ul>
                    </ul>
                    
                    <li>Consider dissociative dose of Ketamine</li>
                    
                    
                </ul>

                <hr>

            <h1>Procedure</h1>
            
        </ul>
                <ul>     
                            <li>1 Position</li>
                                <ul>
                                    <li>Inline stabilization: Chin / Sternum / Pubis</li>
                                    <li>Secure head with slight hyperflexion</li>
                                
                                </ul>
                            
                            <li>2 Identify Landmarks</li>
                                <ul>
                                    <li>Palpate Thyroid  Cartilage</li>
                                    <li>Move inferior to Cricothyroid membrane </li>
                                    <li>Consider marking</li>
                                </ul>
                            
                            <li>3 Vertical Incision</li>
                                <ul>
                                    <li>Control Larynx with non-dominant hand - This hand maintains this position</li>
                                <li>Make a vertical incision along the midline</li>
                                <li>The CT Membrane should be in the middle of the incision</li>
                                <li>If there is swelling or thick subcutaneous tissue make the incision longer </li>
                                <li>Spread tissue and identify CT Membrane</li>
                                </ul>
                            
                            <li>4 Lateral Incision </li>
                            <ul>
                                <li>Lateral Stab Incision</li>
                                <li>Spray or noise may indicate incision into trachea</li>
                                <li>Insert bougie through hole - scalpel blade may help - or hook</li>
                                <li>Feel for tracheal click & length to carina</li>
                                <li>Dilate incision with finger</li>
                            </ul>
                            
                        </ul>

                        <ul>
                            
                            <li>5 Place Tube</li>

                                <ul>
                                    <li>Railroad tube over bougie</li>
                                    <li>Inflate cuff</li>
                                    <li>Hold in place till placement confirmation</li>
                                    
                                </ul>

                            <li>6 Confirmation</li>
                                <ul>
                                    <li>Breathing from tube</li>
                                    <ul>
                                        <li>Feel for air movement</li>
                                        <li>Listen with Stethoscope</li>
                                        <li>Mist on tube</li>
                                    </ul>
                                    <li>Normal Ventilation with BVM</li>
                                    <li>ETCO2</li>
                                    <li>After confirmation - Tie in tube</li>
                                    <li>Place a filter on tube lumen</li>
                                </ul>

                            <h2>Unsuccessful</h2>
                        <ul>
                            <li>Create more space </li>
                                <ul>
                                    <li>Increase vertical then horizontal incision - blunt dissection with fingers </li>
                                </ul>

                            <li>Use hook</li>
                                <ul>  
                                    <li>Use cric hook - look for inner membrane & cut - pass bougie</li>
                                </ul>
                        </ul>
                    
                    <hr>

                    <h1>Post Procedure</h1>

                        <ul>
                            <li>Check</li>

                                <ul>
                                    
                                    <li>Cuff inflated  & Tube functioning  </li>
                                
                                </ul>

                            <li>Secure</li>

                                <ul>
                                    
                                    <li>Tube tie</li>
                                
                                </ul>
                            
                        </ul>
                    
 
                </ul>
        <hr>

        <h1 style="color: orange">Risk</h1>

            <ul>
                <li style="color: orange;">False passage <br> <span style="font-size: 18px;">not in true tracheal lumen</span></li>
                    <ul>
                        <li>Try to keep the lumen identified after stab incision</li>
                    </ul>
                <li style="color: orange;">180° error</li>
                    <ul>
                        <li>Cuff to Toes</li>
                    </ul>
            </ul>

            <ul>

                <li style="color: orange;">Tube too far & in right main bronchus</li>
                    <ul>
                        <li>Advance cuff no more than 2-3 cm</li>
                        <li>Check bilateral breath sounds</li>
                    </ul>
                <li style="color: orange;">Bleeding</li>
                    <ul>
                        <li>Blunt dissection with finger </li>
                        <li>Wipe area with gauze  </li>
                        <li>Direct pressure</li>
                    </ul>
                <li style="color: orange;">Damage to larynx</li>
                    <ul>
                        <li>Know your anatomy</li>
                        <li>Practise</li>
                    </ul>

            </ul>
        
        <hr>

          <iframe src="https://player.vimeo.com/video/745758151?h=c2a73a5c0b" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </app-lg-info>

</div>