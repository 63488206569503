<div class="container">
  <app-lightblue-box name="FurReAssess">REASSESS</app-lightblue-box>
<app-lb-info>

  <h2>X-A-B-C-D-E</h2>
    <ul>
      <li>From X to E step by step</li>
      <li>Check interventions remain effective</li>
      <li>Check interventions are secure for movement & evac</li>
      <li>No sudden change in cas condition</li>
    </ul>

    <iframe src="https://player.vimeo.com/video/912901929?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="F - Reassess"></iframe>

</app-lb-info>
</div>
