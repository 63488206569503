import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circ-asses',
  templateUrl: './circ-asses.component.html',
  styleUrls: ['./circ-asses.component.css']
})
export class CircAssesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
