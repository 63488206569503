
<app-white-box class="header" name="SafetyScene">Scene</app-white-box>

<app-white-box-info>

  <!-- Copy and paste as necessary  -->


      <h2>Is it safe to approach ?</h2>

        <ul>
            <li>Do ALL possible to ensure scene is as safe as can be 
              
          <li>Create a safe area around the casualty or move the casualty to a safe place </li>
        </ul>

</app-white-box-info>
