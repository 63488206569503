<!-- lb-box -->
<div class="boxFlex" >
    <div class="lb-box">
        <div class="left">
            <app-notes [name]="name"></app-notes>
        </div>
        <div class="center">
            <p><ng-content></ng-content></p>
        </div>
        <div class="right">
            <app-tick [name]="name"></app-tick>
        </div>
    </div>
</div>


