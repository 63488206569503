import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import * as firebase from 'firebase';
import { FirebaseService } from '../firebase.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  userEmail = '';
  userPassword = '';
  recurringSelected: boolean = false;
  premiumSelected: boolean = true;
  plan: string = 'price_1MpxtoFTpscF2VfwMk43qqDu';
  userID = '';
  isRegistered: boolean = false;
  error: string = '';
  busy: boolean = false;

  constructor(
    public afAuth: AngularFireAuth,
    public firebaseService: FirebaseService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  emailPasswordSignUp() {
    if (this.userEmail && this.userPassword) {
      this.busy = true;
      this.firebaseService.signUpWithEmail(this.userEmail, this.userPassword).then(res => {
        // console.log('successful login', res.user.uid);
        this.userID = res.user.uid;
        this.firebaseService.userID = this.userID;
        this.isRegistered = true;
        res.user.sendEmailVerification();
        alert('Thank you, an email verification link will be sent shortly. Please check your Junk/Spam folder if you have not received the link.');
        this.busy = false;
        this.router.navigateByUrl('/');
        
      }).catch((err) => {
        this.error = err;
        this.busy = false;
        // console.log('LoginComponent:: emailPasswordSignUp:: sign up failed:', err);
      });
    }
  }

  recurringClicked() {
    this.recurringSelected = !this.recurringSelected;
    if (this.premiumSelected = true) {
      this.premiumSelected = false;
      this.plan = 'price_1JnJPaFTpscF2Vfw2FGD11sv';
    }
  }

  // premiumClicked() {
  //   this.premiumSelected = !this.premiumSelected;
  //   if (this.recurringSelected = true) {
  //     this.recurringSelected = false;
  //     this.plan = 'price_1MpxtoFTpscF2VfwMk43qqDu';
  //   }
  // }

  async cancelCheckout() {
    this.busy = false;
    this.router.navigateByUrl('/');
  }

  async sendToCheckout() {
    this.busy = true;
    firebase.default
      .firestore()
      .collection('production_customers')
      .doc(this.userID)
      .collection('checkout_sessions')
      .add({
        mode: "payment",
        price: this.plan, // Price ID goes here
        success_url: window.location.origin, // return user to this screen on successful purchase
        cancel_url: window.location.origin, // return user to this screen on failed purchase
        allow_promotion_codes: true
      })
      .then((docRef) => {
        // Wait for the checkoutSession to get attached by the extension
        docRef.onSnapshot(async (snap) => {
          this.busy = false;
          const { error, sessionId } = snap.data();
          if (error) {
            // Show an error to your customer and inspect
            // your Cloud Function logs in the Firebase console.
            alert(`An error occurred: ${error.message}`);
          }

          if (sessionId) {
            // We have a session, let's redirect to Checkout
            // Init Stripe
            const stripe = await loadStripe(
              'pk_live_51JJjlqFTpscF2VfwUMhpfOYeCOX4zmURiW3OdpV681SAbamsOwRQBSwjvlAR15hB1AjQC9qJtOa3mlVdXvvK9Cfc00Y4UfMXBp' // stripe key goes here
            );
            console.log(`redirecting`);
            await stripe.redirectToCheckout({ sessionId });
          }
        });
      }).catch((err) => {
        this.busy = false;
      });
  }

}
