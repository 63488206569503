<div class="container">
  <app-lightgreen-box name="ExposureBurns">BURNS</app-lightgreen-box>

  <app-lg-info>
    <h2>Symptoms</h2>
    <ul>
        <li>Exposure to: Thermal / Chemical / Radiation / Electrical energy causing:  </li>
            <ul>
                <li>Superficial burns</li>
                <li>Partial thickness burns</li>
                <li>Full thickness burns </li>
            </ul>
          <li>Thermal burns covering >20% of the total body surface area (TBSA), airway burns and smoke inhalation injury, are life threatening</li>
          <li> Burns that affect vision, decrease hand function, or cause severe pain require evacuation</li>
    </ul>


    <h2>IA Drill</h2>
    <ul>
        <li>Use PPE to protect rescuer</li>
        <li>Remove cas from burning area </li>
        <li>Stop the burning process</li>
        <li>Cool the burnt area with saline irrigation - removing gross contamination </li>
        <li>Cover the burnt area with burn dressing or cling film once cooled </li>
        <li>Keep the casualty warm</li>
        <li>Give analgesia</li>
    </ul>
    <h2>Treatment</h2>
    <ul>
      <li>Airway</li>
        <ul>
          <li>Assess for airway burns: facial burns / black sputum / hoarse voice / difficulty breathing / swelling</li>
          <li>Secure airway with RSI or Cric </li>
        </ul>
      <li>Breathing</li>
        <ul>
          <li>Assess for inhalation injury: Burn in confined space / SOB / Toxicity </li>
          <li>Consider aerosolized unfractionated heparin</li>
          <li>Consider Hydroxocobalamin for cyanide poisoning </li>
        </ul>
      <li>Circulation</li>
        <ul>
          <li>Estimate Total Body Surface Area Burnt</li>
          <li>Start a fluid replacement in burns over 20% </li>
          <li>4ml/kg x % full thickness burn – half in first 8 hrs remainder in 16 hrs (Revised Parklands Form)</li>
          <li>Titrate to urine output to 0.5 - 1 mL/kg/hr in adults</li>
        </ul>
    </ul>

    <h2 style="color: orange;">Risks</h2>
    <ul>
        <li style="color: orange;">Hypovolemic shock with thermal burn</li>
          <ul>
            <li>Resuscitate Hem Shock first</li>
          </ul>
        <li style="color: orange;">Continued burning with chemical burns  </li>
          <ul>
            <li>Extended irrigation min 20min +</li>
          </ul>
        <li style="color: orange;">Cardiac arrest or arrhythmia with electrical burns </li>
          <ul>
            <li>Initiate CPR & ALS</li>
          </ul>
        <li style="color: orange;">Full thickness burns of limbs or torso   </li>
          <ul>
            <li>Consider escharotomy</li>
          </ul>
    </ul>
    
    <iframe src="https://player.vimeo.com/video/903631494?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="E - Burns"></iframe>

      </app-lg-info>
    
    </div>
    

