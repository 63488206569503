<div class="container">
  <app-lightgreen-box name="BreathingVentilate">Ventilate</app-lightgreen-box>

  <app-lg-info>
  
    <h1>Indications</h1>
    
    <ul>
        <li>Respiratory arrest or impending</li>
        <li>Post RSI</li>
    </ul>

<hr>

<h1>Pre-Procedure</h1>

    <h2>Prep</h2>
        <ul>
            <li>Kit: PPE- Gloves & eye pro / BVM & Reservoir / Cadence device / Oxygen / Ventilator</li>
            <li>Cas: Open / Inspect / Clesr / Maintain & Protect the Airway </li>
        </ul>
    
<h1>Procedure</h1>

        <h2>BVM</h2>

    <h2>1 Attach</h2>
    
        <ul>
            <li>Secure mask to face using C & E grip</li>
            <li>BV to EGA / ET Tube / Cric tube</li>
            <li>Ensure oxygen flow if available</li>
        </ul>

    <h2>2 Ventilate </h2>
        
        <ul>
            <li>Squeeze the bag only enough to cause chest expansion </li>
            <li>Avoid overinflation</li>
        </ul>

      <h2>3 Rate</h2>
        <ul>
          <li>12-16 BPM - In a shocked casualty consider reducing the rate</li>
          <li>Use a cadence device - counting is unlikely to be effective</li>
          <li>ETCO: Maintain low normal range 4.0-4.5 kPa (30-33 mmHg)</li>
          <li>Avoid breath stacking</li>
        </ul>

      <h2>Mechanical Ventilator (SAVe II)</h2>

        <h2>Set up</h2>
          <ul>
            <li>1 Establish or confirm airway</li>
            <li>2 Insert HME filter</li>
            <li>3 Connect breathing circuit to ports on device</li>
            <li>4 Turn on device</li>
            <li>5 Verify alarm functions - Disconnect / PIP</li>
            <li>6 Select patient height & ventilator parameters</li>
            <li>7 Connect low flow external O2 via Reservoir tube if available</li>
            <li>8 Connect SAVe II to casualty airway</li>
            <li>9 Verify ventilation: Chest rise / Breath sounds / ETCO2</li>
          </ul>

        <h2>Settings</h2>
          <ul>
            <li>1 Respiratory Rate (RR) in breaths per min </li>
              <ul>
                <li>Normal: Set to patient's height - adjust as required </li>
                <li>Shock: Avoid Hyperventilation and High Ventilatory Pressures</li>
                <li>TBI: Avoid Hyperventilation</li>
              </ul>
            <li>2 Tidal Volume (TV): 200-800ml in 10ml increments</li>
            <li>3 PIP: 10-60cmH2O in 5cmH2O increments </li>
            <li>4 PEEP: 0-20cm H2O in 1cmH2O increments </li>
          </ul>


<h1>Post Procedure</h1>    

    <h2>Check</h2>
    
        <ul>
            <li>Ventilation is effective: SPO2 / ETCO2 / Perfusion</li>
            <li>Ventilation pressure is increasing - Potential TPX</li>
        </ul>
    
    <h2>Secure</h2>
        <ul>
            <li>Secure and tie in airway tubes</li>
            <li>Use ET Tube Catheter Mount if availabel</li>
        </ul>

    <hr>

<h1 style="color: orange">Risk</h1>
    <ul>

        <li style="color: orange;">Decreased Cardiac Output</li>
            <ul>
             <li>Maintain spontaneous respiration</li>
              <li>Resuscitate Hem Shocked casualties with blood products first </li>
              <li>Limit excessive respiratory rate & pressures</li>
            </ul>
        
        <li style="color: orange;">Hyperventilation causing cerebral vasospasm in TBI</li>
            <ul><li>Use a cadence device or count aloud</li></ul>
    </ul>

    <ul>

        <li style="color: orange;">Inflate a tension pneumothorax</li>
            <ul><li>Remain vigilant to this risk developing</li></ul>
        
    </ul>

<hr>

<iframe src="https://player.vimeo.com/video/811212466?h=84e270b98d" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>


  </app-lg-info>
</div>
