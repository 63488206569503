
<div class="container">
    <app-lightgreen-box name="DisabilityLowerLoc">Lowered LOC</app-lightgreen-box>
   
    <app-lg-info>

        <h2>Causes of Lowered LOC</h2>
        <ul>
            <li>X: Blood loss </li>
            <li>A: Airway obstruction </li>
            <li>B: Respiratory compromise</li>
            <li>C: Shock</li>
            <li>D: Neuro Disability / Drugs </li>
            <li>E: Environmental </li>
        </ul>

        <h2>Indications </h2>
        <ul>
            <li>Lowered level of consciousness: V, P or U on AVPU or GCS < 14</li>
        </ul>

        <h2>Procedure </h2>
        <ul>
            <li>Try to identify and treat the cause of the lowered level of consciousness</li>
        </ul>

        <h2 style="color: orange;">Risk</h2>
         <ul>
            <li style="color: orange;">Increased airway risk</li>
            <li style="color: orange;">Undiagnosed reversible cause </li>
         </ul>

         <hr>

         <iframe src="https://player.vimeo.com/video/873289328?title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;airplay=0&amp;audio_tracks=0&amp;chapters=0&amp;chromecast=0&amp;closed_captions=0&amp;transcript=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="D - Lowered LOC"></iframe>

    </app-lg-info>

</div>