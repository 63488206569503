<div class="container">
    <div class="header">
        <img src="../../assets/logo.png" alt="">
        <h1>Settings</h1>
    </div>
    
    <div class="text" *ngIf = "currentUser !=='' ">
        <p> You are currently logged in as: {{currentUser}} </p>
    </div>

    <div class="text" *ngIf = "currentUser =='' ">
        <p> You are currently not logged in! </p>
    </div>

    <div class="text">
        <p> From here you can manage your account settings.</p>
    </div>

    <div class="buttons">
        <button (click)="logOut()" >Log out</button>
        <button (click)="alertManage()">Manage account </button>
    </div>
    
</div>