
<app-white-box class="header" name="TriageSearch">Search</app-white-box>

<app-white-box-info>

  <!-- Copy and paste as necessary  -->

      <h2>Make SURE no casualties are missed</h2>

        <ul>
            <li>Conduct a search of the injury site</li>
            <li>Declare a Major Incident if Mass Casualties </li>
        </ul>

</app-white-box-info>
