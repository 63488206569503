import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exp-burns',
  templateUrl: './exp-burns.component.html',
  styleUrls: ['./exp-burns.component.css']
})
export class ExpBurnsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
