
<div class="container">
    <app-lightgreen-box name="DisabilityHighICP">High ICP</app-lightgreen-box>
   
    <app-lg-info>

        <h1 style="color: green;" >Indications</h1>

        <ul style="color: red;">

            <li>Avoid High ICP in TBI</li>

     
        </ul>

        <hr>

        <h1>Procedure</h1>
        
            <ul>

                <li>Body elevated to 30% to the head</li>
                

                <li>Avoid:</li>

                    <ul>
                        <li>Circumfrential neck pressure blocking venous drainage (Neck seal on immersion suits)</li>
                        <li>Vomiting - consider antiemetic</li>
                        <li>Increased thoracic pressure (TPX)</li>
                    </ul>

            </ul>

        <hr>

        <h1 style="color: orange">Risk</h1>

            <ul style="color: orange;">
                
                <li>Head elevation may worsen Hem Shock</li>
                    <ul>

                    </ul>
                
            </ul>


    </app-lg-info>

</div>