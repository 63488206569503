<div class="container">
  <app-lightgreen-box name="FurHandover">HANDOVER</app-lightgreen-box>

  <app-lg-info>



    <h2>Use NATMIST for Handover</h2>

          <ul>
            <li>Name / Nationality</li>
            <li>Age</li>
            <li>Time of Injury</li>
            <li>Mechanism of Injury</li>
            <li>Injuries: Show injuries on the casualty</li>
            <li>Signs: AVPU / Resp rate / Pulse rate/ BP / SPO2 / SI / Cap refill / GCS / Pupils</li>
            <li>Treatment: Show interventions on the casualty</li>
            <li>Trend: of the casualty's condition</li>

          </ul>

          <h2>Document</h2>
            <ul>
              <li>Document on Cas Card</li>
              <li>Consider writing drugs on casualty's skin</li>
            </ul>

            <iframe src="https://player.vimeo.com/video/912907086?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="360" height="180" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="F - Handover"></iframe>

  </app-lg-info>

</div>
