import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dis-re-asses',
  templateUrl: './dis-re-asses.component.html',
  styleUrls: ['./dis-re-asses.component.css']
})
export class DisReAssesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
