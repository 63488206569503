import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comms-team',
  templateUrl: './comms-team.component.html',
  styleUrls: ['./comms-team.component.css']
})
export class CommsTeamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
