import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dis-iba',
  templateUrl: './dis-iba.component.html',
  styleUrls: ['./dis-iba.component.css']
})
export class DisIBAComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
