<div class="container" *ngIf=true>
  <div class="imgBox">
    <img src="../../assets/logo.png" alt="">
  </div>

  <div *ngIf="isSubscribed && !busy" class="input-fields">
    <div class="email-field">
      <input type="email" placeholder="Email" [(ngModel)]="userEmail">
    </div>
    <div class="password-field">
      <input type="password" placeholder="Password" [(ngModel)]="userPassword">
    </div>
    <div class="buttons">
      <button (click)="emailPasswordLogin()">Login</button>
      <button routerLink="/disclaimer">Sign Up</button>
      <p></p>
      <a class="forgot-password" routerLink="/PasswordReset">Forgot Password?</a>
    </div>
  </div>

  <div class="products" *ngIf="!isSubscribed && !busy && emailVerified">

    <h3>Please select a product:</h3>


    <div #premium (click)="premiumClicked()" [ngClass]="premiumSelected ? 'premiumSelected' : 'premium'">
      <img class="subscription" src="./../../assets/icons/price.png" alt="">
      <h4>£23</h4>
    </div>

    <div class="checkout">
      <img class="basket" src="../../assets/icons/basket.png" alt="">
      <h5 (click)="sendToCheckout()">Proceed to checkout</h5>
    </div>

    <div class="cancel">
      <h5 style="margin-left: 0px;" (click)="cancelCheckout()">Cancel</h5>
    </div>

  </div>

  <div class="products" *ngIf="isLoggedIn && !emailVerified && !busy">
   <h3 style="margin-top: 20px;"> Please verify your email and try again</h3>
   <div class="cancel">
    <h5 style="margin-left: 0px;" (click)="tryAgain()">Try again</h5>
  </div>
  </div>
  <div class="products" *ngIf="busy">

    <h3>We're busy redirecting you...</h3>

  </div>

  <a class=" webLink" href="http://www.atem.org.uk">www.atem.org.uk</a>

  <footer>&copy; Copyright 2021 ATEM <br> v0.2</footer>

</div>